import EventManager from "Managers/EventManager";
import Resolution from "Models/Resolution";
import platform from "platform";
import { RecordRTCPromisesHandler } from "recordrtc";
import DomUtils from "Utils/DomUtils";
import PageLayoutCollector from "Telemetry/PageLayoutCollector";

export default class ChunkedVideoTelemetry {
  _eventManager: EventManager;
  _calibrationEventManager: EventManager;
  _validationEventManager: EventManager;
  _layoutCollector: PageLayoutCollector;

  _recorder: RecordRTCPromisesHandler | undefined | null;
  _metadata: Array<string>;
  _uid: string;
  _index: 0;
  _started: number;
  _metadataUrls: Record<string, any> = {};
  _videoUrls: Record<string, any> = {};
  _lastScreenResolution: Resolution;
  _lastDevicePixelRatio: number;
  _lastContentX: number;
  _lastContentY: number;

  _subscriptions: Array<{ remove: Function }>;

  /**
   * @param eventManager
   */
  constructor(
    eventManager: EventManager,
    calibrationEventManager: EventManager,
    validationEventManager: EventManager
  ) {
    this._eventManager = eventManager;
    this._calibrationEventManager = calibrationEventManager;
    this._validationEventManager = validationEventManager;
    this._metadata = [];
    this._subscriptions = [];
    this._index = 0;
    this._started = Date.now();
    this._lastScreenResolution = Resolution.Zero();
    this._lastDevicePixelRatio = 1.0;
    this._lastContentX = 0;
    this._lastContentY = 0;
    this._uid = this._generateUUID();
    this._initSessionMetadata();
    this._layoutCollector = new PageLayoutCollector();
  }

  uid = () => this._uid;

  pushMetadata = (tag: string, data: object, timestamp: number | undefined) => {
    if (!timestamp) {
      timestamp = Date.now();
    }
    let payload: any = { tag: tag, timestamp: timestamp };
    payload = { ...payload, ...data };
    this._metadata.push(JSON.stringify(payload));
  };

  start = (stream: MediaStream): boolean => {
    if (!!this._recorder) {
      return false;
    }

    this._subscriptions.push(
      this._eventManager.subscribe(
        "onSourceResolutionChanged",
        (o, timestamp) => {
          this._metadata.push(
            JSON.stringify({
              tag: "onSourceResolutionChanged",
              timestamp: timestamp,
              resolution: o.resolution.serialize(),
            })
          );
        }
      )
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onNextFrame", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onNextFrame",
            timestamp: timestamp,
            frame: {
              timestamp: o.frame.timestamp(),
              duration: o.frame.duration(),
              resolution: o.frame.resolution().serialize(),
              orientation: o.frame.orientation(),
            },
          })
        );
        let screenResolution = DomUtils.screenResolution();
        let devicePixelRatio = DomUtils.devicePixelRatio();
        let contentX = DomUtils.screenContentX();
        let contentY = DomUtils.screenContentY();
        if (
          !screenResolution.equals(this._lastScreenResolution) ||
          devicePixelRatio != this._lastDevicePixelRatio
        ) {
          this._metadata.push(
            JSON.stringify({
              tag: "onScreenResized",
              timestamp: timestamp,
              resolution: screenResolution.serialize(),
              orientation: DomUtils.screenOrientation(),
              devicePixelRatio: devicePixelRatio,
              contentX: contentX,
              contentY: contentY,
            })
          );
        }
        this._lastScreenResolution = screenResolution;
        this._lastDevicePixelRatio = devicePixelRatio;
        this._lastContentX = contentX;
        this._lastContentY = contentY;
        let diff = this._layoutCollector.calculateDiff(o.frame.timestamp());
        if (
          diff.add.length > 0 ||
          diff.delete.length > 0 ||
          diff.update.length > 0
        ) {
          this._metadata.push(
            JSON.stringify({
              tag: "onLayoutDiff",
              timestamp: timestamp,
              diff: diff,
            })
          );
        }
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onVideoStart", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onVideoStart", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onEmitterStart", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onEmitterStart", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onFrameReaderStart", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onFrameReaderStart", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onWebcamStreamCreate", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onWebcamStreamCreate", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onWasmLoaded", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onWasmLoaded", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe(
        "onLandmarkDetectorInitialized",
        (o, timestamp) => {
          this._metadata.push(
            JSON.stringify({
              tag: "onLandmarkDetectorInitialized",
              timestamp: timestamp,
            })
          );
        }
      )
    );

    this._subscriptions.push(
      this._eventManager.subscribe(
        "onLandmarkDetectorFailed",
        (_o, timestamp) => {
          this._metadata.push(
            JSON.stringify({
              tag: "onLandmarkDetectorFailed",
              timestamp: timestamp,
            })
          );
        }
      )
    );

    this._subscriptions.push(
      this._eventManager.subscribe(
        "onGazeDetectorInitialized",
        (_o, timestamp) => {
          this._metadata.push(
            JSON.stringify({
              tag: "onGazeDetectorInitialized",
              timestamp: timestamp,
            })
          );
        }
      )
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onNextLandmarks", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onNextLandmarks",
            timestamp: timestamp,
            landmarks: {
              timestamp: o.landmarks.timestamp(),
              duration: o.landmarks.duration(),
            },
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onVoidLandmarks", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onVoidLandmarks",
            timestamp: timestamp,
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onNextRawGaze", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onNextRawGaze",
            timestamp: timestamp,
            averagedGaze: o.averagedGaze.serialize(),
            rawGaze: o.rawGaze.serialize(),
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onNextModifiedGaze", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onNextModifiedGaze",
            timestamp: timestamp,
            modifiedGaze: o.modifiedGaze.serialize(),
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onModelTrained", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onModelTrained",
            timestamp: timestamp,
            modifiedGaze: o.model.serialize(),
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onPresenceAcquired", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onPresenceAcquired",
            timestamp: timestamp,
            duration: o.duration,
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onPresenceReleased", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onPresenceRelease",
            timestamp: timestamp,
            duration: o.duration,
          })
        );
      })
    );

    this._subscriptions.push(
      this._calibrationEventManager.subscribe(
        "onNextCalibratedSample",
        (o, timestamp) => {
          let sample = o.sample;
          if ("landmarks" in sample) {
            this._metadata.push(
              JSON.stringify({
                tag: "onCalibrationSampled",
                timestamp: timestamp,
                frame: {
                  timestamp: sample.frame.timestamp(),
                  duration: sample.frame.duration(),
                },
                measuredGaze: o.measuredGaze.serialize(),
              })
            );
          }
        }
      )
    );

    this._subscriptions.push(
      this._validationEventManager.subscribe(
        "onNextCalibratedSample",
        (o, timestamp) => {
          let sample = o.sample;
          if ("averagedGaze" in sample) {
            this._metadata.push(
              JSON.stringify({
                tag: "onValidationSampled",
                timestamp: timestamp,
                averagedGaze: sample.averagedGaze.serialize(),
                measuredGaze: o.measuredGaze.serialize(),
              })
            );
          }
        }
      )
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onCalibrationStarted", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onCalibrationStarted", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onCalibrationComplete", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onCalibrationComplete", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onNextCalibrationPoint", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onNextCalibrationPoint",
            timestamp: timestamp,
            point: o.point,
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onCalibrationFailed", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onCalibrationFailed", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onValidationStarted", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onValidationStarted", timestamp: timestamp })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onValidationComplete", (o, timestamp) => {
        this._metadata.push(
          JSON.stringify({
            tag: "onValidationComplete",
            timestamp: timestamp,
            validatedErrorCorrection: o.validatedErrorCorrection.serialize(),
            errorCorrection: o.errorCorrection.serialize(),
          })
        );
      })
    );

    this._subscriptions.push(
      this._eventManager.subscribe("onValidationFailed", (_o, timestamp) => {
        this._metadata.push(
          JSON.stringify({ tag: "onValidationFailed", timestamp: timestamp })
        );
      })
    );

    this._recorder = new RecordRTCPromisesHandler(stream, {
      type: "video",
      mimeType: platform.os?.family == "iOS" ? "video/mp4" : "video/webm",
      timeSlice: 10000, // pass this parameter
      videoBitsPerSecond: 900000,
      frameRate: 30,
      ondataavailable: (blob) => {
        let metadata = this._metadata;
        let index = this._index;
        this._metadata = [];
        this._index++;
        this._submitVideoData(index, metadata, blob).catch((ex) => {
          this._eventManager.publish("onError", ex);
        });
      },
    });
    this._recorder.startRecording();
    return true;
  };

  stop(): boolean {
    if (this._recorder) {
      this._recorder.stopRecording();
      this._recorder = null;
      return true;
    }
    for (let subscription of this._subscriptions) {
      subscription.remove();
    }
    this._subscriptions = [];
    return false;
  }

  _initSessionMetadata = () => {
    this._lastScreenResolution = DomUtils.screenResolution();
    let timestamp = Date.now();

    this._metadata.push(
      JSON.stringify({
        tag: "onTelemetryInit",
        timestamp: timestamp,
        platform: DomUtils.detectPlatform().toString(),
      })
    );
    this._lastScreenResolution = DomUtils.screenResolution();
    this._lastDevicePixelRatio = DomUtils.devicePixelRatio();
    this._lastContentX = DomUtils.screenContentX();
    this._lastContentY = DomUtils.screenContentY();
    this._metadata.push(
      JSON.stringify({
        tag: "onScreenResized",
        timestamp: timestamp,
        resolution: this._lastScreenResolution.serialize(),
        orientation: DomUtils.screenOrientation(),
        devicePixelRatio: this._lastDevicePixelRatio,
        contentX: this._lastContentX,
        contentY: this._lastContentY,
      })
    );
  };

  _submitVideoData = async (index: number, metadata: any, videoBlob: Blob) => {
    let key = "" + index;

    if (key in this._metadataUrls && key in this._videoUrls) {
      let metadataPresigned = this._metadataUrls[key];
      let videoDataPresigned = this._videoUrls[key];

      const metaFormData = new FormData();
      Object.keys(metadataPresigned.fields).forEach((k) => {
        metaFormData.append(k, metadataPresigned.fields[k]);
      });
      metaFormData.append("file", metadata.join("\n"));
      let metaFuture = fetch(metadataPresigned.url, {
        method: "POST",
        body: metaFormData,
        mode: "no-cors",
      });

      const videoFormData = new FormData();
      Object.keys(videoDataPresigned.fields).forEach((k) => {
        videoFormData.append(k, videoDataPresigned.fields[k]);
      });
      videoFormData.append("file", videoBlob);
      let videoFuture = fetch(videoDataPresigned.url, {
        method: "POST",
        body: videoFormData,
        mode: "no-cors",
      });
      await Promise.all([videoFuture, metaFuture]).catch((ex) => {
        throw {
          name: "ChunkedVideoTelemetryError",
          message: "Failed to upload telemetry data. ",
          stack: new Error().stack,
          toString: function () {
            return this.name + ": " + this.message;
          },
        };
      });
    } else {
      let indices = [];
      for (let i = index; i < index + 10; i++) {
        indices.push("" + i);
      }
      let response = await fetch(
        "https://brlez5puflbu74txojsdof3sw40gkkro.lambda-url.eu-west-2.on.aws/",
        {
          method: "POST",
          body: JSON.stringify({
            started: this._started,
            uid: this._uid,
            indices: indices,
          }),
        }
      );
      let ret = await response.json();
      if (ret["success"]) {
        let metadataUrls = ret["metadata_urls"];
        let videoUrls = ret["video_urls"];
        Object.assign(this._metadataUrls, metadataUrls);
        Object.assign(this._videoUrls, videoUrls);
        await this._submitVideoData(index, metadata, videoBlob);
      } else {
        throw {
          name: "ChunkedVideoTelemetryError",
          message: "Failed to request upload urls. ",
          stack: new Error().stack,
          toString: function () {
            return this.name + ": " + this.message;
          },
        };
      }
    }
  };

  _generateUUID = () => {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  };
}
