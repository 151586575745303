import GazeCalibrator from "Calibration/GazeCalibrator";
import GazeCalibratorFactory from "Calibration/GazeCalibratorFactory";
import GazeDetector from "Detection/GazeDetector";
import GazeDetectorFactory from "Detection/GazeDetectorFactory";
import LandmarkDetector from "Detection/LandmarkDetector";
import SimpleEyesOnScreen from "Detection/SimpleEyesOnScreen";
import SimplePresence from "Detection/SimplePresence";
import ConfigManager from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Trained from "Models/Trained";
import FrameReader from "Readers/FrameReader";
import FrameReaderFactory from "Readers/FrameReaderFactory";
import ErrorCorrectionGazeValidator from "Validation/ErrorCorrectionGazeValidator";
import VideoView from "Views/VideoView";
import LandmarkWorkerAdapter from "Workers/LandmarkWorkerAdapter";

/**
 * TrackerController is event driven.
 * FrameReader emits events with Frame and Resolution models.
 * events published by FrameReader are: "onNextFrame" and "onSourceResolutionChanged"
 * landmarkDetector uses this to publish "onNextLandmarks"
 * The landmark detector creates Landmarks model and publishes "onNextLandmarks" events
 * Landmark models are then fed into the tracker.
 * The tracker creates GazePosition models.
 * The event "onNextGaze" is published by the tracker.
 */

export default class TrackerController {
  config: ConfigManager;
  _eventManager: EventManager; //Handles frame updates and resolution changes

  _frameReader: FrameReader | undefined;
  _faceDetector: {} | null | undefined; // unused

  _landmarkDetector: LandmarkDetector | undefined;
  _videoView: VideoView;
  _presenceDetector: SimplePresence; // unused

  _detectorCalibrator: GazeCalibrator;
  _gazeDetector: GazeDetector | undefined;
  _validatorCalibrator: GazeCalibrator;
  _gazeValidator: ErrorCorrectionGazeValidator;
  _eyesOnScreenDetector: SimpleEyesOnScreen;

  /**
   * @param {ConfigManager} config
   */
  constructor(config: ConfigManager) {
    this.config = config;
    let eventManager = new EventManager();
    this._eventManager = eventManager;
    this._videoView = new VideoView(config.videoView, eventManager);
    //this._videoView = new SilhouetteView(config.videoView, eventManager);

    this._presenceDetector = new SimplePresence(eventManager, true);
    this._eyesOnScreenDetector = new SimpleEyesOnScreen(eventManager, true);
    this._gazeValidator = new ErrorCorrectionGazeValidator(
      config.gazeValidator
    );
    // calibrator needs own event manager since it is not unique
    // manually relay events to it
    let detCal = GazeCalibratorFactory.build(config.gazeDetector.calibrator);
    eventManager.relay(
      "onNextLandmarks",
      detCal.eventManager(),
      "onNextSample"
    );
    // relay data with alias onNextSample
    detCal.eventManager().relay("onError", eventManager);
    detCal.eventManager().relay("onHintSet", eventManager);
    detCal.eventManager().relay("onHintReleased", eventManager);
    this._detectorCalibrator = detCal;
    // use calibrated samples for gaze detector training data
    detCal.eventManager().subscribe("onNextCalibratedSample", (res) => {
      let gazeDetector = this.gazeDetector(); // reference might change, so use getter

      if (gazeDetector && "frame" in res.sample) {
        gazeDetector.addTrainingData(
          res.sample.frame,
          res.sample.landmarks,
          res.measuredGaze
        );
      }
    });
    detCal.eventManager().subscribe("onCalibrationStarted", () => {
      let gazeDetector = this.gazeDetector();

      if (gazeDetector) {
        eventManager.publish("onCalibrationStarted", {});
      }
    });
    this.eventManager().subscribe("onCalibrationComplete", () => {
      detCal.stop();
    });
    detCal.eventManager().subscribe("onCalibrationComplete", () => {
      let gazeDetector = this.gazeDetector();

      if (gazeDetector) {
        let model = gazeDetector.train();
        eventManager.publish("onCalibrationComplete", {});
        eventManager.publish("onModelTrained", { model: model });
      }
    });
    let valCal = GazeCalibratorFactory.build(config.gazeValidator.calibrator);
    eventManager.relay("onNextRawGaze", valCal.eventManager(), "onNextSample");
    valCal.eventManager().relay("onError", eventManager);
    valCal.eventManager().relay("onHintSet", eventManager);
    valCal.eventManager().relay("onHintReleased", eventManager);
    this._validatorCalibrator = valCal;
    valCal.eventManager().subscribe("onNextCalibratedSample", (res) => {
      let gazeValidator = this.gazeValidator();

      if (gazeValidator && "averagedGaze" in res.sample) {
        let predictedGaze = res.sample.averagedGaze;
        let measuredGaze = res.measuredGaze;
        gazeValidator.addValidationData(predictedGaze, measuredGaze);
      }
    });
    valCal.eventManager().subscribe("onCalibrationStarted", () => {
      let gazeValidator = this.gazeValidator();

      if (gazeValidator) {
        eventManager.publish("onValidationStarted", {});
      }
    });
    this.eventManager().subscribe("onValidationComplete", () => {
      valCal.stop();
    });
    valCal.eventManager().subscribe("onCalibrationComplete", () => {
      let gazeValidator = this.gazeValidator();

      if (gazeValidator) {
        let validatedErrorCorrection = gazeValidator.validate(); // causes exception

        let errorCorrection = gazeValidator.errorCorrection();
        eventManager.publish("onValidationComplete", {
          validatedErrorCorrection: validatedErrorCorrection,
          errorCorrection: errorCorrection,
        });
      }
    });
    eventManager.subscribe("onNextRawGaze", (res) => {
      let gazeValidator = this.gazeValidator();
      let filter = gazeValidator.errorCorrectionFilter();

      if (filter.errorCorrection()) {
        let modifiedGaze = filter.apply(res.averagedGaze);
        let ret = {
          rawGaze: res.rawGaze,
          averagedGaze: res.averagedGaze,
          modifiedGaze: modifiedGaze,
        };

        eventManager.publish("onNextModifiedGaze", ret);
      }
    });
  }

  /**
   * Events can be subscribed to.
   * eventManager().subscribe("onNextFrame", (res) => {console.log("Its a frame")};)
   *
   * "onSourceResolutionChanged" => {timestamp, resolution}
   * "onNextFrame" => {timestamp, frame}
   * "onNextLandmarks" => {timestamp, frame, landmarks}
   * "onVoidLandmarks" => {timestamp, frame}
   * "onPresenceAcquired" = {timestamp, duration}
   * "onPresenceReleased" = {timestamp, duration}
   * "onEyesOnScreen" = {timestamp, duration}
   * "onEyesOffScreen" = {timestamp, duration}
   * "onCalibrationComplete" => {timestamp, model}
   * "onNextRawGaze" => {timestamp, rawGaze, averagedGaze}
   * "onValidationComplete" => {timestamp, errorCorrection, validatedErrorCorrection}
   * "onNextModifiedGaze" => {timestamp, rawGaze, averagedGaze, modifiedGaze}
   * "onError"
   *
   * ---experimental---
   * "onPresenceDetected" => {timestamp, landmarks, presence}
   * "onEyesOnScreen" => {timestamp, gaze, onScreen}
   *
   * @return {EventManager}
   */
  eventManager = (): EventManager => this._eventManager;

  /**
   * read frames from media source.
   * if (frameReader.hasNextFrame())
   *   frameReader.nextFrame();
   *
   * @return {FrameReader}
   */
  frameReader = (): FrameReader | undefined => this._frameReader;

  /**
   * @return {FaceDetector}
   */
  //faceDetector = () => this._faceDetector;

  /**
   * @return {LandmarkDetector}
   */
  landmarkDetector = (): LandmarkDetector | undefined => this._landmarkDetector;

  /**
   * @return {SimplePresence}
   */
  presenceDetector = (): SimplePresence => this._presenceDetector;

  /**
   * @return {GazeCalibrator}
   */
  detectorCalibrator = (): GazeCalibrator => this._detectorCalibrator;

  /**
   * @return {GazeDetector}
   */
  gazeDetector = (): GazeDetector | undefined => this._gazeDetector;

  /**
   * @return {GazeCalibrator<any>}
   */
  validatorCalibrator = (): GazeCalibrator => this._validatorCalibrator;

  /**
   * @return {ErrorCorrectionGazeValidator}
   */
  gazeValidator = (): ErrorCorrectionGazeValidator => this._gazeValidator;

  /**
   * @return {SimpleEyesOnScreen}
   */
  eyesOnScreenDetector = (): SimpleEyesOnScreen => this._eyesOnScreenDetector;

  /**
   * The frame reader is responsible for capturing frames.
   * Events "onNextFrame" and "onSourceResolutionChanged" are published when
   * a new frame is ready and when the frame source (e.g webcam) resolution
   * changes (e.g when mobile orientation changes)
   *
   * by default frameReader state is not running
   * @param {Boolean} start after init completes
   */
  initFrameReader = async (start: boolean = false) => {
    let frameReader = FrameReaderFactory.build(this.config.frameReader);

    if (this._frameReader) {
      this.stopFrameReader();
    }

    frameReader
      .eventManager()
      .relay("onSourceResolutionChanged", this._eventManager);
    frameReader.eventManager().relay("onNextFrame", this._eventManager);
    frameReader.eventManager().relay("onError", this._eventManager);
    frameReader.eventManager().relay("onFrameReaderStart", this._eventManager);
    frameReader
      .eventManager()
      .relay("onWebcamStreamCreate", this._eventManager);
    frameReader.eventManager().relay("onVideoStart", this._eventManager);
    frameReader.eventManager().relay("onEmitterStart", this._eventManager);
    this._frameReader = frameReader;

    if (start) {
      await this.startFrameReader();
    }
  };

  initFaceDetector = async (start: boolean = false) => {
    throw "TrackerController: face detector not implemented";
  };

  /**
   * initialize the landmark detector
   * may be called independently of other services
   * @param {Boolean} start
   */
  initLandmarkDetector = async (start: boolean = true) => {
    let landmarkDetector = new LandmarkWorkerAdapter(
      this.config.landmarkDetector,
      this._eventManager
    );
    await landmarkDetector.init(0, 0);

    if (this._landmarkDetector) {
      this.stopLandmarkDetector();
    }

    this._landmarkDetector = landmarkDetector;

    if (start) {
      this.startLandmarkDetector();
    }
  };

  /**
   * initialize the gaze detector
   * may be called independently of other services
   * @param {Boolean} start
   * @param {Uint8Array} model can be set later or trained by calibrator
   */
  initGazeDetector = async (
    start: boolean = false,
    model: Trained | null | undefined = null
  ) => {
    let gazeDetector = await GazeDetectorFactory.build(
      this.config.gazeDetector,
      this._eventManager
    );
    await gazeDetector.init();

    if (model) {
      gazeDetector.setModel(model);
    }

    if (this._gazeDetector) {
      this.stopGazeDetector();
    }

    this._gazeDetector = gazeDetector;
    this.eventManager().publish("onGazeDetectorInitialized", {});

    if (start) {
      this.startGazeDetector();
    }
  };

  setVideoDiv = (div: HTMLDivElement) => {
    if (this._videoView) {
      this._videoView.setDivElement(div);
    }
  };

  /**
   * This function creates an OffscreenCanvas and passes it to the
   * VideoView class
   *
   * The extension doesn't have a page to lodge the HTMLDivElement/HTMLCanvasElement
   *
   * @memberof TrackerController
   */
  setOffscreenCanvas = () => {
    let offscreenCanvas = new OffscreenCanvas(
      this.config.frameReader.maxWidth,
      this.config.frameReader.maxHeight
    );

    this._videoView.setOffscreenCanvas(offscreenCanvas);
  };

  releaseVideoDiv = () => {
    if (this._videoView) {
      this._videoView.releaseDivElement();
    }
  };

  setCalibrationDiv = (div: HTMLDivElement) => {
    if (this._detectorCalibrator) {
      this._detectorCalibrator.setDivElement(div);
    }
  };

  releaseCalibrationDiv = () => {
    if (this._detectorCalibrator) {
      this._detectorCalibrator.releaseDivElement();
    }
  };

  setValidationDiv = (div: HTMLDivElement) => {
    if (this._validatorCalibrator) {
      this._validatorCalibrator.setDivElement(div);
    }
  };

  releaseValidationDiv = () => {
    if (this._validatorCalibrator) {
      this._validatorCalibrator.releaseDivElement();
    }
  };

  /**
   * @return {Promise<boolean>} started
   */
  startFrameReader = async (): Promise<boolean> => {
    if (this._frameReader) {
      return await this._frameReader.start();
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopFrameReader = (): boolean => {
    if (this._frameReader) {
      return this._frameReader.stop();
    }
    return false;
  };

  /**
   * @return {boolean} started
   */
  startFaceDetector = (): boolean => {
    throw "TrackerController: face detector not implemented";
  };

  /**
   * @return {boolean} stopped
   */
  stopFaceDetector = (): boolean => {
    throw "TrackerController: face detector not implemented";
  };

  /**
   * @return {boolean} started
   */
  startLandmarkDetector = (): boolean => {
    if (this._landmarkDetector) {
      this._landmarkDetector.start();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopLandmarkDetector = (): boolean => {
    if (this._landmarkDetector) {
      this._landmarkDetector.stop();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} started
   */
  startShowVideo = (): boolean => {
    if (this._videoView) {
      this._videoView.startAnimation();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopShowVideo = (): boolean => {
    if (this._videoView) {
      this._videoView.stopAnimation();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} start
   */
  startPresenceDetector = (): boolean => {
    if (this._presenceDetector) {
      this._presenceDetector.start();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopPresenceDetector = (): boolean => {
    if (this._presenceDetector) {
      this._presenceDetector.stop();
      return true;
    }
    return false;
  };

  /**
   * Start gaze calibrator
   * Calibrator will automatically stop once calibration is complete
   * If calibrator has already started calibrator will not restart
   * @return {boolean} started
   */
  startGazeCalibrator = (): boolean => {
    if (this._detectorCalibrator) {
      this._detectorCalibrator.start();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopGazeCalibrator = (): boolean => {
    if (this._detectorCalibrator) {
      this._detectorCalibrator.stop();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} started
   */
  startGazeDetector = (): boolean => {
    if (this._gazeDetector) {
      this._gazeDetector.start();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopGazeDetector = (): boolean => {
    if (this._gazeDetector) {
      this._gazeDetector.stop();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} started
   */
  startGazeValidator = (): boolean => {
    if (this._gazeValidator && this._validatorCalibrator) {
      this._validatorCalibrator.start();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopGazeValidator = (): boolean => {
    if (this._validatorCalibrator) {
      this._validatorCalibrator.stop();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} started
   */
  startEyesOnScreenDetector = (): boolean => {
    if (this._eyesOnScreenDetector) {
      this._eyesOnScreenDetector.start();
      return true;
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopEyesOnScreenDetector = (): boolean => {
    if (this._eyesOnScreenDetector) {
      this._eyesOnScreenDetector.stop();
      return true;
    }
    return false;
  };
}
