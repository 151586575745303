import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";
import Landmarks from "Models/Landmarks";

export default class SimplePresence {
  config: any;
  _eventManager: EventManager;
  _landmarksSubscription: { remove: any } | null | undefined;
  _voidSubscription: { remove: any } | null | undefined;
  _lastLandmarks: Landmarks | null | undefined;
  _lastTransition = 0;

  /**
   * @param {EventManager} eventManager
   * @param {boolean} start
   */
  constructor(eventManager: EventManager, start: boolean = true) {
    this._eventManager = eventManager;

    if (start) {
      this.start();
    }
  }

  /**
   * start modifying
   */
  start = () => {
    if (!this._landmarksSubscription) {
      this._landmarksSubscription = this._eventManager.subscribe(
        "onNextLandmarks",
        (res) => {
          this.update(res.landmarks);
        }
      );
      this._voidSubscription = this._eventManager.subscribe(
        "onVoidLandmarks",
        (res) => {
          this.update(null);
        }
      );
    }
  };

  /**
   * stop modifying
   */
  stop = () => {
    if (this._landmarksSubscription) {
      this._landmarksSubscription.remove();

      this._landmarksSubscription = null;
    }

    if (this._voidSubscription) {
      this._voidSubscription.remove();

      this._voidSubscription = null;
    }
  };

  /**
   * reset state
   */
  reset = () => {
    throw "SimplePresence: Not implemented!";
  };

  /**
   * @param {Gaze} rawGaze to modify
   */
  update = (landmarks: Landmarks | null | undefined) => {
    let timestamp = Date.now();

    if (!this._lastLandmarks && landmarks) {
      this._eventManager.publish("onPresenceAcquired", {
        duration: timestamp - this._lastTransition,
      });

      this._lastTransition = timestamp;
    }

    if (this._lastLandmarks && !landmarks) {
      this._eventManager.publish("onPresenceReleased", {
        duration: timestamp - this._lastTransition,
      });

      this._lastTransition = timestamp;
    }

    this._lastLandmarks = landmarks;
  };

  /**
   * @return {Boolean} is users face present?
   */
  isPresent = (): boolean => {
    return this._lastLandmarks != null;
  };
}
