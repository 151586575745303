export default class StorageHandler {
  static setItem(key: string, item: string) {
    if (!!localStorage) {
      localStorage.setItem(key, item);
    } else {
      sessionStorage.setItem(key, item);
    }
  }

  static getItem(key: string) {
    if (!!localStorage) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  }

  static removeItem(key: string) {
    if (!!localStorage) {
      return localStorage.removeItem(key);
    } else {
      return sessionStorage.removeItem(key);
    }
  }

  static clear() {
    if (!!localStorage) {
      localStorage.clear();
    } else {
      sessionStorage.clear();
    }
  }
}
