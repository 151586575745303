import { PoseSegmentedDotViewConfig } from "Managers/ConfigManager";
import ClicklessDotView, { Point } from "Views/ClicklessDotView";

export default class PoseSegmentedDotView extends ClicklessDotView {
  config: PoseSegmentedDotViewConfig;
  _segments: Array<number>; // 0: started, 1: mid, 2: finished, -1: ignore
  _lastSegment: number; // most recently modified segment

  /**
   * @param {PoseSegmentedDotViewConfig} config
   */
  constructor(config: PoseSegmentedDotViewConfig) {
    super(config);
    this.config = config;
    let offset = config.segmentProperties.centralSegment ? 1 : 0;
    this._segments = new Array(
      config.segmentProperties.numberOfSegments + offset
    ).fill(0);
    this._lastSegment = -1;
  }

  /**
   * Update the canvas to current state
   */
  render = () => {
    let timestamp =
      this._lastRenderTimestamp + this._framerateManager.getFrameDuration();

    if (this._canvas) {
      let canvas = this._canvas;
      let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      // for retina devices must consider pixelRatio
      const pixelRatio = window.devicePixelRatio || 1;
      const canvasWidth = canvas.clientWidth * pixelRatio;
      const canvasHeight = canvas.clientHeight * pixelRatio;

      if (canvas.width !== canvasWidth || canvas.height !== canvasHeight) {
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
      } else {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }

      if (this.moving()) {
        if (this.pulsationCycleComplete(timestamp)) {
          this._movePoint(timestamp);

          this._nextPulsation = timestamp;
        } else {
          this._lastPulsation = timestamp;
        }
      } else {
        this._lastPulsation = timestamp;

        this._updatePulsationCycle(timestamp);

        let indices = Array.from(
          {
            length: this._segments.length,
          },
          (x, i) => i
        );
        // make sure to draw active segment last
        let offset = this.config.segmentProperties.centralSegment ? 1 : 0;

        if (this._lastSegment >= offset && this._lastSegment < indices.length) {
          indices[this._lastSegment] = indices[indices.length - 1];
          indices[indices.length - 1] = this._lastSegment;
        }

        for (let i of indices) {
          let startColour = this.config.segmentProperties.startColour;
          let midColour = this.config.segmentProperties.midColour;
          let finishColour = this.config.segmentProperties.finishColour;

          if (this._segments[i] < 0.0001) {
            this.drawSegment(ctx, this._point, i, 0.0, startColour);
          } else if (this._segments[i] < 1.0) {
            this.drawSegment(ctx, this._point, i, this._segments[i], midColour);
          } else {
            this.drawSegment(ctx, this._point, i, 1.0, finishColour);
          }
        }
      }

      this._pulsatePoint(timestamp);

      this.drawPoint(ctx, this._point);
    }

    this._lastRenderTimestamp = timestamp;

    this._framerateManager.recordFrame();

    let animationType = this.config.animation;

    if (this._running && animationType === "REQUEST_ANIMATION_FRAME") {
      requestAnimationFrame(this.render);
    }
  };
  setSegment = (index: number, value: number) => {
    this._segments[index] = value;
    this._lastSegment = index;
  };

  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {Point2D} point
   * @param {number} index
   * @param {number} fraction of completion
   * @param {string} colour
   * @returns
   */
  drawSegment = (
    ctx: CanvasRenderingContext2D,
    point: Point,
    index: number,
    fraction: number,
    colour: string
  ) => {
    let x = point.x * ctx.canvas.width;
    let y = point.y * ctx.canvas.height;
    let scale = Math.min(ctx.canvas.width, ctx.canvas.height);
    let radius = this.config.pointProperties.radius;
    let innerRimRadius =
      this.config.segmentProperties.innerRimFraction * radius;
    let innerRadius = this.config.segmentProperties.innerFraction * radius;
    let outerRadius = this.config.segmentProperties.outerFraction * radius;
    let n = this.config.segmentProperties.numberOfSegments;

    if (index < 0 || index > n) {
      return;
    }

    ctx.beginPath();
    ctx.fillStyle = colour;

    if (this._lastSegment === index) {
      ctx.lineWidth = 8;
    } else {
      ctx.lineWidth = 1;
    }

    ctx.strokeStyle = colour;
    let offset = this.config.segmentProperties.centralSegment ? 1 : 0;

    if (index === 0 && offset === 1) {
      ctx.arc(x, y, innerRadius * scale, 0, 2 * Math.PI);
      ctx.arc(x, y, innerRimRadius * scale, 2 * Math.PI, 0, true);
    } else {
      let h = 0.5 * (outerRadius + innerRadius);
      let dh = 0.5 * (outerRadius - innerRadius);
      let theta = (2 * Math.PI * (index - offset)) / n;
      let dTheta = Math.PI / this.config.segmentProperties.numberOfSegments;
      let minRadius = this.config.segmentProperties.minCircleFraction;
      let maxRadius = h * Math.sin(dTheta);
      let radius = minRadius + fraction * (maxRadius - minRadius);
      let pTheta = (0.8 * dTheta * (radius - dh)) / (maxRadius - dh); // 0.8 so it jumps last 10% at completion

      radius = Math.min(radius, dh);
      theta += dTheta;
      let outRS = outerRadius * scale;
      let inRS = innerRadius * scale;
      let hS = h * scale;
      let rS = radius * scale; // scale and clamp

      if (fraction >= 1.0) {
        // full arc
        ctx.arc(x, y, outRS, theta - dTheta, theta + dTheta);
        ctx.arc(x, y, inRS, theta + dTheta, theta - dTheta, true);
      } else if (pTheta > 0.0) {
        let lTheta = theta - pTheta;
        let rTheta = theta + pTheta;
        ctx.arc(x, y, outRS, lTheta, rTheta);
        ctx.arc(
          x + hS * Math.cos(rTheta),
          y + hS * Math.sin(rTheta),
          rS,
          rTheta,
          rTheta + Math.PI
        );
        ctx.arc(x, y, inRS, rTheta, lTheta, true);
        ctx.arc(
          x + hS * Math.cos(lTheta),
          y + hS * Math.sin(lTheta),
          rS,
          lTheta - Math.PI,
          lTheta
        );
      } else {
        // circle
        ctx.arc(
          x + hS * Math.cos(theta),
          y + hS * Math.sin(theta),
          rS,
          0.0,
          Math.PI * 2
        );
      }
    }

    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  };
}
