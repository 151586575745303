type LayoutNode = {
  // tagName: string;
  // class: string;
  // id: string;
  element: Element;
  children: Array<LayoutNode>;
  relX: number; // relative to parent node's relX
  relY: number; // relative to parent node's relY
  width: number;
  height: number;
};

type SerializedNode = {
  index: number;
  rel_x: number;
  rel_y: number;
  width: number;
  height: number;
  children: number[];
  src?: string;
  class?: string;
  tag?: string;
  id?: string;
  // html: string;
  // text: string;
  // x: number;
  // y: number;
  time: number;
  video_time?: number;
  video_playing?: boolean;
};

type LayoutDiff = {
  timestamp: number;
  add: Array<SerializedNode>;
  delete: Array<number>;
  update: Array<SerializedNode>;
};

function isEqual(node1: LayoutNode, node2: LayoutNode): boolean {
  if (
    node1.element != node2.element ||
    node1.children.length != node2.children.length ||
    node1.relX != node2.relX ||
    node1.relY != node2.relY ||
    node1.width != node2.width ||
    node1.height != node2.height
  ) {
    return false;
  }
  for (let i = 0; i < node1.children.length; i++) {
    if (node1.children[i].element != node2.children[i].element) {
      return false;
    }
  }
  return true;
}

function createLayoutTree(
  element: Element,
  parentX: number,
  parentY: number
): LayoutNode {
  // Create an object to hold the current element's info
  let rect = element.getBoundingClientRect();
  let x = rect.x;
  let y = rect.y;
  let width = rect.width;
  let height = rect.height;

  let node: LayoutNode = {
    element: element,
    children: [],
    relX: Math.round(100000 * (x - parentX)) / 100000,
    relY: Math.round(100000 * (y - parentY)) / 100000,
    width: Math.round(100000 * width) / 100000,
    height: Math.round(100000 * height) / 100000,
  };

  // Iterate over the child elements recursively
  for (let i = 0; i < element.children.length; i++) {
    // Store the layout info of each child element
    let child = element.children[i];
    if (
      child.tagName === "IFRAME" &&
      (child as HTMLIFrameElement).contentDocument
    ) {
      let iframeDoc = (child as HTMLIFrameElement).contentDocument;
      if (iframeDoc && iframeDoc.body) {
        child = iframeDoc.body;
      }
    }
    node.children.push(createLayoutTree(child, x, y));
  }

  return node;
}

export default class PageLayoutCollector {
  _index: number = 0;
  _nodes: Array<LayoutNode> = new Array();
  _visitedElementMap: Map<Element, number> = new Map();
  _serializedNodeLookup: Map<number, SerializedNode> = new Map();

  constructor() {}

  calculateDiff = (timestamp: number): LayoutDiff => {
    let adds: Array<SerializedNode> = new Array();
    let deletes: Array<number> = new Array();
    let updates: Array<SerializedNode> = new Array();
    // update elements in tree
    // 1: create the layout tree.
    let layoutTree = createLayoutTree(document.documentElement, 0.0, 0.0);
    // 2: walk tree.
    //     add visited nodes to visited nodes list.
    //     add new nodes to new nodes list
    //     add difference to delete/diff list
    let repeatNodes = new Array<LayoutNode>();
    let newNodes = new Array<LayoutNode>();
    this.walkLayoutTree(layoutTree, repeatNodes, newNodes);
    let deleteElements = new Set<Element>();
    for (let node of this._nodes) {
      deleteElements.add(node.element);
    }
    for (let node of repeatNodes) {
      deleteElements.delete(node.element);
    }
    // 3: do the chores
    //     populate element map
    //     record deleted elements
    //     record new elements
    //     record changes to deleted elements
    for (let element of deleteElements) {
      let index = this._visitedElementMap.get(element) as number;
      this._visitedElementMap.delete(element);
      this._serializedNodeLookup.delete(index);
      deletes.push(index);
    }
    for (let node of newNodes) {
      this._visitedElementMap.set(node.element, this._index++);
    }
    for (let node of newNodes) {
      let serializedNode = this.serializeNode(node);
      this._serializedNodeLookup.set(serializedNode.index, serializedNode);
      adds.push(serializedNode);
    }
    let nodeMap = new Map();
    for (let node of this._nodes) {
      nodeMap.set(node.element, node);
    }
    for (let node of repeatNodes) {
      if (!isEqual(node, nodeMap.get(node.element))) {
        let serializedNode = this.serializeNode(node);
        let index = serializedNode.index;
        let oldNode = this._serializedNodeLookup.get(index);
        let diffNode: SerializedNode = {
          index: index,
          rel_x: serializedNode.rel_x,
          rel_y: serializedNode.rel_y,
          width: serializedNode.width,
          height: serializedNode.height,
          children: serializedNode.children,
          time: serializedNode.time,
        };
        if (oldNode?.class != serializedNode.class) {
          diffNode.class = serializedNode.class;
        }
        if (oldNode?.src != serializedNode.src) {
          diffNode.src = serializedNode.src;
        }
        if (oldNode?.tag != serializedNode.tag) {
          diffNode.tag = serializedNode.tag;
        }
        if (oldNode?.id != serializedNode.id) {
          diffNode.id = serializedNode.id;
        }
        if (oldNode?.video_time != serializedNode.video_time) {
          diffNode.video_time = serializedNode.video_time;
        }
        if (oldNode?.video_playing != serializedNode.video_playing) {
          diffNode.video_playing = serializedNode.video_playing;
        }
        updates.push(diffNode);
      }
    }
    this._nodes = repeatNodes;
    for (let node of newNodes) {
      this._nodes.push(node);
    }

    return {
      timestamp: timestamp,
      add: adds,
      delete: deletes,
      update: updates,
    };
  };

  serializeNode = (node: LayoutNode): SerializedNode => {
    let element = node.element;
    const computedStyle = window.getComputedStyle(element);
    const src =
      (element as HTMLMediaElement).src ||
      (element as HTMLMediaElement).currentSrc ||
      (computedStyle.backgroundImage.includes("url")
        ? computedStyle.backgroundImage
            .replace(/.*\s?url\(['"]?/, "")
            .replace(/['"]?\).*/, "")
        : null) ||
      "";

    let index = this._visitedElementMap.get(element) as number;

    let children = [];
    for (let child of node.children) {
      children.push(this._visitedElementMap.get(child.element) as number);
    }

    let videoTime = 0;
    let videoPlaying = false;
    if (element.tagName === "video") {
      videoTime = (element as HTMLVideoElement).currentTime;
      videoPlaying = !(element as HTMLVideoElement).paused;
    }

    return {
      index: index,
      rel_x: node.relX,
      rel_y: node.relY,
      width: node.width,
      height: node.height,
      children: children,
      src: src,
      class: element.className,
      tag: element.tagName,
      id: element.id,
      // html: element.innerHTML,
      // text: element.textContent ? element.textContent : "",
      // x: rect.x,
      // y: rect.y,
      time: Date.now(),
      video_time: videoTime,
      video_playing: videoPlaying,
    };
  };

  walkLayoutTree = (
    node: LayoutNode,
    repeatNodes: Array<LayoutNode>,
    newNodes: Array<LayoutNode>
  ) => {
    if (this._visitedElementMap.has(node.element)) {
      repeatNodes.push(node);
    } else {
      newNodes.push(node);
    }
    for (let i = 0; i < node.children.length; i++) {
      this.walkLayoutTree(node.children[i], repeatNodes, newNodes);
    }
  };
}
