export default class Gaze {
  _index: number;
  _timestamp: number;
  _duration: number;
  _x: number;
  _y: number;

  /**
   * @param {number} index
   * @param {number} timestamp
   * @param {number} duration
   * @param {number} x
   * @param {number} y
   */
  constructor(
    index: number,
    timestamp: number,
    duration: number,
    x: number,
    y: number
  ) {
    this._index = index;
    this._timestamp = timestamp;
    this._duration = duration;
    this._x = x;
    this._y = y;
  }

  /**
   * @return {*}
   */
  serialize = (): any => {
    return {
      index: this._index,
      timestamp: this._timestamp,
      duration: this._duration,
      x: this._x,
      y: this._y,
    };
  };

  /**
   * @param {*} gaze
   * @return {Gaze}
   */
  static deserialize = (gaze: any): Gaze =>
    new Gaze(gaze.index, gaze.timestamp, gaze.duration, gaze.x, gaze.y);

  /**
   * @return {number} index (unique)
   */
  index = (): number => this._index;

  /**
   * @return {number} milliseconds
   */
  timestamp = (): number => this._timestamp;

  /**
   * @return {number} milliseconds
   */
  duration = (): number => this._duration;

  /**
   * @return {number} pixels
   */
  x = (): number => this._x;

  /**
   * @return {number} pixels
   */
  y = (): number => this._y;

  /**
   * @return {Gaze}
   */
  static Zero = (): Gaze => {
    return new Gaze(0, 0, 0, 0, 0);
  };
}
