import CalibratorList from "Calibration/CalibratorList";
import ClicklessDotGazeCalibrator from "Calibration/ClicklessDotGazeCalibrator";
import ExternalGazeCalibrator from "Calibration/ExternalGazeCalibrator";
import GazeCalibrator from "Calibration/GazeCalibrator";
import PoseSegmentedGazeCalibrator from "Calibration/PoseSegmentedGazeCalibrator";
import VideoGazeCalibrator from "Calibration/VideoGazeCalibrator";
import {
  GazeCalibratorConfig,
  VideoGazeCalibratorConfig,
} from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";

export default class GazeCalibratorFactory {
  /**
   * @param {GazeCalibratorConfig} calibratorConfig
   * @return {GazeCalibrator}
   */
  static build = (
    calibratorConfig: GazeCalibratorConfig | GazeCalibratorConfig[],
    eventManager: EventManager | null | undefined = null
  ): GazeCalibrator => {
    if (!eventManager) {
      eventManager = new EventManager();
    }
    if (calibratorConfig instanceof Array) {
      let calibrators = [];
      for (let cConfig of calibratorConfig) {
        calibrators.push(GazeCalibratorFactory.build(cConfig, eventManager));
      }
      return new CalibratorList(calibrators, eventManager);
    }
    let calibratorType = calibratorConfig.calibrator;
    switch (calibratorType) {
      case "CLICKLESS_DOT":
        return new ClicklessDotGazeCalibrator(calibratorConfig, eventManager);
      case "POSE_SEGMENTED_DOT":
        return new PoseSegmentedGazeCalibrator(calibratorConfig, eventManager);
      case "POSE_SEGMENTED_VIDEO":
        return new VideoGazeCalibrator(
          calibratorConfig as VideoGazeCalibratorConfig,
          eventManager
        );
      case "EXTERNAL":
        return new ExternalGazeCalibrator(calibratorConfig, eventManager);
      default:
        throw "FrameReaderFactory: Unsupported: " + calibratorType;
    }
  };
}
