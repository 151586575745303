import GazeDetector from "Detection/GazeDetector";
import { GazeDetectorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Gaze from "Models/Gaze";
import Landmarks468 from "Models/Landmarks";
import Resolution from "Models/Resolution";
import Trained from "Models/Trained";
import DomUtils from "Utils/DomUtils";
import WasmModule from "Utils/WasmModule";
import Throttle from "Workers/Throttle";

export default class RemoveFeatureGazeDetector extends GazeDetector {
  config: GazeDetectorConfig;
  _wasm: WasmModule | undefined;
  _lastOrientation: number | null | undefined;
  _model: Trained | null | undefined; // friendly data

  _gaze: Gaze | null | undefined;
  _completed: number;
  _requested: number;
  _prefix: string;
  _postFlightCount: number;

  /**
   * Web Assembly Gaze Detector may be configured to use different models
   * providing that they adhere to
   * @param {GazeDetectorConfig} config
   * @param {EventManager} eventManager
   */
  constructor(config: GazeDetectorConfig, eventManager: EventManager) {
    super(config, eventManager);
    this.config = config;
    this._prefix = this.makeId(config.tag);
    this._completed = 0;
    this._requested = 0;
    this._postFlightCount = 0;
  }

  /**
   * Initialize GazeDetector
   * Must be called prior to both calibration and detection
   */
  init = async () => {
    try {
      if (this._landmarksSubscription) {
        this._landmarksSubscription.remove();

        this._landmarksSubscription = null;
      }

      let _this = this;

      let george = this.config.george;
      let wasmFuture = WasmModule.build(george, this._eventManager).then(
        async (wasm) => {
          if (wasm.useWorkers()) {
            let buffer = new TextEncoder().encode(wasm.workerUrl() + "\0");
            let heapBuffer = wasm.arrayToHeap(buffer);
            await wasm
              .module()
              ._init_tracker(468, 76, 76, 50.0, heapBuffer.byteOffset);
            wasm.freeArray(heapBuffer);
          } else {
            await wasm.module()._init_tracker(468, 76, 76, 50.0);
          }

          _this._wasm = wasm;
        }
      );
      await wasmFuture;
    } catch (err) {
      throw {
        name: "LRTrackerInitError",
        message: "RecordFeatureGazeDetector: " + err,
        stack: new Error().stack,
      };
    }
  };

  /**
   * reset detection state
   */
  reset = () => {
    this._lastOrientation = null;
  };

  /**
   * resize the gaze detector
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    throw "WasmGazeDetector: Not Implemented!";
  };

  /**
   * @return {Boolean}
   */
  hasNextGaze = (): boolean => this._gaze != null;

  /**
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._gaze ? this._gaze : Gaze.Zero();
  };

  /**
   *
   * @param {Frame} frame
   * @param {Landmarks} landmarks
   * @param {Gaze} gaze
   */
  addTrainingData = (frame: Frame, landmarks: Landmarks468, gaze: Gaze) => {
    this._lastOrientation = frame.orientation();
    let response = -1;

    if (this._wasm) {
      let wasm = this._wasm;

      let module = this._wasm.module();

      let imageWidth = frame.imageData().width;
      let imageHeight = frame.imageData().height;
      let timestamp = landmarks.timestamp();
      let duration = landmarks.duration();
      let orientation = frame.orientation();
      let screenWidth = DomUtils.screenWidth();
      let screenHeight = DomUtils.screenHeight();
      let scale = DomUtils.devicePixelRatio();
      let dx = DomUtils.screenContentX();
      let dy = DomUtils.screenContentY();
      let x = scale * (dx + gaze.x());
      let y = scale * (dy + gaze.y());

      let featuresSize = module._detect_features();

      let offset = module._get_features_offset();

      let featureArr = module.HEAPU8.subarray(offset, offset + featuresSize);
      let urlData = Buffer.from(featureArr).toString("base64");
      let postfix = ".jpg";
      let name = this._prefix + "/" + this._requested + postfix;
      let misc = {
        gazeX: gaze.x(),
        gazeY: gaze.y(),
        screenWidth: DomUtils.screenWidth(),
        screenHeight: DomUtils.screenHeight(),
        screenContentX: dx,
        screenContentY: dy,
        scale: scale,
        ref_x: x,
        ref_y: y,
        face_roi: this._face_roi(landmarks),
      };
      let payload = {
        name: name,
        content: urlData,
        info: {
          session_id: this._prefix,
          sequence_number: this._requested,
          image: {
            width: imageWidth,
            height: imageHeight,
            orientation: orientation,
            timestamp: timestamp,
            duration: duration,
          },
          timestamp: Date.now(),
          misc: misc,
        },
      };
      this._requested++;

      this._post(payload);

      response = 1;
    }

    return response;
  };

  /**
   * @param {*} payload
   */
  _post = async (payload: any) => {
    Throttle.addEvent("RemoteFeatureGazeDetector:post");
    this._postFlightCount += 1;

    try {
      const response = await fetch(this.config.url, {
        method: "POST",
        body: JSON.stringify(payload),
      });
      const content = await response.json();
    } catch (e) {
      console.log("failed to upload!");
    }

    Throttle.removeEvent("RemoteFeatureGazeDetector:post");
    this._completed++;
    this._postFlightCount -= 1;
  };
  _face_roi = (landmarks: Landmarks468) => {
    let i0 = 33; // left eye

    let i1 = 263; // right eye

    let vs = landmarks.vertices();
    let dx = vs[i1 * 3 + 0] - vs[i0 * 3 + 0];
    let dy = vs[i1 * 3 + 1] - vs[i0 * 3 + 1];
    let normFactor = 1.0 / Math.sqrt(dx * dx + dy * dy);
    dx *= normFactor;
    dy *= normFactor;
    // define parallel and perpendicular basis vectors p and q
    // RowVector3f n_p{dx, dy, 0.0};
    // RowVector3f n_q{dy, -dx, 0.0};
    // calculate some dot products, find min/max.
    let p_min = 1e9,
      p_max = -1e9;
    let q_min = 1e9,
      q_max = -1e9;

    for (let i = 0; i < 468; i++) {
      let c_p = vs[i * 3] * dx + vs[i * 3 + 1] * dy; //.row(i).dot(n_p);

      let c_q = vs[i * 3] * dy - vs[i * 3 + 1] * dx; //.row(i).dot(n_q);

      p_min = Math.min(p_min, c_p);
      p_max = Math.max(p_max, c_p);
      q_min = Math.min(q_min, c_q);
      q_max = Math.max(q_max, c_q);
    }

    let width = p_max - p_min;
    let height = q_max - q_min;
    let size = 1.5 * Math.max(width, height);
    let theta = Math.atan2(dy, dx);
    let x = dx * (p_min + 0.5 * width) + dy * (q_min + 0.5 * height);
    let y = dy * (p_min + 0.5 * width) - dx * (q_min + 0.5 * height);
    return {
      x: x,
      y: y,
      size: size,
      theta: theta,
    };
  };

  /**
   * @param {String} tag
   */
  makeId = (tag: string) => {
    let id = "";
    let created = new Date();
    id += "ts=" + +created;

    // add platform
    //@ts-ignore
    window.mobilecheck = function () {
      let check = false;

      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true; //@ts-ignore
      })(navigator.userAgent || navigator.vendor || window.opera);

      return check;
    };
    //@ts-ignore
    let platform = window.mobilecheck() ? "mobile" : "desktop";
    id += ",platform=" + platform;
    // add session tag
    let tokens: string | any[] = [];

    if (tag) {
      tokens = tag.split("/");

      if (tokens.length > 0) {
        id += ",session=" + tokens[0];
      }
    }

    // Add query string
    let urlParams = new URLSearchParams(window.location.search);
    let query_string_tags = urlParams.getAll("lrtf_tag");
    if (query_string_tags.length != 0) id += ",tags=" + query_string_tags[0];
    // Add random id
    let sid = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 4; i++) {
      sid += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    id += ",SID=" + sid;

    for (let i = 1; i < tokens.length; i++) {
      id += "/" + tokens[i];
    }

    return id;
  };
}
