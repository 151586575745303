import { FrameReaderConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Resolution from "Models/Resolution";
import FrameReader from "Readers/FrameReader";
import DomUtils from "Utils/DomUtils";
import WasmModule from "Utils/WasmModule";
import Throttle from "Workers/Throttle";

export default class VideoFrameReader extends FrameReader {
  config: FrameReaderConfig; // Config for everything reader related. Is it a mirror image?, video filename etc

  _eventManager: EventManager; // This is an event emitter
  _videoElement: HTMLVideoElement; // Media is copied here by the browser (outside our control)
  _videoCanvas: HTMLCanvasElement; // Media is copied and scaled from source to here (we control this)

  _lastSourceResolution: Resolution; // last resolution of media source. This can change on mobile.
  _startedTime: number; // time which webcam started (ms)
  _lastFrameTime: number; // time of last frame (ms)
  _lastVideoTime: number; // last video element timestamp
  _lastFrameCheck: number; // time last frame was checked (ms)
  _lastValidFrameCheck: number; // time last valid frame was checked (ms)

  _throwCount: number; // current number of sequential throws
  _throwLimit: number; // limit number of sequential throws
  _module: WasmModule | undefined;

  /**
   * webgl specific
   */
  _glFailed: boolean;
  _gl: WebGL2RenderingContext | undefined;
  _data: Uint8ClampedArray | undefined; // data we copy to

  _textureBuffer: WebGLTexture | undefined; // receives texture from webcam images

  _framebuffer: WebGLFramebuffer | undefined; // the same as texture buffer

  _pixelPackBuffer: WebGLBuffer | undefined; // asynchronously streams frame data from gpu

  _bufferWidth = 0;
  _bufferHeight = 0;
  _sync: WebGLSync | undefined; // make sure everything is ready before copying data

  /**
   * Abstract base class is used for playing frames in real time from media sources.
   * If frames are not read fast enough they will be dropped.
   * Class can be extended when frame readers require an offscreen canvas and video element.
   * @param {FrameReaderConfig} config
   * @param {HTMLVideoElement} videoElement
   * @param {HTMLCanvasElement} videoCanvas
   */
  constructor(
    config: FrameReaderConfig,
    videoElement: HTMLVideoElement,
    videoCanvas: HTMLCanvasElement
  ) {
    super(config);
    this.config = config;
    this._videoElement = videoElement;
    this._videoCanvas = videoCanvas;
    this._eventManager = new EventManager();
    let time = Date.now();
    this._startedTime = time;
    this._lastFrameTime = time;
    this._lastVideoTime = time;
    this._lastFrameCheck = time;
    this._lastValidFrameCheck = time;
    this._lastSourceResolution = new Resolution(0, 0, 1, 1);
    this._throwCount = 0;
    this._throwLimit = 10;
    let platform = DomUtils.detectPlatform();
    this._glFailed =
      platform.name === "Safari" ||
      (platform.os != null &&
        (platform.os.family === "OS X" || platform.os.family === "iOS"));
  }

  /**
   * This emits events which can be relayed to another event manager
   * @override
   * @return {EventManager}
   */
  eventManager = (): EventManager => this._eventManager;

  /**
   * The underlying video element. May be used by child classes
   * @return {HTMLVideoElement}
   */
  videoElement = (): HTMLVideoElement => this._videoElement;

  /**
   * Start underlying media device and emit events
   * Note: this method is async
   * @return {Promise<boolean>}success
   */
  start = async (): Promise<boolean> => {
    throw "VideoFrameReader: Not implemented!";
  };

  /**
   * stop the underlying media source
   * @return {boolean}
   */
  stop = (): boolean => {
    throw "VideoFrameReader: Not implemented!";
  };

  /**
   * Is the next frame ready to be read?
   * @return {boolean}
   */
  hasNextFrame = (): boolean => {
    // False positives on firefox. Can drop duplicate frames on firefox elsewhere.
    if (Throttle.countAll() >= this.config.flightLimit) {
      return false;
    }

    let time = Date.now();
    let videoTime = this.videoTime();
    let frameValid = this._lastVideoTime !== videoTime;
    this._lastFrameCheck = time;
    let frameTimeoutDuration = this.config.frameTimeoutDuration;

    if (
      this._lastFrameCheck - (frameValid ? time : this._lastValidFrameCheck) >
      frameTimeoutDuration
    ) {
      console.warn("VideoFrameReader: Frame timed out. Video stopped. ");
      this.stop();
      throw {
        name: "FrameTimeoutError",
        message:
          "VideoFrameReader: No Frame detected for: " +
          (this._lastFrameCheck - this._lastValidFrameCheck) +
          "ms. ",
        stack: new Error().stack,
        toString: function () {
          return this.name + ": " + this.message;
        },
      };
    }

    if (frameValid) {
      // Make sure webGL is ready to go
      if (this._useWebGL()) {
        if (!this._gl) {
          let sourceResolution = this.sourceResolution();

          if (sourceResolution.width() * sourceResolution.height() > 0) {
            this._initWebGL();
          }

          return false;
        }

        if (!this._glReady) {
          return false;
        }
      }

      this._lastValidFrameCheck = time;
    }

    return frameValid;
  };

  /**
   * Return the next available frame from the frame reader.
   * @return {Frame}
   */
  nextFrame = (): Frame => {
    let sourceResolution = this.sourceResolution();
    let frameResolution = this.frameResolution();
    let videoTime = this.videoTime();
    // let timestamp = this._lastVideoTime * 1000.0 + this._startedTime;
    // let duration = 1000 * (videoTime - this._lastVideoTime);

    let timestamp = Date.now();
    let duration = timestamp - this._lastFrameTime;
    this._lastFrameTime = timestamp;

    let orientation = DomUtils.screenOrientation();
    this._lastVideoTime = videoTime;
    let maxFrameDuration = this.config.maxFrameDuration;
    duration = Math.min(duration, maxFrameDuration);
    duration = Math.max(duration, 0.0); // This shouldn't do anything

    let errorMessage = null;
    let stack = "";

    if (this._videoElement.paused) {
      errorMessage = "VideoFrameReader: VideoElement paused";
      let err = new Error();
      stack = err.stack ? err.stack : "";
    }

    if (this._videoElement && this._videoElement.srcObject) {
      let stream = this._videoElement.srcObject as MediaStream;
      let tracks = stream.getVideoTracks();

      if (tracks.length == 0) {
        errorMessage = "VideoFrameReader: No video tracks";
        let err = new Error();
        stack = err.stack ? err.stack : "";
      } else {
        for (let track of tracks) {
          if (!track.enabled) {
            errorMessage = "VideoFrameReader: Video track not enabled";
            let err = new Error();
            stack = err.stack ? err.stack : "";
            break;
          }
        }
      }
    }

    if (errorMessage) {
      console.warn("VideoFrameReader: Interrupted. Stop gracefully. ");
      this.stop();
      throw {
        name: "InterruptedError",
        message: errorMessage,
        stack: stack,
        toString: function () {
          return this.name + ": " + this.message;
        },
      };
    }

    try {
      let imageData;

      if (this._useWebGL()) {
        imageData = this._parseContextGL(sourceResolution);
      } else {
        imageData = this._parseContext2D(sourceResolution, frameResolution);
      }

      this._throwCount = 0;

      return new Frame(timestamp, duration, imageData, orientation);
    } catch (err) {
      this._throwCount += 1;

      if (this._throwCount >= this._throwLimit) {
        errorMessage = "VideoFrameReader: Interrupted while reading frame. ";
        console.warn("VideoFrameReader: Interrupted. Stop gracefully. ");
        this.stop();
        throw {
          name: "InterruptedError",
          message: errorMessage,
          stack: new Error().stack,
          toString: function () {
            return this.name + ": " + this.message;
          },
        };
      } else {
        return Frame.BuildNoise(
          frameResolution.width(),
          frameResolution.height()
        );
      }
    }
  };

  /**
   * parse webcam imageData using reliable canvas 2d
   * @param {Resolution} sourceResolution
   * @param {Resolution} frameResolution
   * @returns imageData
   */
  _parseContext2D = (
    sourceResolution: Resolution,
    frameResolution: Resolution
  ) => {
    let ctx = this._videoCanvas.getContext("2d") as CanvasRenderingContext2D;

    ctx.drawImage(
      this._videoElement,
      // sourceResolution.x(),
      // sourceResolution.y(),
      // sourceResolution.width(),
      // sourceResolution.height(),
      frameResolution.x(),
      frameResolution.y(),
      frameResolution.width(),
      frameResolution.height()
    );
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    return ctx.getImageData(
      frameResolution.x(),
      frameResolution.y(),
      frameResolution.width(),
      frameResolution.height()
    );
  };
  _useWebGL = () => {
    return this.config.useWebGL && !this._glFailed;
  };
  _initWebGL = () => {
    let options = {
      stencil: false,
    };

    let gl = this._videoCanvas.getContext(
      "webgl2",
      options
    ) as WebGL2RenderingContext;

    if (!gl) {
      this._glFailed = true;
      return false;
    }

    let textureBuffer = gl.createTexture() as WebGLTexture;
    let framebuffer = gl.createFramebuffer() as WebGLFramebuffer;
    let pixelPackBuffer = gl.createBuffer() as WebGLBuffer;
    let sourceResolution = this.sourceResolution();
    let width = sourceResolution.width();
    let height = sourceResolution.height();
    gl.bindTexture(gl.TEXTURE_2D, textureBuffer);
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.bindBuffer(gl.PIXEL_PACK_BUFFER, pixelPackBuffer);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      this._videoElement
    );
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      textureBuffer,
      0
    );
    gl.bufferData(gl.PIXEL_PACK_BUFFER, width * height * 4, gl.STREAM_READ);
    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, 0);
    this._gl = gl;
    this._textureBuffer = textureBuffer;
    this._framebuffer = framebuffer;
    this._bufferWidth = width;
    this._bufferHeight = height;
    this._pixelPackBuffer = pixelPackBuffer;
    this._sync = gl.fenceSync(gl.SYNC_GPU_COMMANDS_COMPLETE, 0) as WebGLSync;
    return true;
  };
  _glReady = () => {
    if (!this._gl) {
      return false;
    }

    let gl = this._gl;
    let status = gl.getSyncParameter(this._sync as WebGLSync, gl.SYNC_STATUS);
    return status == gl.SIGNALED;
  };

  /**
   * parse webcam imageData using accelerated webgl
   * @param {Resolution} sourceResolution
   * @returns imageData
   */
  _parseContextGL = (sourceResolution: Resolution) => {
    let width = sourceResolution.width();
    let height = sourceResolution.height();
    this._videoCanvas.width = width;
    this._videoCanvas.height = height;
    let bufferWidth = this._bufferWidth;
    let bufferHeight = this._bufferHeight;
    let gl = this._gl;
    let textureBuffer = this._textureBuffer;

    let frameData = this._frameData(bufferWidth, bufferHeight);

    if (gl && this._glReady()) {
      let bufferSize = bufferWidth * bufferHeight;
      let size = width * height;
      gl.getBufferSubData(gl.PIXEL_PACK_BUFFER, 0, frameData, 0, 4 * size);

      if (bufferSize != size) {
        gl.bufferData(gl.PIXEL_PACK_BUFFER, size * 4, gl.STREAM_READ);
      }

      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        this._videoElement
      );
      gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.COLOR_ATTACHMENT0,
        gl.TEXTURE_2D,
        textureBuffer as WebGLTexture,
        0
      );
      gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, 0);
      gl.deleteSync(this._sync as WebGLSync);
      this._sync = gl.fenceSync(gl.SYNC_GPU_COMMANDS_COMPLETE, 0) as WebGLSync;
      this._bufferWidth = width;
      this._bufferHeight = height;
    }

    let imageData = new ImageData(frameData, bufferWidth, bufferHeight);
    return imageData;
  };

  /**
   * The expected resolution of frames to be provided by the frame reader
   * This may change from time to time
   * @return {Resolution}
   */
  frameResolution = (): Resolution => {
    let videoCanvas = this._videoCanvas;
    return new Resolution(0, 0, videoCanvas.width, videoCanvas.height);
  };

  /**
   * The maximum resolution which the reader supports
   * This can be configured
   * @return {Resolution}
   */
  maxFrameResolution = (): Resolution => {
    let resolution = this.sourceResolution();
    let width = 0;
    let height = 0;

    if (resolution.width() > resolution.height()) {
      width = Math.max(this.config.width, this.config.height);
      height = Math.min(this.config.width, this.config.height);
    } else if (resolution.height() > resolution.width()) {
      width = Math.min(this.config.width, this.config.height);
      height = Math.max(this.config.width, this.config.height);
    } else {
      width = this.config.width;
      height = this.config.height;
    }

    return new Resolution(0, 0, width, height);
  };

  /**
   * Update the resolution of the frame reader
   * This is usually only called internally
   * @param {Resolution} resolution
   */
  updateFrameResolution = (resolution: Resolution) => {
    this._videoCanvas.width = resolution.width();
    this._videoCanvas.height = resolution.height();
    this._lastSourceResolution = this.sourceResolution();
  };

  /**
   * Has the media source resolution changed?
   * @return {Boolean}
   */
  sourceResolutionChanged = (): boolean =>
    !this.sourceResolution().equals(this._lastSourceResolution);

  /**
   * Is called when the media source resolution changes
   * @return {Resolution}
   */
  sourceResolution = (): Resolution => {
    throw "VideoFrameReader: Not implemented!";
  };

  /**
   * Render video from offscreenCanvas to external canvas
   * @param {CanvasRenderingContext2D} ctx
   */
  renderVideo = (ctx: CanvasRenderingContext2D) => {
    let frameResolution = this.frameResolution();
    ctx.drawImage(
      this._videoCanvas,
      frameResolution.x(),
      frameResolution.y(),
      frameResolution.width(),
      frameResolution.height()
    );
  };

  /**
   * Return the quoted framerate from the underlying media device
   * @return {Number}
   */
  framerate = (): number => {
    throw "VideoFrameReader: Not implemented!";
  };

  /**
   * @returns current time according to video element
   * temporary fix applied for iPhone (this is in seconds)
   */
  videoTime = () => {
    //let currentTime = this._videoElement.currentTime
    //  ? this._videoElement.currentTime
    //  : Date.now() / 1000.0;
    let currentTime = this._videoElement.currentTime;
    return currentTime && !isNaN(currentTime) ? currentTime : 0;
  };

  /**
   * returns data to read pixels to
   * @param {number} width pixels
   * @param {number} height pixels
   */
  _frameData = (width: number, height: number) => {
    let imageSize = width * height * 4;

    if (this.config.allocator.allocate) {
      if (this._module) {
        let module = this._module.module();

        let offset = module._get_image_offset(width, height);

        let heapBytes = module.HEAPU8.subarray(offset, offset + imageSize);
        let buffer = heapBytes.buffer; // no data is copied!

        this._data = new Uint8ClampedArray(buffer, offset, imageSize);
        return this._data;
      } else {
        let wasmConfig = this.config.allocator;

        if (WasmModule.built(wasmConfig)) {
          WasmModule.build(wasmConfig, this._eventManager).then(
            async (module) => {
              this._module = module;
            }
          );
        }
      }
    }

    if (!this._data || this._data.length != imageSize) {
      this._data = new Uint8ClampedArray(imageSize);
    }

    return this._data;
  };
}
