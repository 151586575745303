import React from "react";
import {
  getSessionRequest,
} from "../../../api";
import {
  setTmpSessionId,
} from "../../../store/reducers/session";
import { ErrorDetail, SessionSectionProps, SessionState } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { extensionSendMessage } from "../../../utils/extensionCom";

export interface SessionStartProps extends SessionSectionProps {
  trackerRef: React.RefObject<HTMLDivElement>;
  panelist: string;
}
export const SessionStart = ({
  broker,
  dispatch,
  panelist,
  trackerRef,
  setSessionState,
  errorHandle
}: SessionStartProps) => {
  // TODO: CHECK PERMISSION STATUS

  const cont = async () => {
    try {
      let m: PanelMessages.ExternalMessage = {
        type: PanelMessages.ExternalMessageType.CheckPermissions
      };
      // TODO: ACTUALLY DO SOMETHING WITH THIS
      let perms: boolean = await extensionSendMessage(m);
      if (!perms) {
        throw ErrorClass.CAMERA_PERMISSIONS
      }
      let res = await getSessionRequest(panelist);
      dispatch(setTmpSessionId(res.tmpSessionId));
      // HANDLE GET SESSION ID ERROR
      let div = trackerRef.current;
      if (!div) throw ErrorFormatter.getError(ErrorClass.TRACKER_DIV);
      await broker.init(30000, div);
      setSessionState(SessionState.POSITION);
    } catch (e) {
      if (e === ErrorClass.EXTENSION_COMMS_FAIL) {
        // TODO: MAYBE COULD BE BETTER?
        window.location.href = "/install";
      } else if (e === ErrorClass.CAMERA_PERMISSIONS) {
        let detail: ErrorDetail = { 
          message: ErrorFormatter.formatError(ErrorFormatter.getError(e)),
          action: () => (window.location.reload()),
          state: SessionState.ERROR
        }
        errorHandle(detail);
      } else {
        errorHandle(ErrorFormatter.formatError())
      }
    }
  };
  return (
    <>
      <p className="font-semibold mb-10">Participate in a research session</p>
      <p className="mb-10">Note: We do not record pictures or video of you</p>
      <button
        onClick={
          cont
          // () => setSessionState(SessionState.POSITION)
        }
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Start a 10 minute Research session
      </button>
    </>
  );
};
