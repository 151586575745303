import BRFv4LandmarkDetector from "Detection/BRFv4LandmarkDetector";
import BRFv5LandmarkDetector from "Detection/BRFv5LandmarkDetector";
import GeorgeLandmarkDetector from "Detection/GeorgeLandmarkDetector";
import LandmarkDetector from "Detection/LandmarkDetector";
import { LandmarkDetectorConfig } from "Managers/ConfigManager";

// import TFJSLandmarkDetector from "Detection/TFJSLandmarkDetector";
// import MediaPipeLandmarkDetector from "Detection/MediaPipeLandmarkDetector";
export default class LandmarkDetectorFactory {
  static build = (config: LandmarkDetectorConfig): LandmarkDetector => {
    if (config.detector === "BRF_4") {
      return new BRFv4LandmarkDetector(config); // return await import(
      //   /* webpackChunkName: "BRFv4LandmarkDetector" */ "./BRFv4LandmarkDetector"
      // ).then((BRFv4LandmarkDetector) => {
      //   return new BRFv4LandmarkDetector.default(config);
      // });
    } else if (config.detector === "BRF_5") {
      return new BRFv5LandmarkDetector(config);
    } else if (config.detector === "GEORGE") {
      return new GeorgeLandmarkDetector(config); // } else if (config.detector === "TFJS") {
      //   return new TFJSLandmarkDetector(config);
      // } else if (config.detector === "MEDIAPIPE") {
      //   return new MediaPipeLandmarkDetector(config);
    } else {
      throw (
        "LandmarkDetectorFactory: Unknown landmark detector type: " +
        config.detector
      );
    }
  };
}
