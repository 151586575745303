import GazeDetector from "Detection/GazeDetector";
import { GazeDetectorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Gaze from "Models/Gaze";
import Landmarks from "Models/Landmarks";
import Resolution from "Models/Resolution";
import Trained from "Models/Trained";
import DomUtils from "Utils/DomUtils";

export default class JsonRpcGazeDetector extends GazeDetector {
  config: GazeDetectorConfig;
  _lastGaze: Gaze | null | undefined;
  _gaze: Gaze | null | undefined;
  _websocket: WebSocket | undefined;
  _messageId = 0;
  _pollDelay = 45.5;
  _activeMessages: Record<string, any>;
  _heartbeat: any;
  _lastGazeHeartbeat = 0;
  _heartbeatCadence = 3000;
  _index = 0;

  /**
   * Web Assembly Gaze Detector may be configured to use different models
   * providing that they adhere to
   * @param {GazeDetectorConfig} config
   * @param {EventManager} eventManager
   */
  constructor(config: GazeDetectorConfig, eventManager: EventManager) {
    super(config, eventManager);
    this.config = config;
    eventManager.subscribe("onCalibrationStarted", () => {
      this._sendMessage("calibrate_tracker");
    });
    this._activeMessages = {};
  }

  /**
   * Initialize GazeDetector
   * Must be called prior to both calibration and detection
   */
  init = async () => {
    try {
      const address = `ws://${this.config.ip}:${this.config.port}/ws`;
      let websocket = new WebSocket(address);
      this._activeMessages = {};
      this._messageId = 0;
      await new Promise((resolve, reject) => {
        websocket.onopen = resolve;
        websocket.onerror = reject;
      });

      websocket.onmessage = (event: Record<string, any>) => {
        let data = JSON.parse(event["data"]);
        let id = data.id;
        let result = {
          success: false,
        };

        if ("result" in data) {
          result = data.result;
        }

        if (id in this._activeMessages) {
          let payload = this._activeMessages[id];
          delete this._activeMessages[id];
          switch (payload.method) {
            case "calibrate_tracker":
              if (result.success) {
                this._eventManager.publish("onCalibrationComplete", {
                  timestamp: Date.now(),
                });
              } else {
                this._eventManager.publish("onCalibrationFailed", {
                  timestamp: Date.now(),
                });
              }

              break;

            case "tracker_on":
              break;

            case "tracker_off":
              break;

            case "tracker_id":
              break;

            case "is_tracker_on":
              break;

            case "gaze_position":
              if ("gaze" in result && result.success) {
                let resGaze = result.gaze as {
                  t: number;
                  x: number;
                  y: number;
                };
                let timestamp = resGaze.t;
                let duration = this._pollDelay; // initial dummy value

                if (this._lastGaze) {
                  duration = timestamp - this._lastGaze.timestamp();
                }

                if (duration > 0) {
                  let scale = DomUtils.devicePixelRatio();
                  let dx = DomUtils.screenContentX();
                  let dy = DomUtils.screenContentY();
                  let x = resGaze.x / scale - dx;
                  let y = resGaze.y / scale - dy;
                  this._lastGaze = this._gaze;
                  this._gaze = new Gaze(
                    this._index++,
                    timestamp,
                    duration,
                    x,
                    y
                  );
                }
              }

              break;

            case "get_frame":
              break;

            case "kill":
              break;

            case "heartbeat":
              break;

            default:
              console.warn(
                "JsonRpcGazeDetector: Unknown method: " + payload.method
              );
          }
        } else {
          console.warn(
            "JsonRpcGazeDetector: Unaccounted for message id: " + id
          );
        }
      };

      websocket.onerror = (event: any) => {
        this._eventManager.publish("onError", event);
      };

      this._websocket = websocket;

      if (this._heartbeat) {
        clearInterval(this._heartbeat);
      }

      this._heartbeat = setInterval(() => {
        this._sendMessage("heartbeat", {
          data: {
            layer: "rni-webcam-js-alive",
            timestamp: Date.now(),
          },
        });
      }, this._heartbeatCadence);
    } catch (err) {
      throw {
        name: "LRTrackerInitError",
        message: "JsonRpcGazeDetector: " + err,
        stack: new Error().stack,
      };
    }
  };
  onStart = () => {
    this._lastGaze = null;
    this._lastGazeHeartbeat = Date.now();

    this._sendMessage("tracker_on");

    this._eventManager.startEmitter(this._pollGaze, this._pollDelay);
  };
  onStop = () => {
    this._eventManager.stopEmitter();

    this._sendMessage("tracker_off");
  };

  /**
   * reset detection state
   */
  reset = () => {
    // TODO: implement functionality
  };

  /**
   * Update the gaze detector
   * @param {Frame} frame to detect gaze from
   * @param {Landmarks} landmarks to be used during gaze detection
   */
  update = (frame: Frame, landmarks: Landmarks) => {
    return this.nextGaze();
  };

  /**
   * resize the gaze detector
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    throw "WasmGazeDetector: Not Implemented!";
  };

  /**
   * @return {boolean}
   */
  hasNextGaze = (): boolean => {
    return this._gaze != null && this._gaze !== this._lastGaze;
  };

  /**
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._gaze ? this._gaze : Gaze.Zero();
  };

  /**
   *
   * @param {Frame} frame
   * @param {Landmarks} landmarks
   * @param {Gaze} gaze
   */
  addTrainingData = (frame: Frame, landmarks: Landmarks, gaze: Gaze) => {
    return true;
  };

  /**
   * train gaze detector using supplied data
   */
  train = () => {
    /*
    Actually performs calibration (externally...)
    */
    // console.warn("JsonRpcGazeDetector: Don't train me!");
    return Trained.Zero();
  };

  /**
   * @return {Trained}
   */
  model = (): Trained => {
    throw "JsonRpcGazeDetector: This model cannot be accessed!";
  };

  /**
   * set the model which is used for gaze detection
   * @param {Trained} model
   */
  setModel = (model: Trained) => {
    throw "JsonRpcGazeDetector: This model cannot be set!";
  };

  _pollGaze = () => {
    this._sendMessage("gaze_position");

    if (this.hasNextGaze()) {
      this._lastGaze = this._gaze;
      let rawGaze = this.nextGaze();

      if (!rawGaze) {
        return;
      }

      let averagedGaze = this._gazeFilter.apply(rawGaze);

      let timestamp = Date.now();
      let res = {
        rawGaze: rawGaze,
        averagedGaze: averagedGaze,
      };

      if (timestamp > this._lastGazeHeartbeat + this._heartbeatCadence) {
        this._lastGazeHeartbeat = timestamp;

        this._sendMessage("heartbeat", {
          data: {
            layer: "rni-webcam-js-gaze",
            timestamp: timestamp,
            gaze_x: rawGaze.x(),
            gaze_y: rawGaze.y(),
          },
        });
      }

      this._eventManager.publish("onNextRawGaze", res);
    }
  };
  _sendMessage = (method: string, params: Record<string, any> = {}) => {
    if (!this._websocket) {
      throw "JsonRpcGazeDetector: Not yet initialized!";
    }

    let id = this._messageId++;
    let payload = {
      jsonrpc: "2.0",
      method: method,
      id: id,
      params: params,
    };

    if (method == "status" || method == "helo") {
      payload.params = {
        participant_reference: "ref",
        study_id: 1,
        broker_download_url: "Broker/webcam/open/status.json",
      };
    }

    this._activeMessages[id] = payload;

    this._websocket.send(JSON.stringify(payload));
  };
}
