import React from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { startWildRequest } from "../../../api";
import { SessionSectionProps } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";

interface SessionExternalProps extends SessionSectionProps {
  panelist: string;
  tmpSessionId: string;
  panelistId: number;
  accessCode: string;
}
export const SessionExternal = ({
  broker,
  setSessionState,
  panelist,
  tmpSessionId,
  panelistId,
  accessCode,
  errorHandle
}: SessionExternalProps) => {
  const currentDate = new Date();
  const sessionEndDate = new Date(
    currentDate.setMinutes(currentDate.getMinutes() + 10)
  );

  const start = async () => {
    try {
      startWildRequest(accessCode, tmpSessionId, panelistId);
      broker.saveModel("model", "errorCorrection");
      if (!!broker.trackersController) {
        broker.turnOffCamera();
      }
      const model = localStorage.getItem("model");
      const errorCorrection = localStorage.getItem("errorCorrection");
      const extensionId = process.env.EXTENSION_ID;

      if (model && errorCorrection) {
        console.log("sending model to extension...");
        let m: PanelMessages.InitSession = {
          type: PanelMessages.ExternalMessageType.InitSession,
          data: {
            broker: {
              model,
              errorCorrection,
            },
            panelist: panelist,
            panelistId: panelistId,
            tmpSessionId: tmpSessionId,
            // sessionLength: 10000, // 10 seconds
            sessionLength: 200000, // TODO: SHOULD BE FROM STATE,
          },
        };
        chrome.runtime.sendMessage(extensionId, m, (_r) => {
          // TODO: BETTER HANDLE SESSION START REDIRECT
          window.location.href = "https://google.com/";
        });
      } else {
        throw ErrorFormatter.getError(ErrorClass.MISSING_MODEL)
      }
    } catch (e) {
      errorHandle(ErrorFormatter.formatError(e))
    }
  };

  return (
    <>
      <p className="font-semibold text-lg mb-10">Start Your Session!</p>
      <p className="mb-10">
        If any of the scores drop we will interrupt the session and allow you to
        adjust.
      </p>
      <p className="font-semibold mb-10">What to do in your session?</p>
      <ol className="mb-10">
        <li>We will direct you in the tab to a random website</li>
        <li>Go to a few websites as you normally would</li>
        <li>
          After 10 minutes you will be automatically redirected to a validation
          screen at the end of the session
        </li>
      </ol>
      {/* TODO: BETTER DATE FORMATTING */}
      <p className="mb-10">
        Session will end at {sessionEndDate.toTimeString()}
      </p>
      <button
        onClick={start}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Start Session
      </button>
    </>
  );
};
