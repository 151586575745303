import { FrameReaderConfig } from "Managers/ConfigManager";
import Resolution from "Models/Resolution";
import VideoFrameReader from "Readers/VideoFrameReader";
import DomUtils from "Utils/DomUtils";

export default class VideoFileFrameReader extends VideoFrameReader {
  _videoSource; // the video source element

  /**
   * VideoFileFrameReader records frames from a video file
   * This frame reader can be played/paused using start and stop functions
   * @param {FrameReaderConfig} config
   * @param {HTMLVideoElement} videoElement
   * @param {HTMLCanvasElement} videoCanvas
   * @param {HTMLSourceElement} videoSource
   */
  constructor(
    config: FrameReaderConfig,
    videoElement: HTMLVideoElement,
    videoCanvas: HTMLCanvasElement,
    videoSource: HTMLSourceElement
  ) {
    super(config, videoElement, videoCanvas);
    this._videoSource = videoSource;
    videoElement.appendChild(videoSource);
  }

  /**
   * Build a VideoFileFrameReader from file.
   * The location of this file is described in config.
   * @param {FrameReaderConfig} config
   * @return {VideoFileFrameReader}
   */
  static build = (config: FrameReaderConfig): VideoFileFrameReader => {
    let videoSource = DomUtils.createVideoSource(config.videoFile);
    let videoCanvas = DomUtils.createOffscreenCanvas();
    let videoElement = DomUtils.createVideoElement() as HTMLVideoElement;
    videoElement.setAttribute("loop", "");
    return new VideoFileFrameReader(
      config,
      videoElement,
      videoCanvas,
      videoSource
    );
  };

  /**
   * Play the video and begin emitting events
   * @return {Promise<Boolean>} success
   */
  start = async (): Promise<boolean> => {
    if (!this.videoElement().muted) {
      this.videoElement().muted = true;
    }

    this.videoElement().play();
    this.eventManager().publish("onVideoStart", { timestamp: Date.now() });
    let dt = 1000.0 / this.framerate();
    this._lastFrameTime = this.videoTime() * 1000 + this._startedTime;
    this._lastFrameHash = -1;
    this._lastUniqueFrame = Date.now();
    this.eventManager().startEmitter(this._emit, dt);
    this.eventManager().publish("onEmitterStart", { timestamp: Date.now() });
    return true;
  };

  /**
   * stop the video and stop emitting events
   * @return {Boolean} success
   */
  stop = (): boolean => {
    this.eventManager().stopEmitter();
    return true;
  };

  /**
   * When mobile browser changes orientation this method is called
   * @emit onSourceResolutionChanged
   * @override
   * @return {Resolution}
   */
  sourceResolution = (): Resolution => {
    let width = this.videoElement().videoWidth;
    let height = this.videoElement().videoHeight;
    return new Resolution(0, 0, width, height);
  };

  /**
   * Quoted framerate (frames per second) of the video file
   * This currently returns a spoofed value
   * @override
   * @return {Number}
   */
  framerate = (): number => {
    return 30;
  };
}
