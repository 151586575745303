import StorageHandler from "Utils/StorageHandler";

export default class Trained {
  _timestamp: number;
  _name: string;
  _version: string;
  _orientation: number;
  //TODO: change to bounding box
  _offset: {
    _x: number;
    _y: number;
  };
  _data: string;

  /**
   * represent the data needed to store trained models for gaze detection
   * @param {number} timestamp
   * @param {string} name
   * @param {string} version
   * @param {string} orientation
   * @param {number} xOffset
   * @param {number} yOffset
   * @param {string} data
   */
  constructor(
    timestamp: number,
    name: string,
    version: string,
    orientation: number,
    xOffset: number,
    yOffset: number,
    data: string
  ) {
    this._timestamp = timestamp;
    this._name = name;
    this._version = version;
    this._orientation = orientation;
    this._offset = {
      _x: xOffset,
      _y: yOffset,
    };
    this._data = data;
  }

  /**
   * load saved trained model from local storage
   * @param {*} storageKey
   */
  static fromLocalStorage = (storageKey: string) => {
    let json = StorageHandler.getItem(storageKey);

    if (json) {
      let saveData = JSON.parse(json);
      let model = Trained.deserialize(saveData);
      return model;
    }

    return null;
  };

  /**
   * save trained model to local storage
   * @param {string} storageKey
   */
  toLocalStorage = (storageKey: string) => {
    let json = JSON.stringify(this.serialize());
    StorageHandler.setItem(storageKey, json);
  };

  /**
   * serialize trained model by removing all functions
   * useful when communicating with web workers
   * @return {*}
   */
  serialize = (): any => {
    return {
      name: this._name,
      version: this._version,
      timestamp: this._timestamp,
      data: this._data,
      offset: {
        x: this._offset._x,
        y: this._offset._y,
      },
      orientation: this._orientation,
    };
  };

  /**
   * reverse serialization
   * @param {*} trained
   * @return {Trained}
   */
  static deserialize = (trained: any): Trained =>
    new Trained(
      trained.timestamp,
      trained.name,
      trained.version,
      trained.orientation,
      trained.offset.x,
      trained.offset.y,
      trained.data
    );

  /**
   * @return {number}
   */
  timestamp = (): number => this._timestamp;

  /**
   * @return {string}
   */
  name = (): string => this._name;

  /**
   * @return {string}
   */
  version = (): string => this._version;

  /**
   * @return {number}
   */
  orientation = (): number => this._orientation;

  /**
   * @return {number}
   */
  xOffset = (): number => this._offset._x;

  /**
   * @return {number}
   */
  yOffset = (): number => this._offset._y;

  /**
   * @return {string}
   */
  dataString = (): string => this._data;

  /**
   * @return {Uint8Array}
   */
  data = (): Uint8Array => {
    return Uint8Array.from(JSON.parse("[" + this._data + "]"));
  };

  static Zero = () => {
    return new Trained(0, "Zero", "0.0", 0, 0, 0, "");
  };
}
