import Eyes from "Models/Eyes";
import Landmarks from "Models/Landmarks";

export default class Landmarks468 extends Landmarks {
  /**
   * Landmarks represents 68 point facial landmark detections
   * This class may also be extended to contain information about facial rotation
   * and additional data needed to describe facial landmark detections
   * @param {number} timestamp milliseconds
   * @param {number} timestamp milliseconds
   * @param {Float32Array} vertices
   */
  constructor(timestamp: number, duration: number, vertices: Float32Array) {
    super(timestamp, duration, vertices);
  }

  /**
   * Serialize Landmarks by removing all functions
   * This is useful when communicating with web workers
   * @return {*}
   */
  serialize = (): any => {
    return {
      class: "Landmarks468",
      timestamp: this._timestamp,
      duration: this._duration,
      vertices: this._vertices,
    };
  };

  /**
   * reverse serialization
   * @param {*} landmarks
   * @return {Landmarks468}
   */
  static deserialize = (landmarks: any): Landmarks468 =>
    new Landmarks468(
      landmarks.timestamp,
      landmarks.duration,
      landmarks.vertices
    );

  /**
   * @return {number} milliseconds
   */
  timestamp = (): number => {
    return this._timestamp;
  };

  /**
   * @return {number} milliseconds
   */
  duration = (): number => {
    return this._duration;
  };

  /**
   * @return {[{x: number, y: number, z: number}]}
   */
  landmarks = (): { x: number; y: number; z: number }[] => {
    let landmarks = [];

    for (let i = 0; i < this._vertices.length; i += 3) {
      landmarks.push({
        x: this._vertices[i],
        y: this._vertices[i + 1],
        z: this._vertices[i + 2],
      });
    }

    return landmarks;
  };

  /**
   * @return {Eyes}
   */
  eyes = (): Eyes => {
    let vertices = this.vertices();
    let left = {
      in: {
        x: vertices[399],
        y: vertices[400],
      },
      out: {
        x: vertices[99],
        y: vertices[100],
      },
    };
    let right = {
      in: {
        x: vertices[1086],
        y: vertices[1087],
      },
      out: {
        x: vertices[789],
        y: vertices[790],
      },
    };
    return new Eyes(this._timestamp, this._duration, left, right);
  };

  /**
   * Point of origin1
   * This is tip of nose
   * @return {{x: number, y: number}}
   */
  origin = (): { x: number; y: number } => {
    throw "Landmarks468: Not implemented!";
  };

  smileFactor = (): number => {
    //CHANGE: getFaceData --> update
    let smileFactor = 0;
    let vertices = this.vertices();

    if (vertices) {
      if (vertices.length !== 0) {
        let l = vertices.length;
        let k, yLE, yRE;

        // Left eye movement (y)
        for (k = 36, l = 41, yLE = 0; k <= l; k++) {
          yLE = yLE + (vertices[k * 2 + 1] - vertices[k * 2 + 1]);
        }

        yLE = yLE / 6;

        // Right eye movement (y)
        for (k = 42, l = 47, yRE = 0; k <= l; k++) {
          yRE += vertices[k * 2 + 1] - vertices[k * 2 + 1];
        }

        yRE = yRE / 6;
        let yN = 0;
        // Compare to overall movement (nose y)
        yN += vertices[27 * 2 + 1] - vertices[27 * 2 + 1];
        yN += vertices[28 * 2 + 1] - vertices[28 * 2 + 1];
        yN += vertices[29 * 2 + 1] - vertices[29 * 2 + 1];
        yN += vertices[30 * 2 + 1] - vertices[30 * 2 + 1];
        yN /= 4;
        let p0_x = vertices[48 * 2]; // mouth corner left

        let p0_y = vertices[48 * 2 + 1];
        let p1_x = vertices[54 * 2]; // mouth corner left

        let p1_y = vertices[54 * 2 + 1];
        let p2_x = vertices[39 * 2]; // mouth corner left

        let p2_y = vertices[39 * 2 + 1];
        let p3_x = vertices[42 * 2]; // mouth corner left

        let p3_y = vertices[42 * 2 + 1];
        let mouthWidth = Math.sqrt(
          Math.pow(p1_x - p0_x, 2) + Math.pow(p1_y - p0_y, 2)
        );
        let eyeDist = Math.sqrt(
          Math.pow(p3_x - p2_x, 2) + Math.pow(p3_y - p2_y, 2)
        );
        smileFactor = mouthWidth / eyeDist;
        smileFactor -= 1.4; // 1.40 - neutral, 1.70 smiling

        smileFactor *= 4.0;

        if (smileFactor < -1.0) {
          smileFactor = -1.0;
        }

        if (smileFactor > 1.0) {
          smileFactor = 1.0;
        }

        smileFactor *= 100;
        smileFactor = parseInt("" + smileFactor);
      }
    }

    return smileFactor;
  };

  static Zeros = () => {
    return new Landmarks468(0, 0, new Float32Array(468 * 3));
  };
}
