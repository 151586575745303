import { FrameReaderConfig } from 'Managers/ConfigManager';
import EventManager from 'Managers/EventManager';
import Frame from 'Models/Frame';
import Resolution from 'Models/Resolution';
import FrameReader from 'Readers/FrameReader';
import DomUtils from 'Utils/DomUtils';

export default class ImageElementFrameReader extends FrameReader {
  _imageElement: HTMLImageElement; // Media is copied here by the browser (outside our control)
  _videoCanvas: HTMLCanvasElement; // Media is copied and scaled from source to here (we control this)
  _eventManager: EventManager; // This is an event emitter
  _startedTime: number; // time which webcam started
  _lastFrameTime: number; // time of last available frame
  _lastSourceResolution: Resolution; // last resolution of media source. This can change on mobile.

  _lastSrc: string;
  _lastFrame: Frame; // to be removed shortly

  /**
   * image should be set by external application
   * @param {FrameReaderConfig} config
   * @param {HTMLImageElement} imageElement
   * @param {HTMLCanvasElement} videoCanvas
   */
  constructor(
    config: FrameReaderConfig,
    imageElement: HTMLImageElement,
    videoCanvas: HTMLCanvasElement
  ) {
    super(config);
    this._imageElement = imageElement;
    this._videoCanvas = videoCanvas;
    this._eventManager = new EventManager();
    this._startedTime = Date.now();
    this._lastSrc = "";
    this._lastFrame = Frame.Zeros();
    this._lastFrameTime = 0.0;
    this._lastSourceResolution = new Resolution(0, 0, 1, 1);
  }

  /**
   * Static factory method to build new WebcamFrameReader
   * In the event that the DOM is not available (such as in a web worker),
   * the regular constructor may be called
   * @param {FrameReaderConfig} config
   * @return {ImageElementFrameReader}
   */
  static build = (config: FrameReaderConfig): ImageElementFrameReader => {
    let imageElement = DomUtils.createImageElement() as HTMLImageElement;
    let videoCanvas = DomUtils.createOffscreenCanvas() as HTMLCanvasElement;
    return new ImageElementFrameReader(config, imageElement, videoCanvas);
  };

  /**
   * This emits events which can be relayed to another event manager
   * @return {EventManager}
   */
  eventManager = (): EventManager => this._eventManager;

  /**
   * Start the webcam and begin emitting events
   * Turns on the webcam. The user must accept
   * @return {Promise<Boolean>} success
   */
  start = async (): Promise<boolean> => {
    if (!this.eventManager().emitting()) {
      try {
        let dt = 1000.0 / this.framerate();
        this._lastFrameTime = Date.now();
        this._startedTime = Date.now();
        this.eventManager().startEmitter(this._emit, dt);
        return true;
      } catch (err) {
        console.warn("WebcamFrameReader: Failed to start webcam!");
        throw err;
      }
    }

    return false;
  };

  /**
   * stop the underlying media source
   * @return {boolean} success
   */
  stop = (): boolean => {
    if (this.eventManager().emitting()) {
      this.eventManager().stopEmitter();
      return true;
    }

    return false;
  };

  /**
   * Is the next frame ready to be read?
   * @return {boolean}
   */
  hasNextFrame = (): boolean => {
    let src = this._imageElement.src;

    if (!src) {
      this._lastFrameTime = Date.now(); // hacked code: we didn't start yet
    }

    return src !== this._lastSrc;
  };

  /**
   * Return the next available frame from the frame reader.
   * @return {Frame}
   */
  nextFrame = (): Frame => {
    // this code is a bit pasted ----> needs refactor!
    let ctx = this._videoCanvas.getContext("2d") as CanvasRenderingContext2D;

    let sourceResolution = this.sourceResolution();
    let frameResolution = this.frameResolution();
    let timestamp = Date.now();
    let duration = timestamp - this._lastFrameTime;
    let maxFrameDuration = this.config.maxFrameDuration;

    if (duration > maxFrameDuration) {
      this.stop();
      console.warn("ImageElementFrameReader: stream has been stopped. ");
      throw Error(
        "ImageElementFrameReader: Maximum delay between frames exceeded: " +
          duration +
          " > " +
          maxFrameDuration
      );
    }

    this._lastFrameTime = timestamp;
    this._lastSrc = this._imageElement.src;

    //Copy webcam video from videoElement to videoCanvas before reading the data
    if (this.config.mirror) {
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.translate(frameResolution.width(), 0);
      ctx.scale(-1, 1);
    }

    ctx.drawImage(
      this._imageElement,
      sourceResolution.x(),
      sourceResolution.y(),
      sourceResolution.width(),
      sourceResolution.height(),
      frameResolution.x(),
      frameResolution.y(),
      frameResolution.width(),
      frameResolution.height()
    );
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    let imageData;

    try {
      imageData = ctx.getImageData(
        frameResolution.x(),
        frameResolution.y(),
        frameResolution.width(),
        frameResolution.height()
      );
    } catch (err) {
      console.warn(
        "ImageElementFrameReader: Interrupted. Use previous frame instead.  "
      );
      return this._lastFrame; // console.warn("ImageElementFrameReader: Interrupted. Stop gracefully. ");
      // this.stop();
      // throw Error("ImageElementFrameReader: Interrupted");
    }

    let orientation = DomUtils.screenOrientation();
    let frame = new Frame(timestamp, duration, imageData, orientation);
    this._lastFrame = frame;
    return frame;
  };

  /**
   * The expected resolution of frames to be provided by the frame reader
   * This may change from time to time
   * @return {Resolution}
   */
  frameResolution = (): Resolution => {
    let videoCanvas = this._videoCanvas;
    return new Resolution(0, 0, videoCanvas.width, videoCanvas.height);
  };

  /**
   * The maximum resolution which the reader supports
   * This can be configured
   * @override
   * @return {Resolution}
   */
  maxFrameResolution = (): Resolution => {
    let maxWidth = this.config.maxWidth;
    let maxHeight = this.config.maxHeight;
    return new Resolution(0, 0, maxWidth, maxHeight);
  };

  /**
   * Update the resolution of the frame reader
   * This is usually only called internally
   * @param {Resolution} resolution
   */
  updateFrameResolution = (resolution: Resolution) => {
    this._videoCanvas.width = resolution.width();
    this._videoCanvas.height = resolution.height();
    this._lastSourceResolution = this.sourceResolution();
  };

  /**
   * Has the media source resolution changed?
   * @return {boolean}
   */
  sourceResolutionChanged = (): boolean =>
    !this.sourceResolution().equals(this._lastSourceResolution);

  /**
   * Render video from offscreenCanvas to external canvas
   * @param {CanvasRenderingContext2D} ctx
   */
  renderVideo = (ctx: CanvasRenderingContext2D) => {
    let frameResolution = this.frameResolution();
    ctx.drawImage(
      this._videoCanvas,
      frameResolution.x(),
      frameResolution.y(),
      frameResolution.width(),
      frameResolution.height()
    );
  };

  /**
   * When mobile browser changes orientation this method is called
   * @return {Resolution}
   */
  sourceResolution = (): Resolution => {
    let width = this._imageElement.naturalHeight;
    let height = this._imageElement.naturalHeight;
    return new Resolution(0, 0, width, height);
  };

  /**
   * The quoted framerate (frames per second) of the webcam device
   * This may not be reliable on some browsers
   * @return {number}
   */
  framerate = (): number => {
    return this.config.framerate.ideal;
  };
}
