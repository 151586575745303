import Gaze from "Models/Gaze";
import StorageHandler from "Utils/StorageHandler";

export class ECPoint {
  _x: number;
  _y: number;
  _xCorrection: number;
  _yCorrection: number;

  /**
   * represent the data needed to store gaze validation
   * @param {number} x
   * @param {number} y
   * @param {number} xCorrection
   * @param {number} yCorrection
   */
  constructor(x: number, y: number, xCorrection: number, yCorrection: number) {
    this._x = x;
    this._y = y;
    this._xCorrection = xCorrection;
    this._yCorrection = yCorrection;
  }

  /**
   * serialize validated params by removing all functions
   * useful for persistence
   * @return {*}
   */
  serialize = (): any => {
    return {
      x: this._x,
      y: this._y,
      xCorrection: this._xCorrection,
      yCorrection: this._yCorrection,
    };
  };

  /**
   * reverse serialization
   * @param {*} point
   * @return {ECPoint}
   */
  static deserialize = (point: any): ECPoint =>
    new ECPoint(point.x, point.y, point.xCorrection, point.yCorrection);

  /**
   * @return {number}
   */
  x = (): number => this._x;

  /**
   * @return {number}
   */
  y = (): number => this._y;

  /**
   * @return {number}
   */
  xCorrection = (): number => this._xCorrection;

  /**
   * @return {number}
   */
  yCorrection = (): number => this._yCorrection;

  /**
   * @param {Gaze} gaze
   * @return {number}
   */
  distance = (gaze: Gaze): number => {
    let dx = this._x - gaze.x();
    let dy = this._y - gaze.y();
    return Math.sqrt(dx * dx + dy * dy);
  };
}
export default class ErrorCorrection {
  _timestamp: number;
  _duration: number;
  _ecPoints: Array<ECPoint>;
  _sampleCount: number;

  /**
   * represent the data needed to store gaze validation
   * @param {number} timestamp
   * @param {number} duration
   * @param {Array<ECPoints>} ecPoints
   * @param {number} sampleCount
   */
  constructor(
    timestamp: number,
    duration: number,
    ecPoints: Array<ECPoint>,
    sampleCount: number
  ) {
    this._timestamp = timestamp;
    this._duration = duration;
    this._ecPoints = ecPoints;
    this._sampleCount = sampleCount;
  }

  /**
   * load saved validation params from local storage
   * @param {*} storageKey
   */
  static fromLocalStorage = (storageKey: string) => {
    let json = StorageHandler.getItem(storageKey);

    if (json) {
      let saveData = JSON.parse(json);
      let model = ErrorCorrection.deserialize(saveData);
      return model;
    }

    return null;
  };

  /**
   * save validation params to local storage
   * @param {string} storageKey
   */
  toLocalStorage = (storageKey: string) => {
    let json = JSON.stringify(this.serialize());
    StorageHandler.setItem(storageKey, json);
  };

  /**
   * serialize validated params by removing all functions
   * useful for persistence
   * @return {*}
   */
  serialize = (): any => {
    let ecPoints: Array<any> = this._ecPoints.map((p) => p.serialize());

    return {
      timestamp: this._timestamp,
      duration: this._duration,
      ecPoints: ecPoints,
      sampleCount: this._sampleCount,
    };
  };

  /**
   * reverse serialization
   * @param {*} errorCorrection
   * @return {ErrorCorrection}
   */
  static deserialize = (errorCorrection: any): ErrorCorrection => {
    let ecPoints = errorCorrection.ecPoints.map((p: any) =>
      ECPoint.deserialize(p)
    );
    return new ErrorCorrection(
      errorCorrection.timestamp,
      errorCorrection.duration,
      ecPoints,
      errorCorrection.sampleCount
    );
  };

  /**
   * @return {number}
   */
  timestamp = (): number => this._timestamp;

  /**
   * @return {number}
   */
  duration = (): number => this._duration;

  /**
   * @return {Array<ECPoint>}
   */
  points = (): Array<ECPoint> => this._ecPoints;

  /**
   * @return {number}
   */
  sampleCount = (): number => this._sampleCount;

  /**
   * @return ErrorCorrection
   */
  static Zero = (): ErrorCorrection => {
    return new ErrorCorrection(0, 0, [], 0);
  };
}
