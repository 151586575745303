import React from "react";
import { setCalibrationTime } from "../../../store/reducers/session";
import { SessionSectionProps, SessionState } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";

interface SessionCalibrationProps extends SessionSectionProps {
  calibrationRef: React.RefObject<HTMLDivElement>;
  trackerRef: React.RefObject<HTMLDivElement>;
}
export const SessionCalibration = ({
  broker,
  dispatch,
  calibrationRef,
  trackerRef,
  setSessionState,
  errorHandle,
}: SessionCalibrationProps) => {
  const calibrate = async () => {
    try {
      let div = calibrationRef.current;
      let trackerDiv = trackerRef.current;
      if (!div || !trackerDiv)
        throw ErrorFormatter.getError(ErrorClass.CALIBRATION_DIV);
      dispatch(setCalibrationTime());
      await broker.calibrate(div, 50000);
      broker.trackersController.setVideoDiv(trackerDiv);
      broker.trackersController._videoView.render();
      cont();
    } catch (e) {
      let div = calibrationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      errorHandle(ErrorFormatter.formatError(e));
    }
  };
  const cont = () => {
    setSessionState(SessionState.VALIDATION);
  };
  return (
    <>
      <p className="font-semibold mb-10">Set Up Eye Tracking - Calibration</p>
      <p className="mb-10">
        To make sure our eyetracking is accurate, we need to calibrate it.
      </p>
      <p className="mb-10">
        On the next page you will be required to look at a blue dot on the
        screen and follow it with your eyes as it moves.
      </p>
      <p className="mb-10">Try to keep your head still.</p>
      <p className="mb-10">Let's try this now!</p>
      <button
        onClick={calibrate}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Continue
      </button>
    </>
  );
};
