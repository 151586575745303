import { FrameReaderConfig } from 'Managers/ConfigManager';
import Resolution from 'Models/Resolution';
import platform from 'platform';

export const Orientation = {
  LANDSCAPE_LEFT: -90,
  PORTRAIT: 0,
  LANDSCAPE_RIGHT: 90,
  UNKNOWN: 0,
};
export default class DomUtils {
  /**
   * Gets the browser name or returns an empty string if unknown.
   * This function also caches the result to provide for any
   * future calls this function has.
   *
   *
   */
  static detectPlatform = (): typeof platform => {
    return platform; // Opera 8.0+
    // let isOpera =
    //   (!!window.opr && !!opr.addons) ||
    //   !!window.opera ||
    //   navigator.userAgent.indexOf(" OPR/") >= 0;
    // // Firefox 1.0+
    // let isFirefox = typeof InstallTrigger !== "undefined";
    // // Safari 3.0+ "[object HTMLElementConstructor]"
    // let isSafari =
    //   /constructor/i.test(window.HTMLElement) ||
    //   (function (p) {
    //     return p.toString() === "[object SafariRemoteNotification]";
    //   })(!window["safari"] || safari.pushNotification);
    // // Internet Explorer 6-11
    // let isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // // Edge 20+
    // let isEdge = !isIE && !!window.StyleMedia;
    // // Chrome 1+
    // let isChrome = !!window.chrome && !!window.chrome.webstore;
    // // Blink engine detection
    // let isBlink = (isChrome || isOpera) && !!window.CSS;
    // return isOpera
    //   ? "Opera"
    //   : isFirefox
    //   ? "Firefox"
    //   : isSafari
    //   ? "Safari"
    //   : isChrome
    //   ? "Chrome"
    //   : isIE
    //   ? "IE"
    //   : isEdge
    //   ? "Edge"
    //   : isBlink
    //   ? "Blink"
    //   : "Unknown";
  };

  /**
   * Create an offscreen canvas
   * Canvas is not attached to the document so this function may be called multiple times
   * @return {HTMLCanvasElement} canvas
   */
  static createOffscreenCanvas = (): HTMLCanvasElement => {
    let canvas = document.createElement("canvas");
    canvas.width = 1;
    canvas.height = 1;
    canvas.hidden = true;
    return canvas;
  };

  /**
   * Create a singleton imageElement
   * imageElement must be is attached to the document so this should only be called once
   * per application
   * @return {HTMLImageElement} videoElement
   */
  static createImageElement = (): HTMLImageElement => {
    let video = document.createElement("IMG");
    video.id = "lr_record_image";
    document.body.appendChild(video);
    let imageElement = document.getElementById(
      "lr_record_image"
    ) as HTMLImageElement;
    // imageElement.style = "display: none;";
    imageElement.style.display = "none";
    imageElement.setAttribute("crossOrigin", "anonymous");
    return imageElement;
  };

  /**
   * Create a singleton videoElement
   * videoElement must be is attached to the document so this should only be called once
   * per application
   * @return {HTMLVideoElement} videoElement
   */
  static createVideoElement = (
    id: string = "lr_record_video"
  ): HTMLVideoElement => {
    let video = document.createElement("video");
    video.id = id;
    document.body.appendChild(video);
    let videoElement = document.getElementById(id) as HTMLVideoElement;
    let platform = DomUtils.detectPlatform();
    let isIOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    let isAndroid =
      !!navigator.platform && /Linux armv8l/.test(navigator.platform);

    if (isIOS || isAndroid || platform.name === "Safari") {
      // @ts-ignore
      videoElement.position = "absolute"; // @ts-ignore
      videoElement.width = "0px"; // @ts-ignore
      videoElement.height = "0px";
    } else {
      // @ts-ignore
      videoElement.style = "display: none;";
    }

    videoElement.setAttribute("autoplay", "");
    videoElement.setAttribute("muted", "");
    videoElement.setAttribute("playsinline", "");
    videoElement.setAttribute("crossOrigin", "anonymous");
    return videoElement;
  };

  /**
   * Ask the navigator for permission to get the users webcam stream
   * @param {} readerConfig
   * @return {Promise<MediaStream>} webcamStream
   */
  static createWebcamStream = async (
    readerConfig: FrameReaderConfig
  ): Promise<MediaStream> => {
    let webcamStream = await navigator.mediaDevices.getUserMedia({
      video: {
        frameRate: readerConfig.framerate,
        facingMode: readerConfig.facingMode,
        width: readerConfig.width,
        height: readerConfig.height,
      },
      audio: false,
    });
    return webcamStream;
  };

  /**
   * @param {string} videoFileURI the location of the video file to be streamed from
   * @param {string} id of element to create
   * @return {HTMLSourceElement} videoSource
   */
  static createVideoSource = (
    videoFileURI: string,
    id: string = "",
    loop: boolean = false
  ): HTMLSourceElement => {
    let videoSource = document.createElement("source") as HTMLSourceElement;
    videoSource.setAttribute("src", videoFileURI);
    videoSource.setAttribute("type", "video/mp4");
    videoSource.setAttribute("crossorigin", "anonymous"); // @ts-ignore
    videoSource.autoplay = true; // @ts-ignore
    videoSource.loop = true;
    if (id != "") {
      videoSource.id = id;
    } // @ts-ignore
    videoSource.loop = loop;
    return videoSource;
  };

  /**
   * @return {number}
   */
  static screenOrientation = (): number => {
    let orientation = 0;

    if (screen.orientation) {
      orientation = screen.orientation.angle;
    } else if (window.orientation !== undefined) {
      orientation = window.orientation;
    }

    switch (orientation) {
      case 0:
        return Orientation.PORTRAIT;
      case 90:
        return Orientation.LANDSCAPE_RIGHT;
      case -90:
      case 270:
        return Orientation.LANDSCAPE_LEFT;
      default:
        return Orientation.UNKNOWN;
    }
  };

  /**
   * Wrapper function for device pixel ratio. Hopefully this won't need any special cases
   * @returns
   */
  static devicePixelRatio = () => {
    let pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1.0;
    return pixelRatio;
  };

  /**
   * Wrapper function for left coordinate of screen content
   * @returns
   */
  static screenContentX = () => {
    let dx = window.screenX ? window.screenX : 0.0;

    if (window.innerWidth && window.outerWidth) {
      // Not sure where the factor of 2 comes from, but it is small so doesn't matter much!
      let deltaX = (window.outerWidth - window.innerWidth) / 2;

      if (deltaX > window.outerWidth * 0.2) {
        // Sometimes window.innerWidth is really small for no sensible reason. Ignore these cases.
        dx += deltaX;
      }
    }

    return dx;
  };

  /**
   * Wrapper function for top coordinate of screen content
   * @returns
   */
  static screenContentY = () => {
    let dy = window.screenY ? window.screenY : 0.0;

    if (window.innerHeight && window.outerHeight) {
      // Not sure where the factor of 2 comes from, but it is small so doesn't matter much!
      let deltaY = (window.outerHeight - window.innerHeight) / 2;

      if (deltaY < window.outerHeight * 0.2) {
        // Sometimes window.innerWidth is really small for no sensible reason. Ignore these cases.
        dy += deltaY;
      }
    }

    return dy;
  };

  /**
   * unlikely to change
   * @returns
   */
  static screenWidth = () => screen.width;

  /**
   * @returns
   */
  static screenHeight = () => screen.height;

  /**
   * @returns
   */
  static screenResolution = () => {
    return new Resolution(
      0,
      0,
      DomUtils.screenWidth(),
      DomUtils.screenHeight()
    );
  };
}
