import { FrameReaderConfig } from "Managers/ConfigManager";
import Base64FrameReader from "Readers/Base64FrameReader";
import FrameReader from "Readers/FrameReader";
import ImageElementFrameReader from "Readers/ImageElementFrameReader";
import VideoFileFrameReader from "Readers/VideoFileFrameReader";
import WebcamFrameReader from "Readers/WebcamFrameReader";

export default class FrameReaderFactory {
  /**
   * @param {FrameReaderConfig} frameReaderConfig
   * @return {FrameReader}
   */
  static build = (frameReaderConfig: FrameReaderConfig): FrameReader => {
    let frameReaderType = frameReaderConfig.reader;

    switch (frameReaderType) {
      case "WEBCAM":
        return WebcamFrameReader.build(frameReaderConfig);
      case "VIDEO_FILE":
        return VideoFileFrameReader.build(frameReaderConfig);
      case "IMAGE_ELEMENT":
        return ImageElementFrameReader.build(frameReaderConfig);
      case "BASE64":
        return Base64FrameReader.build(frameReaderConfig);
      default:
        throw "FrameReaderFactory: Unsupported: " + frameReaderType;
    }
  };
}
