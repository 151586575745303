import LandmarkDetector from "Detection/LandmarkDetector";
import WASMLoader from "Loaders/BRFv4Loader";
import { LandmarkDetectorConfig } from "Managers/ConfigManager";
import Frame from "Models/Frame";
import Landmarks68 from "Models/Landmarks68";
import Resolution from "Models/Resolution";

export default class BRFv4LandmarkDetector extends LandmarkDetector {
  _brf: any;
  _manager: any;
  _lastResolution: Resolution;
  _faces: Array<any>;
  _timestamp = 0;
  _duration = 0;
  config: LandmarkDetectorConfig;

  /**
   * @param {LandmarkDetectorConfig} config;
   */
  constructor(config: LandmarkDetectorConfig) {
    super();
    this.config = config;
    this._faces = [];
    this._lastResolution = Resolution.Zero();
  }

  /**
   * @param {number} width
   * @param {number} height
   */
  init = async (width: number, height: number) => {

    let loader = new WASMLoader();
    this._lastResolution = new Resolution(0, 0, width, height);

    let brfResolution = this._lastResolution.serialize(); // a BRF rectangle

    let brfConfig = this.config.brf;

    if (!this._manager) {
      await loader
        .initialiseBRF4()
        .then(() => {
          this._brf = loader.brf;
          this._manager = new this._brf.BRFManager();
        })
        .catch((err: string) => {
          throw {
            name: "BRFv4InitError",
            message: "BRFv4LandmarkDetector: " + err,
            stack: new Error().stack,
            toString: function () {
              return this.name + ": " + this.message;
            },
          };
        });
    }

    try {
      this._manager.init(brfResolution, brfResolution, brfConfig.license);
    } catch (err) {
      console.log(err);
      throw {
        name: "BRFv4LicenseError",
        message: "BRFv4LandmarkDetector: " + err,
        stack: new Error().stack,
        toString: function () {
          return this.name + ": " + this.message;
        },
      };
    }
    this._manager.setMode(brfConfig.mode);
    this._manager.setNumFacesToTrack(brfConfig.numFaces);
    this._manager.setFaceDetectionParams(
      0.3 * Math.min(width, height),
      Math.min(width, height),
      brfConfig.stepSize,
      brfConfig.minMergeNeighbors
    );
  };

  /**
   * Update the landmark detector.
   * @param {Frame} frame
   */
  update = (frame: Frame) => {
    if (this._manager) {
      if (!frame.resolution().equals(this._lastResolution)) {
        let resolution = frame.resolution();
        this.resize(resolution);
        this._lastResolution = resolution;
      }

      this._manager.update(frame.imageData().data);
      this._faces = this._manager.getFaces();
      this._timestamp = frame.timestamp();
      this._duration = frame.duration();
    }
  };

  /**
   * Might be useful
   */
  reset = () => {
    if (this._manager) {
      this._manager.reset();
    }
  };

  /**
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    let brfConfig = this.config.brf;
    // let brfResolution = this._lastResolution.serialize(); // a BRF rectangle
    let brfResolution = resolution.serialize();
    let width = resolution.width();
    let height = resolution.height();

    try {
      this._manager.init(brfResolution, brfResolution, brfConfig.license);
    } catch (err) {
      console.log(err);
      throw {
        name: "BRFv4LicenseError",
        message: "BRFv4LandmarkDetector: " + err,
        stack: new Error().stack,
        toString: function () {
          return this.name + ": " + this.message;
        },
      };
    }

    this._manager.setMode(brfConfig.mode);
    this._manager.setNumFacesToTrack(brfConfig.numFaces);
    this._manager.setFaceDetectionParams(
      0.3 * Math.min(width, height),
      Math.min(width, height),
      brfConfig.stepSize,
      brfConfig.minMergeNeighbors
    ); // for (let i = 0; i < 3; i++) {
    //   let frame = Frame.BuildNoise(width, height);
    //   await this.update(frame);
    // }
  };

  /**
   * @override
   * @return {Boolean}
   */
  hasNextLandmarks = (): boolean => {
    return (
      this._faces &&
      this._faces.length > 0 &&
      (this._faces[0].state == "state_face_tracking" ||
        this._faces[0].state == "state_face_tracking_start")
    );
  };

  /**
   * @override
   * @return {Landmarks68}
   */
  nextLandmarks = (): Landmarks68 => {
    if (this._manager && this._faces && this._faces.length > 0) {
      let face = this._faces[0];
      return new Landmarks68(this._timestamp, this._duration, face.vertices);
    }
    return Landmarks68.Zeros();
  };
}
