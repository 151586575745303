import { ClicklessDotViewConfig } from "Managers/ConfigManager";
import FramerateManager from "Managers/FramerateManager";

export default class LoadingView {
  config: ClicklessDotViewConfig;
  _div: HTMLDivElement | null | undefined;
  _canvas: HTMLCanvasElement | null | undefined;
  _running = false; // Is the animation loop supposed to be running?

  _animationInterval: any; // Used for looping with interval
  _framerateManager: FramerateManager; // manages the framerate (why does this have to be complicated!)

  _lastRenderTimestamp: number; // When view was last rendered
  _lastPulsation: number; // When the last pulsation cycle ended
  _nextPulsation: number; // When the next pulsation cycle ends

  /**
   * @param {ClicklessDotViewConfig} config
   */
  constructor(config: ClicklessDotViewConfig) {
    this.config = config;
    let timestamp = Date.now();
    this._lastPulsation = timestamp;
    this._lastRenderTimestamp = timestamp;
    this._nextPulsation = timestamp;
    this._framerateManager = new FramerateManager(config.framerate);
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} parent
   */
  setDivElement = (div: HTMLDivElement) => {
    if (this._div) {
      this.releaseDivElement();
    }

    let canvas = document.createElement("canvas");
    div.appendChild(canvas);
    canvas.style.width = "inherit";
    canvas.style.height = "inherit";
    canvas.id = "lr_calibrator";
    this._div = div;
    this._canvas = canvas;
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this.stopAnimation();

    if (this._div && this._canvas) {
      let div = this._div;
      div.removeChild(this._canvas);
    }

    this._div = null;
    this._canvas = null;
  };

  /**
   * Start animation loop by requesting animation frame if available
   */
  startAnimation = () => {
    if (!this._running) {
      this._running = true;
      let timestamp = Date.now();
      this._lastRenderTimestamp = timestamp;
      this._lastPulsation = timestamp;
      this._nextPulsation = timestamp;

      if (this.config.animation === "REQUEST_ANIMATION_FRAME") {
        requestAnimationFrame(this.render);
      } else {
        let interval = 1000.0 / this.config.framerate.targetFps;
        this._animationInterval = setInterval(() => this.render(), interval);
      }
    }
  };

  /**
   * Stop animation loop
   */
  stopAnimation = () => {
    if (this._running) {
      this._running = false;

      if (this.config.animation !== "REQUEST_ANIMATION_FRAME") {
        clearInterval(this._animationInterval);
      }
    }
  };

  /**
   * Update the canvas to current state
   */
  render = () => {
    let timestamp =
      this._lastRenderTimestamp + this._framerateManager.getFrameDuration();

    if (this._canvas) {
      let canvas = this._canvas;
      let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      // for retina devices must consider pixelRatio
      const pixelRatio = window.devicePixelRatio || 1;
      const canvasWidth = canvas.clientWidth * pixelRatio;
      const canvasHeight = canvas.clientHeight * pixelRatio;

      if (canvas.width !== canvasWidth || canvas.height !== canvasHeight) {
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
      } else {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }

      let r1 = Math.min(canvasWidth, canvasHeight) * 0.2;
      let r2 = Math.min(canvasWidth, canvasHeight) * 0.0;
      let r3 = Math.min(canvasWidth, canvasHeight) * 0.03;
      let n = 12;
      let t1 = 5000;
      let t2 = 1000;
      let theta = Math.PI * (timestamp / t1 - Math.floor(timestamp / t1));
      let phi = Math.PI * (timestamp / t2 - Math.floor(timestamp / t2));
      ctx.fillStyle = this.config.colour;
      ctx.strokeStyle = this.config.colour;

      for (let i = 0; i < n; i++) {
        ctx.beginPath();
        let x =
          canvasWidth * 0.5 +
          r1 * Math.sin(theta + (Math.PI * 2 * i) / n) +
          r2 * Math.sin(phi);
        let y =
          canvasHeight * 0.5 +
          r1 * Math.cos(theta + (Math.PI * 2 * i) / n) +
          r2 * Math.cos(phi);
        ctx.arc(x, y, r3, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
      }
    }

    this._lastRenderTimestamp = timestamp;
    let animationType = this.config.animation;

    if (this._running && animationType === "REQUEST_ANIMATION_FRAME") {
      requestAnimationFrame(this.render);
    }
  };
}
