import Rollbar from 'rollbar'

const BrokerRollbar = new Rollbar({
  accessToken: 'ff4bc6505ac44da2973e026aabbfaaf2',
  captureUncaught: false,
  captureUnhandledRejections: false,
  payload: {
    currentState: null,
    environment: process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'dev' ? 'development' : 'production'
  }
})

const PlatformRollbar = new Rollbar({
  accessToken: '445598a05850426583db5e7c20fd7357',
  captureUncaught: false,
  captureUnhandledRejections: false,
  payload: {
    environment: process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'dev' ? 'development' : 'production'
  }
})

BrokerRollbar.configure({
  onSendCallback: function (_isUncaught, _args, payload) {
    let stateDetails = null

    if (window.LSSTORE) {
      stateDetails = window.LSSTORE('ls_store').getState()
    } else if (window.store) {
      stateDetails = window.store.getState()
    }

    payload.currentState = stateDetails
  }
})

export { BrokerRollbar, PlatformRollbar }
