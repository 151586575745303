import Resolution from "Models/Resolution";

export default class Frame {
  _timestamp: number; // milliseconds

  _duration: number; // milliseconds

  _imageData: ImageData;
  _orientation: number;

  /**
   * Encapsulates image data and timestamp of creation
   * @param {number} timestamp milliseconds
   * @param {number} duration milliseconds
   * @param {ImageData} imageData
   * @param {string} orientation
   */
  constructor(
    timestamp: number,
    duration: number,
    imageData: ImageData,
    orientation: number
  ) {
    this._timestamp = timestamp;
    this._duration = duration;
    this._imageData = imageData;
    this._orientation = orientation;
  }

  /**
   * Serialize frame by removing functions
   * This is useful when sending frames to web workers
   * @return {*}
   */
  serialize = (): any => {
    return {
      timestamp: this._timestamp,
      duration: this._duration,
      data: this._imageData.data.buffer,
      width: this._imageData.width,
      height: this._imageData.height,
      orientation: this._orientation,
    };
  };

  /**
   * @param {*} frame
   * @return {Frame}
   */
  static deserialize = (frame: any): Frame =>
    new Frame(
      frame.timestamp,
      frame.duration,
      new ImageData(
        new Uint8ClampedArray(frame.data),
        frame.width,
        frame.height
      ),
      frame.orientation
    );

  /**
   * time of frame capture in milliseconds
   * @return {number}
   */
  timestamp = (): number => this._timestamp;

  /**
   * time since last frame captured in milliseconds
   * @return {number}
   */
  duration = (): number => this._duration;

  /**
   * Resolution of frame
   * @return {Resolution}
   */
  resolution = (): Resolution =>
    new Resolution(0, 0, this._imageData.width, this._imageData.height);

  /**
   * Underlying image data for frame
   * @return {ImageData}
   */
  imageData = (): ImageData => this._imageData;

  /**
   * Orientation: portrait-primary: 0, landscape-primary: 90, landscape-secondary: -90
   */
  orientation = () => this._orientation;

  /**
   * Copies the frame contents to a canvas
   * This does does not support scaling
   * @param {CanvasRenderingContext2D} ctx
   */
  putFrame = (ctx: CanvasRenderingContext2D) => {
    let videoElement = document.getElementById(
      "lr_record_video"
    ) as HTMLVideoElement | null;
    if (videoElement) {
      try {
        ctx.drawImage(
          videoElement,
          0,
          0,
          this.resolution().width(),
          this.resolution().height()
        );
      } catch {}
    } // try {
    //   ctx.putImageData(this._imageData, resolution.x(), resolution.y());
    // } catch {}
  };

  /**
   * Calculate hash from 64 bytes in middle of frame
   */
  calculateReducedHash = () => {
    let width = this._imageData.width;
    let height = this._imageData.height;
    let size = 4 * width * height;
    let offset = 2 * width * height - 32;
    let hashData = new Int8Array(
      this._imageData.data.slice(offset, offset + 64)
    );
    let hash = 0;

    for (let i = 0; i < hashData.length; i++) {
      hash *= 3; // this is not a very secure or robust hash function!

      hash += hashData[i];
    }

    return hash;
  };

  /**
   *
   * @param duration
   * @returns a new frame with the additional duration added to existing duration
   */
  extendDuration = (duration: number, maxDuration: number | undefined) => {
    let extendedDuration = this._duration + duration;
    if (maxDuration) {
      extendedDuration = Math.min(extendedDuration, maxDuration);
    }
    return new Frame(
      this._timestamp,
      extendedDuration,
      this._imageData,
      this._orientation
    );
  };

  /**
   * Create an empty frame
   */
  static Zeros = (width: number = 1, height: number = 1) => {
    if (width < 1) {
      width = 1;
    }
    if (height < 1) {
      height = 1;
    }
    let data = new Uint8ClampedArray(width * height * 4);
    let imageData = new ImageData(data, width, height);
    let timestamp = Date.now();
    return new Frame(timestamp, 10, imageData, 0);
  };

  /**
   * Create a mock frame for use testing/warming up
   */
  static BuildNoise = (width: number = 1, height: number = 1) => {
    if (width < 1) {
      width = 1;
    }
    if (height < 1) {
      height = 1;
    }
    let data = new Uint8ClampedArray(width * height * 4);

    for (let i = 0; i < height; i++) {
      for (let j = 0; j < width; j++) {
        let f = 127.5 + 127.5 * Math.sin(width * 0.1) * Math.cos(height * 0.01);
        f = Math.floor(f);
        f = Math.min(f, 255);
        f = Math.max(f, 0);
        data[(i * width + j) * 4] = f;
        data[(i * width + j) * 4 + 1] = f;
        data[(i * width + j) * 4 + 2] = f;
        data[(i * width + j) * 4 + 3] = 255;
      }
    }

    let imageData = new ImageData(data, width, height);
    let timestamp = Date.now();
    return new Frame(timestamp, 33.3, imageData, 0);
  };
}
