import { OneEuroNoiseCorrectionFilter } from "GazeFilters/OneEuroNoiseCorrectionFilter";
import { VideoViewConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import FramerateManager from "Managers/FramerateManager";
import { sqrt, sum, variance } from "mathjs";
import Frame from "Models/Frame";
import Landmarks from "Models/Landmarks";
import Resolution from "Models/Resolution";
import Draw from "Utils/Draw";

export default class VideoView {
  config: VideoViewConfig;
  _eventManager: EventManager;
  _div: HTMLDivElement | null | undefined;
  _canvas: HTMLCanvasElement | OffscreenCanvas | null | undefined;
  _running = false;
  _animationInterval: any;
  _framerateManager: FramerateManager;
  _lastFrame: Frame;
  _lastLandmarks: Landmarks | undefined;
  _lastVoidTimestamp = 0.0;
  _lastLandmarksTimestamp = 0.0;

  _antiFlickerFilter: OneEuroNoiseCorrectionFilter<Array<number>>;
  _silhouettePositionFilter: OneEuroNoiseCorrectionFilter<Array<number>>;

  /**
   * @param {VideoViewConfig} config
   */
  constructor(config: VideoViewConfig, eventManager: EventManager) {
    this.config = config;
    this._eventManager = eventManager;
    this._framerateManager = new FramerateManager(config.framerate);
    this._lastFrame = Frame.Zeros();
    eventManager.subscribe("onNextFrame", this.onNextFrame);
    eventManager.subscribe("onNextLandmarks", this.onNextLandmarks);
    eventManager.subscribe("onVoidLandmarks", this.onVoidLandmarks);
    this._silhouettePositionFilter = new OneEuroNoiseCorrectionFilter(
      config.silhouetteFilter.fc,
      config.silhouetteFilter.dfc,
      config.silhouetteFilter.beta
    );
    this._antiFlickerFilter = new OneEuroNoiseCorrectionFilter(
      config.antiFlickerFilter.fc,
      config.antiFlickerFilter.dfc,
      config.antiFlickerFilter.beta
    );
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} div
   */
  setDivElement = (div: HTMLDivElement) => {
    if (this._div) {
      this.releaseDivElement();
    }

    let canvas = document.createElement("canvas");
    canvas.id = "lr_show_video";
    // div.id = "lr_show_video_div";
    canvas.width = div.offsetWidth;
    canvas.height = div.offsetHeight;
    div.appendChild(canvas);
    this._div = div;
    this._canvas = canvas;

    if (this.config.mirror) {
      canvas.style["transform"] = "rotateY(180deg)";
      //@ts-ignore
      canvas.style["-webkit-transform"] = "rotateY(180deg)";

      /* Safari and Chrome */
      //@ts-ignore
      canvas.style["-moz-transform"] = "rotateY(180deg)";
      /* Firefox */
    }

    this.startAnimation();
  };

  /**
   *
   * @param {OffscreenCanvas} canvas
   * @memberof ShowVideoView
   */
  // $FlowFixMe
  setOffscreenCanvas = (canvas: OffscreenCanvas) => {
    this._canvas = canvas;
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this.stopAnimation();

    if (this._div && this._canvas) {
      let div = this._div;
      if (this._canvas instanceof HTMLCanvasElement) {
        div.removeChild<HTMLCanvasElement>(this._canvas);
      }
    }

    this._div = null;
    this._canvas = null;
  };

  /**
   * Start animation loop by requesting animation frame if available
   */
  startAnimation = () => {
    if (!this._running) {
      this._running = true;

      this._eventManager.publish("onHeadPositioningVideoStart", {
        timestamp: Date.now(),
      });

      if (this.config.animation === "REQUEST_ANIMATION_FRAME") {
        requestAnimationFrame(() => this.render());
      } else {
        let interval = 1000.0 / this.config.framerate.targetFps;
        this._animationInterval = setInterval(() => this.render(), interval);
      }
    }
  };

  /**
   * Stop animation loop
   */
  stopAnimation = () => {
    if (this._running) {
      this._running = false;

      if (this.config.animation !== "REQUEST_ANIMATION_FRAME") {
        clearInterval(this._animationInterval);
      }
    }
  };

  /**
   * @param {{timestamp: number, frame: Frame}} result
   */
  onNextFrame = (result: { frame: Frame }) => {
    if (
      Date.now() >
      this._lastLandmarksTimestamp + this.config.frameFallbackAfter
    ) {
      this._lastFrame = result.frame;
    }
  };

  /**
   * @param {{timestamp: number, frame: Frame, landmarks: Landmarks}} result
   */
  onNextLandmarks = (result: { frame: Frame; landmarks: Landmarks }) => {
    this._lastLandmarksTimestamp = Date.now();
    this._lastFrame = result.frame;
    this._lastLandmarks = result.landmarks;

    if (this.config.silhouette) {
      let landmarks = this._lastLandmarks as Landmarks;
      let frame = this._lastFrame;
      let xs = landmarks.landmarks().map((o) => o.x) as Array<number>;
      let ys = landmarks.landmarks().map((o) => o.y) as Array<number>;
      let vx = sum(variance(xs));
      let vy = sum(variance(ys));
      let scale = sqrt(vx + vy) as number;

      let left = landmarks.eyes().left();
      let right = landmarks.eyes().right();
      let lx = 0.5 * (left.in.x + left.out.x);
      let ly = 0.5 * (left.in.y + left.out.y);
      let rx = 0.5 * (right.in.x + right.out.x);
      let ry = 0.5 * (right.in.y + right.out.y);

      let x = 0.5 * (lx + rx);
      let y = 0.5 * (ly + ry);
      this._silhouettePositionFilter.apply(
        [
          x - 0.5 * frame.resolution().width(),
          y - 0.5 * frame.resolution().height(),
          scale,
        ],
        landmarks.timestamp()
      );
    }
    this._antiFlickerFilter.apply([1.0], result.frame.timestamp());
  };

  /**
   *
   * @param {{timestamp: number, frame: Frame}} result
   */
  onVoidLandmarks = (result: { frame: Frame }) => {
    this._lastVoidTimestamp = Date.now();
    this._lastFrame = result.frame;
    this._antiFlickerFilter.apply([0.0], result.frame.timestamp());
  };

  /**
   * Update the canvas to current state
   */
  render = () => {
    if (this._div && this._canvas) {
      let div = this._div;
      let canvas = this._canvas;
      let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

      if (this._lastFrame) {
        let frame = this._lastFrame;
        let divResolution = new Resolution(
          0,
          0,
          div.clientWidth,
          div.clientHeight
        );
        let resolution = frame
          .resolution()
          .calculatePutResolution(divResolution);
        let canvasWidth = resolution.width() + 2 * resolution.x();
        let canvasHeight = resolution.height() + 2 * resolution.y();

        // If the canvasHeight and canvasWidth are not NaN
        if (
          canvasHeight &&
          canvasWidth &&
          (canvas.width !== canvasWidth || canvas.height !== canvasHeight)
        ) {
          canvas.width = canvasWidth;
          canvas.height = canvasHeight;
        }
        ctx.clearRect(
          resolution.x(),
          resolution.y(),
          resolution.width(),
          resolution.height()
        );
        if (!this.config.silhouette) {
          frame.putFrame(ctx); // put-frame ignores transform
        }

        let a = 1;
        let b = 0;
        let c = 0;
        let d = 1;
        let e = resolution.x();
        let f = resolution.y();
        let transform = ctx.getTransform();

        if (
          !transform ||
          a !== transform.a ||
          b !== transform.b ||
          c !== transform.c ||
          d !== transform.d ||
          e !== transform.e ||
          f !== transform.f
        ) {
          ctx.setTransform(a, b, c, d, e, f);
        }

        // If the canvas has a style property (OffscreenCanvas doesn't have such property)
        //@ts-ignore
        if (canvas.style) {
          let left = "0px";
          let width = divResolution.width() + "px";
          let top = "0px";
          let height = divResolution.height() + "px";
          let position = "absolute";
          //@ts-ignore

          if (canvas.style.left !== left) canvas.style.left = left; //@ts-ignore
          if (canvas.style.width !== width) canvas.style.width = width; //@ts-ignore
          if (canvas.style.top !== top) canvas.style.top = top; //@ts-ignore
          if (canvas.style.height !== height) canvas.style.height = height; //@ts-ignore
          if (canvas.style.position !== position)
            //@ts-ignore
            canvas.style.position = position;
        }
      }
      if (this._lastLandmarks) {
        if (this.config.silhouette) {
          let dt =
            this._lastLandmarks.timestamp() - this._lastLandmarksTimestamp;
          let [alpha] = this._antiFlickerFilter.guess(Date.now() + dt);
          if (alpha > 0.5) {
            this._drawSilhouette(ctx);
          }
        } else {
          if (this._lastLandmarksTimestamp > this._lastVoidTimestamp) {
            if (ctx.strokeStyle != this.config.colour)
              ctx.strokeStyle = this.config.colour;
            let draw = new Draw(ctx, canvas.width, canvas.height);
            draw.triangles(this._lastLandmarks);
          }
        }
      }

      let animationType = this.config.animation;

      if (this._running && animationType === "REQUEST_ANIMATION_FRAME") {
        requestAnimationFrame(() => this.render());
      }
    }

    this._framerateManager.recordFrame();
  };

  _drawSilhouette = (ctx: CanvasRenderingContext2D) => {
    // calculate scale using face points std
    // calculate center

    let wf = 0.45; // flat top shoulder
    let rh = 2.0; // size of head
    let dhb = 0.28;
    let rb = 4.0; // width of shoulders
    if (!this._lastLandmarks) {
      return;
    }
    let dt = this._lastLandmarks.timestamp() - this._lastLandmarksTimestamp;
    let [x, y, scale] = this._silhouettePositionFilter.guess(Date.now() + dt);
    scale = Math.max(100, scale);
    x += 0.5 * this._lastFrame.resolution().width();
    y += 0.5 * this._lastFrame.resolution().height();
    scale *= this.config.silhouetteScale;

    // should coord at

    // shoulds max out at -2.28]
    // flat bit in middle of shoulders is 0.9 long
    // big circles with origin at -0.45, -2.28 - R

    ctx.fillStyle = "black";
    ctx.strokeStyle = "black";
    ctx.beginPath();
    ctx.arc(
      Math.round(x),
      Math.round(y),
      Math.round(rh * scale),
      0,
      2 * Math.PI
    );
    ctx.fill();

    ctx.beginPath();
    ctx.arc(
      Math.round(x - wf * scale),
      Math.round(y + (rh + dhb + rb) * scale),
      Math.round(rb * scale),
      Math.PI / 2,
      (3 * Math.PI) / 2
    );
    ctx.arc(
      Math.round(x + wf * scale),
      Math.round(y + (rh + dhb + rb) * scale),
      Math.round(rb * scale),
      (3 * Math.PI) / 2,
      Math.PI / 2
    );
    ctx.fill();
  };
}
