import Frame from "Models/Frame";
import Landmarks from "Models/Landmarks";
import Resolution from "Models/Resolution";

export default class LandmarkDetector {
  /**
   * LandmarkDetector should also work with detectors which are not BRF
   *
   * usage:
   * landmarkDetector.update(frame);
   * if (landmarkDetector.hasNextLandmarks) {
   *    landmarks = landmarkDetector.nextLandmarks();
   * }
   */

  /**
   * init the landmark detector
   * can be called repeatedly
   * @param {Number} width expected, can be changed later
   * @param {Number} height expected, can be changed later
   */
  init = async (width: number, height: number): Promise<void> => {
    throw "LandmarkDetector: Not Implemented!";
  };

  /**
   * Update the landmark detector
   * @param {Frame} frame to detect face landmarks from
   */
  update = (frame: Frame): void => {
    throw "LandmarkDetector: Not implemented";
  };

  /**
   * start detecting landmarks
   */
  start = (): void => {
    throw "LandmarkDetector: Not implemented!";
  };

  /**
   * stop detecting landmarks
   */
  stop = (): void => {
    throw "LandmarkDetector: Not implemented!";
  };

  /**
   * reset detection state
   */
  reset = (): void => {
    throw "LandmarkDetector: Not implemented!";
  };

  /**
   * resize the landmark detector
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution): void => {
    throw "LandmarkDetector: Not Implemented!";
  };

  /**
   * @return {Boolean} are facial landmarks available?
   */
  hasNextLandmarks = (): boolean => {
    throw "LandmarkDetector: Not implemented!";
  };

  /**
   * @return {Landmarks} a list of face Landmarks
   */
  nextLandmarks = (): Landmarks => {
    throw "LandmarkDetector: Not implemented!";
  };
}
