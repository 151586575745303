import GazeCalibrator from "Calibration/GazeCalibrator";
import { VideoGazeCalibratorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";
import Landmarks468 from "Models/Landmarks468";
import { Point } from "Views/ClicklessDotView";
import VideoDotView from "Views/VideoDotView";

export default class PoseSegmentedGazeCalibrator extends GazeCalibrator {
  /**
   * Extensible base class for GazeCalibrator
   * Future implementations may include one point calibration etc
   */
  config: VideoGazeCalibratorConfig;
  _view: VideoDotView;
  _gaze: Gaze | null | undefined; // most recent gaze calibration

  _points: Array<Point>; // Points to sample from

  _pointIndex = 0; // current index of calibration

  _pointStarted = 0;
  _samples: Array<number>; // number of samples for each segment of current point

  _lastSampleTime = 0; // timestamp (ms) last pose was processed

  _unusedPoseStreak = 0; // number of sequential unused poses

  _lastMovingTime = 0;
  _lastHintTime = 0; // timestamp (ms) last hint was displayed

  _stageIndex = 0;
  _stageStarted = 0; // when was stage started (ms)

  _stageTicks = 0;
  // maxPoseCount in config
  // find median
  _poseBufferX: Array<number>;
  _poseBufferY: Array<number>;

  /**
   * @param {GazeCalibratorConfig} config
   */
  constructor(config: VideoGazeCalibratorConfig, eventManager: EventManager) {
    super(eventManager);
    this.config = config;
    this._view = new VideoDotView(config.view);
    this._points = [];
    this._samples = [];
    this.setPoints(config.points);
    this.reset();
    this._poseBufferX = new Array(20).fill(0.0);
    this._poseBufferY = new Array(20).fill(0.15);
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} div
   */
  setDivElement = (div: HTMLDivElement) => {
    this._view.setDivElement(div);
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this._view.releaseDivElement();
  };

  /**
   * @param {Array<Point>} points
   */
  setPoints = (points: Array<Point>) => {
    this._points = points.map((p) => new Point(p.x, p.y));
  };

  /**
   * Start calibration
   */
  onStart = () => {
    if (this.finished()) {
      this.reset();
    }

    this._unusedPoseStreak = 0;
    let time = Date.now();
    this._lastSampleTime = time;
    this._lastMovingTime = time;
    let point = this._points[this._pointIndex];

    this._view.moveTo(point);

    this._pointStarted = time;
    this.setStage(0);

    this._view.startAnimation();
  };

  /**
   * Stop calibration
   */
  onStop = () => {
    this._view.stopAnimation();
  };

  /**
   * your finished!
   * @return {Boolean}
   */
  finished = (): boolean => {
    return this._pointIndex === this._points.length;
  };

  /**
   * Reset the calibrator
   */
  reset = () => {
    this._pointIndex = 0;
    this._samples = new Array(this.config.bounds.length).fill(0);

    for (let i = 0; i < this._samples.length; i++) {
      this._view.setSegment(i, 0);
    }

    this._gaze = null;
  };
  setStage = (index: number) => {
    if (index < this.config.schedule.length) {
      this._stageStarted = Date.now();

      if (index != this._stageIndex) {
        this._stageTicks = 0;
      }

      this._stageIndex = index;
    }

    this._view.setStage(this._stageIndex);
  };

  /**
   * Sample the calibrator.
   * This fires an onCalibrationGaze event
   * nextGaze will also now return an updated value
   * @param {*} sample
   */
  calibrate = (sample: { landmarks: Landmarks468 }) => {
    let time = Date.now();

    this._processPose(sample.landmarks);

    if (!this._view.moving()) {
      let gaze = this._view.sample();

      this._gaze = gaze;

      if (this._pointIndex < this._points.length) {
        let totalRequiredSamples = this.config.numberOfSamples;
        let segmentRequiredSamples = Math.floor(
          totalRequiredSamples / (this._samples.length * this._points.length)
        );
        let requiredSamples = segmentRequiredSamples * this._samples.length;

        let segment = this._calculateSegment(sample.landmarks);

        let totalSamples = 0;

        for (let n of this._samples) {
          totalSamples += n;
        }

        if (segment != -1) {
          if (totalSamples >= requiredSamples) {
            this._samples = new Array(this._samples.length).fill(0);
            this._pointIndex += 1;
            this._gaze = null;

            if (this._pointIndex < this._points.length) {
              let point = this._points[this._pointIndex];
              this.setStage(0);

              this._view.moveTo(point);

              this._pointStarted = time;

              for (let i = 0; i < this._samples.length; i++) {
                this._view.setSegment(i, 0);
              }

              console.log("calibrate next point");
            } else {
              console.log("calibrated final point");
            }

            this._lastSampleTime = time;
          } else if (this._samples[segment] < segmentRequiredSamples) {
            if (
              time > this._lastMovingTime + this.config.preSampleDelay &&
              time < this._stageStarted + this.config.preSampleDelay
            ) {
              this._gaze = null;
            } else {
              this._samples[segment] += 1;
              this._unusedPoseStreak = 0;
            }

            this._view.setSegment(
              segment,
              (this._samples[segment] + 0.0) / segmentRequiredSamples
            );

            if (this._samples[segment] == segmentRequiredSamples) {
              this._view.setSegment(segment, 1);
            }

            this._lastSampleTime = Date.now();
          } else {
            this._view.setSegment(segment, 1);

            this._gaze = null;
            this._unusedPoseStreak += 1;
          }
        }

        let timedOut =
          time >
            this._pointStarted + this.config.points[this._pointIndex].timeout ||
          (this._stageIndex == this.config.schedule.length - 1 &&
            time >
              this._stageStarted +
                this.config.schedule[this._stageIndex].timeout);

        if (
          this._pointIndex < this._points.length &&
          timedOut &&
          totalSamples >
            requiredSamples * this.config.points[this._pointIndex].minCompletion
        ) {
          this._samples = new Array(this._samples.length).fill(0);
          this._pointIndex += 1;
          this._gaze = null;

          if (this._pointIndex < this._points.length) {
            let point = this._points[this._pointIndex];
            this.setStage(0);

            this._view.moveTo(point);

            this._pointStarted = time;

            for (let i = 0; i < this._samples.length; i++) {
              this._view.setSegment(i, 0);
            }

            console.log("calibrate next point");
          } else {
            console.log("calibrated final point");
          }

          this._lastSampleTime = time;
        }

        // stage is only cosmetic!
        let stageComplete = true;

        for (let i = 0; i < this._samples.length; i++) {
          let n = this._samples[i];
          totalSamples += n;
          let schedule = this.config.schedule[this._stageIndex];

          if (schedule.targets.includes(i) && n < segmentRequiredSamples) {
            stageComplete = false;
          }
        }

        if (
          time >
          this._stageStarted + this.config.schedule[this._stageIndex].timeout
        ) {
          stageComplete = true;
        }

        if (stageComplete) {
          if (this._stageTicks > 1) {
            this.setStage(this._stageIndex + 1);
          } else {
            this._stageTicks += 1;
          }
        }
      }
    } else {
      this._gaze = null;
      this._lastMovingTime = time;
    }
  };

  /**
   * @return {boolean}
   */
  hasNextGaze = (): boolean => this._gaze != null;

  /**
   * consume and return the next gaze point
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._gaze ? this._gaze : Gaze.Zero();
  };

  /**
   * calculate pose normal from landmarks
   * @param {Landmarks468} landmarks
   * @returns
   */
  _calculatePoseCartesian = (landmarks: Landmarks468) => {
    let vertices = landmarks.vertices();
    let x0 = 0.5 * (vertices[115 * 3 + 0] + vertices[344 * 3 + 0]);
    let y0 = 0.5 * (vertices[115 * 3 + 1] + vertices[344 * 3 + 1]);
    let z0 = 0.5 * (vertices[115 * 3 + 2] + vertices[344 * 3 + 2]);
    let x1 = vertices[4 * 3 + 0];
    let y1 = vertices[4 * 3 + 1];
    let z1 = vertices[4 * 3 + 2];
    let dx = x1 - x0;
    let dy = y1 - y0;
    let dz = z1 - z0;
    let norm = Math.sqrt(dx * dx + dy * dy + dz * dz);
    dx /= norm;
    dy /= norm;
    dz /= norm;
    return [dx, dy, dz];
  };

  /**
   * extract and store pose from landmarks
   * @param {Landmarks468} landmarks
   */
  _processPose(landmarks: Landmarks468) {
    let [dx, dy, dz] = this._calculatePoseCartesian(landmarks);

    this._poseBufferX.push(dx);

    this._poseBufferY.push(dy);

    if (this._poseBufferX.length > this.config.maxPoseBuffer) {
      this._poseBufferX.shift();

      this._poseBufferY.shift();
    }
  }

  /**
   * @returns approximation of the users comfortable 'normal' pose coordinates
   */
  _calculateNaturalPose = () => {
    let n = this._poseBufferX.length;
    let xs = [...this._poseBufferX].sort();
    let ys = [...this._poseBufferY].sort();
    let i0 = Math.floor(0.2 * n);
    let i1 = Math.floor(0.8 * n);
    let dxn = 0.5 * (this._poseBufferX[i0] + this._poseBufferX[i1]);
    let dyn = 0.5 * (this._poseBufferY[i0] + this._poseBufferY[i1]);
    return [dxn, dyn];
  };

  /**
   *
   */
  _calculateSegment = (landmarks: Landmarks468) => {
    // using 3d nose vector, calculate 2 angles
    // 344, 115: either side of nose
    // 4: tip of nose
    let [dx, dy, dz] = this._calculatePoseCartesian(landmarks);

    let [dxn, dyn] = this._calculateNaturalPose();

    if (this.config.poseCorrection) {
      dx -= dxn;
      dy -= dyn;
    }

    let norm = Math.sqrt(dx * dx + dy * dy + dz * dz);
    dx /= norm;
    dy /= norm;
    dz /= norm;
    let theta = Math.atan2(dy, -dx);
    let phi = Math.acos(dz * dz);
    let nearestScore = 100.0;
    let bestBound = -1;
    let totalRequiredSamples = this.config.numberOfSamples;
    let segmentRequiredSamples = Math.floor(
      totalRequiredSamples / (this._samples.length * this._points.length)
    );

    for (let i = 0; i < this.config.bounds.length; i++) {
      let bound = this.config.bounds[i];
      let bTheta = (Math.PI * bound[0]) / 180.0;
      let dBTheta = (Math.PI * bound[1]) / 180.0;

      if (bTheta > Math.PI) {
        bTheta -= 2 * Math.PI;
      }

      let bPhi = (Math.PI * bound[2]) / 180.0;
      let dBPhi = (Math.PI * bound[3]) / 180.0;
      let dTheta = theta - bTheta;
      dTheta = Math.abs(dTheta);

      if (dTheta > 2 * Math.PI) {
        dTheta -= 2 * Math.PI;
      }

      if (dTheta > Math.PI) {
        dTheta = 2 * Math.PI - dTheta;
      }

      let dPhi = Math.abs(phi - bPhi);

      if (dTheta < dBTheta && dPhi < dBPhi) {
        let score = dTheta + 1;

        if (this._samples[i] == segmentRequiredSamples) {
          score += 10;
        }

        if (i == 0) {
          score += 5;
        }

        if (score < nearestScore) {
          nearestScore = score;
          bestBound = i;
        }
      }
    }

    return bestBound;
  };

  /**
   * Calculate hint that should be displayed
   */
  _calculateHintIndex = () => {
    let time = Date.now();

    if (
      this._unusedPoseStreak > 5 &&
      time > this._lastSampleTime + this.config.hintProperties.showAfter
    ) {
      let minSamples = 1e9; // arbitrary large number

      let minIndex = 0;

      for (let i = 0; i < this._samples.length; i++) {
        if (this._samples[i] < minSamples) {
          minSamples = this._samples[i];
          minIndex = i;
        }
      }

      return minIndex;
    } else {
      return -1;
    }
  };
}
