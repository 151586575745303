import React, { useRef, useState } from "react";
import { Brokers } from "@lumen-developer/lumen-common-js/esm/brokers";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { AppDispatch } from "../../store/store";
import { SessionStart } from "./sections/start";
import { SessionPosition } from "./sections/position";
import { SessionCalibration } from "./sections/calibration";
import { SessionValidation } from "./sections/validation";
import { SessionExternal } from "./sections/external";
import { SessionReturn } from "./sections/return";
import { SessionCompletion } from "./sections/complete";
import ErrorFormatter from "../../utils/errorFormat";
import { SessionError } from "./sections/error";

// TODO: ERRORS

export enum SessionState {
  START,
  POSITION,
  CALIBRATION,
  VALIDATION,
  EXTERNAL,
  RETURN,
  COMPLETE,
  ERROR,
}

export interface SessionSectionProps {
  broker: Brokers.LRExtensionBroker;
  setSessionState: React.Dispatch<React.SetStateAction<SessionState>>;
  dispatch: AppDispatch;
  errorHandle: (detail: ErrorDetail | string) => void;
}

export interface SessionValidationProps extends SessionSectionProps {
  validationRef: React.RefObject<HTMLDivElement>;
  trackerRef: React.RefObject<HTMLDivElement>;
  validationSessionDetails: {
    panelist: string;
    panelistId: number;
    tmpSessionId: string;
    calibrationTime: number;
    accessCode: string;
  };
}

export interface ErrorDetail {
  message: string;
  action: () => void;
  state: SessionState;
}

const Session = () => {
  let sessionParamRes = () => {
    const params = new URLSearchParams(window.location.search);
    switch (params.get("section")?.toLowerCase()) {
      case "complete":
        return SessionState.COMPLETE;
      case "return":
        return SessionState.RETURN;
      case "external":
        return SessionState.EXTERNAL;
      default:
        return SessionState.START;
    }
  };

  const panelist = useAppSelector((state) => state.auth.panelist);
  const panelistId = useAppSelector((state) => state.auth.panelistId);
  const accessCode = useAppSelector((state) => state.auth.accessCode);
  const calibrationTime = useAppSelector(
    (state) => state.session.calibrationTime
  );
  const tmpSessionId = useAppSelector((state) => state.session.tmpSessionId);
  const dispatch = useAppDispatch();

  const [sessionState, setSessionState] = useState(sessionParamRes());
  const [status, setStatus] = useState({ position: 0 });
  const [errorDetail, setErrorDetail] = useState<ErrorDetail>({
    message: ErrorFormatter.formatError(),
    action: () => (window.location.href = "/"),
    state: SessionState.ERROR,
  });
  const [broker, _setBroker] = useState<Brokers.LRExtensionBroker>(
    new Brokers.LRExtensionBroker()
  );
  const trackerRef = useRef<HTMLDivElement>(null);
  const calibrationRef = useRef<HTMLDivElement>(null);
  const validationRef = useRef<HTMLDivElement>(null);

  const errorHandle = (detailOrString: ErrorDetail | string) => {
    if (typeof detailOrString === "string") {
      const defaultDetail: ErrorDetail = {
        message: detailOrString,
        action: () => (window.location.reload()),
        state: SessionState.ERROR,
      };
      setErrorDetail(defaultDetail);
      setSessionState(defaultDetail.state);
    } else {
      setErrorDetail(detailOrString);
      setSessionState(detailOrString.state);
    }
  };

  const sessionSwitch = () => {
    switch (sessionState) {
      case SessionState.START:
        return (
          <SessionStart
            broker={broker}
            dispatch={dispatch}
            panelist={panelist}
            trackerRef={trackerRef}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
          />
        );
      case SessionState.POSITION:
        return (
          <SessionPosition
            broker={broker}
            dispatch={dispatch}
            setPositionStatus={(score: number) => {
              setStatus({ position: score });
            }}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
          />
        );
      case SessionState.CALIBRATION:
        return (
          <SessionCalibration
            dispatch={dispatch}
            broker={broker}
            calibrationRef={calibrationRef}
            trackerRef={trackerRef}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
          />
        );
      case SessionState.VALIDATION:
        return (
          <SessionValidation
            broker={broker}
            dispatch={dispatch}
            validationSessionDetails={{
              panelist,
              panelistId,
              tmpSessionId,
              calibrationTime,
              accessCode,
            }}
            validationRef={validationRef}
            trackerRef={trackerRef}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
          />
        );
      case SessionState.EXTERNAL:
        return (
          <SessionExternal
            broker={broker}
            accessCode={accessCode}
            panelist={panelist}
            panelistId={panelistId}
            tmpSessionId={tmpSessionId}
            dispatch={dispatch}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
          />
        );
      case SessionState.RETURN:
        return (
          <SessionReturn
            broker={broker}
            dispatch={dispatch}
            validationSessionDetails={{
              panelist,
              panelistId,
              tmpSessionId,
              calibrationTime,
              accessCode,
            }}
            validationRef={validationRef}
            trackerRef={trackerRef}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
          />
        );
      case SessionState.COMPLETE:
        return (
          <SessionCompletion
            broker={broker}
            dispatch={dispatch}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
          />
        );
      case SessionState.ERROR:
        return (
          <SessionError
            broker={broker}
            dispatch={dispatch}
            setSessionState={setSessionState}
            errorHandle={errorHandle}
            errorDetail={errorDetail}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={`w-full h-full fixed bg-white top-0 left-0; ${
          sessionState === SessionState.ERROR ? "hidden" : "visible"
        }`}
        style={{ display: "none" }}
        ref={calibrationRef}
      ></div>
      <div
        className={`w-full h-full fixed bg-white top-0 left-0; ${
          sessionState === SessionState.ERROR ? "hidden" : "visible"
        }`}
        style={{ display: "none" }}
        ref={validationRef}
      ></div>
      <div className="lg:w-1/2 lg:p-10">{sessionSwitch()}</div>
      <div className="lg:w-1/2 lg:p-10">
        <div
          ref={trackerRef}
          className={`w-full h-2/3 border-2 border-gray-400 rounded ${
            sessionState != SessionState.RETURN &&
            sessionState != SessionState.COMPLETE
              ? "visible"
              : "hidden"
          }`}
        ></div>
        <div
          className={`${
            sessionState != SessionState.START &&
            sessionState != SessionState.RETURN &&
            sessionState != SessionState.EXTERNAL &&
            sessionState != SessionState.COMPLETE
              ? "visible"
              : "hidden"
          }`}
        >
          <p>Your scores:</p>
          <table className="table-auto border-separate border-spacing-2 border border-slate-500">
            <tbody>
              <tr>
                <td>Type</td>
                <td>Target</td>
                <td>Your Score</td>
                <td>Status</td>
              </tr>
              <tr>
                <td>Positioning</td>
                <td>80</td>
                <td>{status.position} / 100</td>
                <td>
                  {status.position >= 80 ? (
                    <CheckCircleIcon className="h-8 p-1 flex text-[#4BB543] focus:outline-none" />
                  ) : (
                    <ExclamationCircleIcon className="h-8 p-1 flex text-[#eed202] focus:outline-none" />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Session;
