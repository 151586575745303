import LandmarkDetector from "Detection/LandmarkDetector";
import LandmarkDetectorFactory from "Detection/LandmarkDetectorFactory";
import { LandmarkDetectorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import { median } from "mathjs";
import Frame from "Models/Frame";
import Landmarks from "Models/Landmarks";
import LandmarksFactory from "Models/LandmarksFactory";
import Resolution from "Models/Resolution";
import LandmarkWorker from "Workers/LandmarkWorker.worker";
import Throttle from "Workers/Throttle";

export default class LandmarkWorkerAdapter extends LandmarkDetector {
  config: LandmarkDetectorConfig;
  _eventManager: EventManager;
  _frameSubscription: { remove: any } | null | undefined;
  _hasWebWorkers: boolean; // does the browser support web workers?

  _landmarkWorker: LandmarkWorker | undefined; // web worker that detects landmarks

  _landmarkDetector: LandmarkDetector | undefined;
  _landmarkDurations: number[] = [];
  _pendingFrameCount = 0;

  /**
   * This class delegates work to either a LandmarkDetector or a LandmarkWorker depending on config
   * @param {{useWebWorkers: Boolean}} workerConfig
   */
  constructor(config: LandmarkDetectorConfig, eventManager: EventManager) {
    super();
    this.config = config;
    this._eventManager = eventManager;
    this._hasWebWorkers = typeof Worker !== "undefined";
  }

  /**
   * @return {Boolean}
   */
  useWebWorkers = (): boolean =>
    this._hasWebWorkers && this.config.worker.useWebWorkers;

  /**
   * @param {LandmarkDetectorConfig} config
   */
  init = async (width: number = 0, height: number = 0): Promise<void> => {
    // Throttle.addEvent("initLandmarkDetector", 3);
    if (this.useWebWorkers()) {
      this._landmarkWorker = new LandmarkWorker(
        this.config,
        this.config.worker.maxQueuedFrames
      );
      this._pendingFrameCount = 0;
      //@ts-ignore
      this._landmarkWorker.addEventListener(
        "message",
        this._handleMessageFromLandmarkWorker
      );
      //@ts-ignore
      this._landmarkWorker.postMessage({
        type: "init",
        args: {
          landmarkDetectorConfig: this.config,
          maxQueuedFrames: this.config.worker.maxQueuedFrames,
        },
      });

      await new Promise<void>((resolve, reject) => {
        let successSub: { remove: any };
        let failSub: { remove: any };
        successSub = this._eventManager.subscribe(
          "onLandmarkDetectorInitialized",
          () => {
            if (successSub) {
              successSub.remove();
            }

            if (failSub) {
              failSub.remove();
            }

            // Throttle.removeEvent("initLandmarkDetector", 3);
            resolve();
          }
        );
        failSub = this._eventManager.subscribe(
          "onLandmarkDetectorFailed",
          () => {
            if (successSub) {
              successSub.remove();
            }

            if (failSub) {
              failSub.remove();
            }

            // Throttle.removeEvent("initLandmarkDetector", 3);
            reject();
          }
        );
      });
    } else {
      let landmarkDetector = LandmarkDetectorFactory.build(this.config);
      let imageConfig = this.config.brf.imageConfig;

      if (landmarkDetector) {
        await landmarkDetector.init(
          imageConfig.inputWidth,
          imageConfig.inputHeight
        );
        this._landmarkDetector = landmarkDetector;

        this._eventManager.publish("onLandmarkDetectorInitialized", {});
      } // Throttle.removeEvent("initLandmarkDetector", 3);
    }
  };

  /**
   * start detecting landmarks
   */
  start = async () => {
    if (!this._frameSubscription) {
      this._frameSubscription = this._eventManager.subscribe(
        "onNextFrame",
        (res) => this.update(res.frame)
      );
    }
  };

  /**
   * stop detecting landmarks
   */
  stop = async () => {
    if (this._frameSubscription) {
      this._frameSubscription.remove();

      this._frameSubscription = null;
    }
  };

  /**
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    if (this.useWebWorkers()) {
      //@ts-ignore
      this._landmarkWorker.postMessage({
        type: "setResolution",
        args: {
          resolution: resolution.serialize(),
        },
      });
    } else {
      if (this._landmarkDetector) {
        this._landmarkDetector.resize(resolution);
      }
    }
  };

  /**
   * broadcast findings to event manager
   * @param {Frame} frame
   * @param {Landmarks} landmarks
   */
  _broadcast = (
    frame: Frame | null | undefined,
    landmarks: Landmarks | null | undefined
  ) => {
    let res: any = {};
    res.timestamp = Date.now();

    if (frame) {
      res.frame = frame;
    }

    if (landmarks) {
      res.landmarks = landmarks;
      let duration = landmarks.duration();
      let maxDuration = this.config.maxLandmarkDuration;
      let medianDurationCount = this.config.medianDurationCount;

      this._landmarkDurations.push(duration);

      if (this._landmarkDurations.length > medianDurationCount) {
        this._landmarkDurations.shift();

        let medianDuration = median(this._landmarkDurations);

        if (medianDuration > maxDuration) {
          console.warn("Halting landmark detector due to performance. ");
          this.stop();
          throw {
            name: "LandmarkThroughputError",
            message:
              "LandmarkWorkerAdapter: Median landmark duration: " +
              medianDuration +
              " > " +
              maxDuration,
            stack: new Error().stack,
            toString: function () {
              return this.name + ": " + this.message;
            },
          };
        }
      }

      this._eventManager.publish("onNextLandmarks", res);
    } else {
      this._eventManager.publish("onVoidLandmarks", res);
    }
  };

  /**
   * Delegate frame to worker or landmark detector
   * @param {Frame} frame
   */
  update = (frame: Frame) => {
    if (this.useWebWorkers()) {
      //we send the frame structure to web worker for processing
      if (this._pendingFrameCount < this.config.worker.maxQueuedFrames) {
        Throttle.addEvent("addLandmarkDetector");
        this._pendingFrameCount += 1;
        let serializedFrame = frame.serialize();
        if (this._landmarkWorker) {
          //@ts-ignore
          this._landmarkWorker.postMessage(
            {
              type: "postFrame",
              args: {
                frame: serializedFrame,
              },
            },
            [serializedFrame.data]
          );
        }
      } //the web worker will send faces data back to the detection queue when it is done
    } else if (this._landmarkDetector) {
      this._landmarkDetector.update(frame);
      let landmarks;

      if (this._landmarkDetector.hasNextLandmarks()) {
        landmarks = this._landmarkDetector.nextLandmarks();
      }

      this._broadcast(frame, landmarks);
    }
  };

  /**
   * @param {MessageEvent} event
   */
  _handleMessageFromLandmarkWorker = (event: MessageEvent) => {
    let data: any = event.data;
    let type = data.type;
    let result = data.result;

    if (!type) {
      console.warn("LandmarkWorkerAdapter: Message has no type: " + data);
    }

    switch (type) {
      case "initComplete":
        this._eventManager.publish("onLandmarkDetectorInitialized", {});

        break;

      case "initFailed":
        this._eventManager.publish("onLandmarkDetectorFailed", {});

      case "init":
      case "setResolution":
        break;

      case "postFrame":
        this._pendingFrameCount -= 1;
        break;

      case "receiveLandmarks":
        let landmarks = result.landmarks
          ? LandmarksFactory.deserialize(result.landmarks)
          : null;
        let frame = result.frame ? Frame.deserialize(result.frame) : null;

        this._broadcast(frame, landmarks);

        Throttle.removeEvent("addLandmarkDetector");
        break;

      default:
        console.warn("LandmarkWorkerAdapter: Unknown message type: " + type);
    }
  };
}
