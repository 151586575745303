import Eyes from "Models/Eyes";
import Landmarks from "Models/Landmarks";

export default class Landmarks68 extends Landmarks {
  /**
   * Landmarks represents 68 point facial landmark detections
   * This class may also be extended to contain information about facial rotation
   * and additional data needed to describe facial landmark detections
   * @param {number} timestamp milliseconds
   * @param {number} timestamp milliseconds
   * @param {Float32Array} vertices
   */
  constructor(timestamp: number, duration: number, vertices: Float32Array) {
    super(timestamp, duration, vertices);
  }

  /**
   * Serialize Landmarks by removing all functions
   * This is useful when communicating with web workers
   * @return {*}
   */
  serialize = (): any => {
    return {
      class: "Landmarks68",
      timestamp: this._timestamp,
      duration: this._duration,
      vertices: this._vertices,
    };
  };

  /**
   * reverse serialization
   * @param {*} landmarks
   * @return {Landmarks68}
   */
  static deserialize = (landmarks: any): Landmarks68 =>
    new Landmarks68(
      landmarks.timestamp,
      landmarks.duration,
      landmarks.vertices
    );

  /**
   * return a copy of face which has been centered
   * @return {Landmarks68}
   */
  center = (): Landmarks68 => {
    let vertices = this.vertices();
    let centeredVertices = new Float32Array(vertices.length);
    let sumX = 0.0;
    let sumY = 0.0;

    for (let i = 0; i < this.vertices.length; i += 2) {
      sumX += vertices[i];
      sumY += vertices[i + 1];
    }

    let meanX = (2 * sumX) / vertices.length;
    let meanY = (2 * sumY) / vertices.length;

    for (let i = 0; i < this.vertices.length; i += 2) {
      centeredVertices[i] = vertices[i] - meanX;
      centeredVertices[i] = vertices[i + 1] - meanY;
    }

    return new Landmarks68(this._timestamp, this._duration, centeredVertices);
  };

  /**
   * Return additional face which has been merged by fraction
   * a = a_1 * (1 - f) + a_2 * f
   * @param {Landmarks68} face additional face to merge
   * @param {number} fraction between 0 and 1
   * @return {Landmarks68}
   */
  merge = (face: Landmarks68, fraction: number): Landmarks68 => {
    let mergedVertices = [];
    let vertices = this.vertices();

    for (let i = 0; i < vertices.length; i += 2) {
      mergedVertices.push(
        vertices[i] * (1 - fraction) + vertices[i] * fraction
      );
      mergedVertices.push(
        vertices[i + 1] * (1 - fraction) + vertices[i + 1] * fraction
      );
    }

    let timestamp = 0.5 * (this._timestamp + face._timestamp);
    let duration = 0.5 * (this._duration + face._duration);
    return new Landmarks68(timestamp, duration, vertices);
  };

  /**
   * @return {{x: number, y: number, z: number}[]}
   */
  landmarks = (): { x: number; y: number; z: number }[] => {
    let landmarks: Array<{
      x: number;
      y: number;
      z: number;
    }> = [];
    let vertices = this.vertices();

    for (let i = 0; i < vertices.length; i += 2) {
      landmarks.push({
        x: vertices[i],
        y: vertices[i + 1],
        z: 0,
      });
    }

    return landmarks;
  };

  /**
   * @return {Eyes}
   */
  eyes = (): Eyes => {
    let vertices = this.vertices();
    let left = {
      in: {
        x: vertices[78],
        y: vertices[79],
      },
      out: {
        x: vertices[72],
        y: vertices[73],
      },
    };
    let right = {
      in: {
        x: vertices[84],
        y: vertices[85],
      },
      out: {
        x: vertices[90],
        y: vertices[91],
      },
    };
    return new Eyes(this._timestamp, this._duration, left, right);
  };

  smileFactor = (): number => {
    //CHANGE: getFaceData --> update
    let smileFactor = 0;
    let vertices = this.vertices();

    if (vertices) {
      if (vertices.length !== 0) {
        let l = vertices.length;
        let k, yLE, yRE;

        // Left eye movement (y)
        for (k = 36, l = 41, yLE = 0; k <= l; k++) {
          yLE = yLE + (vertices[k * 2 + 1] - vertices[k * 2 + 1]);
        }

        yLE = yLE / 6;

        // Right eye movement (y)
        for (k = 42, l = 47, yRE = 0; k <= l; k++) {
          yRE += vertices[k * 2 + 1] - vertices[k * 2 + 1];
        }

        yRE = yRE / 6;
        let yN = 0;
        // Compare to overall movement (nose y)
        yN += vertices[27 * 2 + 1] - vertices[27 * 2 + 1];
        yN += vertices[28 * 2 + 1] - vertices[28 * 2 + 1];
        yN += vertices[29 * 2 + 1] - vertices[29 * 2 + 1];
        yN += vertices[30 * 2 + 1] - vertices[30 * 2 + 1];
        yN /= 4;
        let p0_x = vertices[48 * 2]; // mouth corner left

        let p0_y = vertices[48 * 2 + 1];
        let p1_x = vertices[54 * 2]; // mouth corner left

        let p1_y = vertices[54 * 2 + 1];
        let p2_x = vertices[39 * 2]; // mouth corner left

        let p2_y = vertices[39 * 2 + 1];
        let p3_x = vertices[42 * 2]; // mouth corner left

        let p3_y = vertices[42 * 2 + 1];
        let mouthWidth = Math.sqrt(
          Math.pow(p1_x - p0_x, 2) + Math.pow(p1_y - p0_y, 2)
        );
        let eyeDist = Math.sqrt(
          Math.pow(p3_x - p2_x, 2) + Math.pow(p3_y - p2_y, 2)
        );
        smileFactor = mouthWidth / eyeDist;
        smileFactor -= 1.4; // 1.40 - neutral, 1.70 smiling

        smileFactor *= 4.0;

        if (smileFactor < -1.0) {
          smileFactor = -1.0;
        }

        if (smileFactor > 1.0) {
          smileFactor = 1.0;
        }

        smileFactor *= 100;
        smileFactor = parseInt("" + smileFactor);
      }
    }

    return smileFactor;
  };

  static Zeros = () => {
    return new Landmarks68(0, 0, new Float32Array(68 * 2));
  };
}
