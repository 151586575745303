/**
 * singleton is used to control work deployed to threads
 */
export default class Throttle {
  static _pendingEvents: Record<string, any> = {};
  static addEvent = (type: string, weight: number = 1) => {
    for (let i = 0; i < weight; i++) {
      if (type in this._pendingEvents) {
        this._pendingEvents[type] += 1;
      } else {
        this._pendingEvents[type] = 1;
      }
    }
  };
  static removeEvent = (type: string, weight: number = 1) => {
    for (let i = 0; i < weight; i++) {
      if (type in this._pendingEvents) {
        this._pendingEvents[type] -= 1;
      }
    }
  };
  static countEvent = (type: string) => {
    if (type in this._pendingEvents) {
      return this._pendingEvents[type];
    }

    return 0;
  };
  static countAll = () => {
    let count = 0;

    for (let key in this._pendingEvents) {
      count += this._pendingEvents[key];
    }

    return count;
  };
}
