import { ClicklessDotViewConfig } from "Managers/ConfigManager";
import FramerateManager from "Managers/FramerateManager";
import Gaze from "Models/Gaze";

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}
export default class ClicklessDotView {
  config: ClicklessDotViewConfig;
  _index = 0;
  _div: HTMLDivElement | null | undefined;
  _canvas: HTMLCanvasElement | null | undefined;
  _canvas_x = 0; // x coordinate of canvas relative to parent element
  _canvas_y = 0; // y coordinate of canvas relative to parent element
  _canvas_width = 0; // width of canvas if it were not cropped
  _canvas_height = 0; // height of canvas if it were not cropped
  _start: Point; // movement start
  _dest: Point; //movement destination
  _point: Point; // the displayed point
  _pointRadius = 0; // current outer radius
  _running = false; // Is the animation loop supposed to be running?
  _animationInterval: any; // Used for looping with interval
  _framerateManager: FramerateManager; // manages the framerate (why does this have to be complicated!)
  _lastRenderTimestamp = 0; // When view was last rendered
  _lastPulsation = 0; // When the last pulsation cycle ended
  _nextPulsation = 0; // When the next pulsation cycle ends

  /**
   * @param {ClicklessDotViewConfig} config
   */
  constructor(config: ClicklessDotViewConfig) {
    this.config = config;
    this._point = new Point(0.0, 0.0);
    this._start = new Point(0.0, 0.0);
    this._dest = new Point(0.0, 0.0);
    this._framerateManager = new FramerateManager(config.framerate);
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} parent
   */
  setDivElement = (div: HTMLDivElement) => {
    if (this._div) {
      this.releaseDivElement();
    }

    let canvas = document.createElement("canvas");
    div.appendChild(canvas);
    canvas.style.position = "absolute";
    canvas.id = "lr_calibrator";
    this._div = div;
    this._canvas = canvas;
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this.stopAnimation();

    if (this._div && this._canvas) {
      let div = this._div;
      div.removeChild(this._canvas);
    }

    this._div = null;
    this._canvas = null;
  };

  /**
   * Start animation loop by requesting animation frame if available
   */
  startAnimation = () => {
    if (!this._running) {
      this._running = true;
      let timestamp = 0;
      this._lastRenderTimestamp = timestamp;
      this._lastPulsation = timestamp;
      this._nextPulsation = timestamp;

      this._framerateManager.reset();

      if (this.config.animation === "REQUEST_ANIMATION_FRAME") {
        requestAnimationFrame(this.render);
      } else {
        let interval = 1000.0 / this.config.framerate.targetFps;
        this._animationInterval = setInterval(() => this.render(), interval);
      }
    }
  };

  /**
   * Stop animation loop
   */
  stopAnimation = () => {
    if (this._running) {
      this._running = false;

      if (this.config.animation !== "REQUEST_ANIMATION_FRAME") {
        clearInterval(this._animationInterval);
      }
    }
  };

  /**
   * @return {Boolean} is the displayed point currently moving?
   */
  moving = () =>
    this._point.x !== this._dest.x || this._point.y !== this._dest.y;

  /**
   * Smoothly move displayed point to next location
   * @param {Point} point to move to
   */
  moveTo = (point: Point) => {
    this._start = this._dest;
    this._dest = point;
  };

  /**
   * instantly move displayed point to next location
   * @param {Point} point
   */
  jumpTo = (point: Point) => {
    this._start = point;
    this._dest = point;
  };

  /**
   * @return {Gaze} sample
   */
  sample = () => {
    let x = NaN;
    let y = NaN;

    if (this._div) {
      let rect = this._div.getBoundingClientRect();

      // let scale = DomUtils.devicePixelRatio();
      // let dx = DomUtils.screenContentX();
      // let dy = DomUtils.screenContentY();
      // x = scale * (dx + rect.left + rect.width * this._point.x);
      // y = scale * (dy + rect.top + rect.height * this._point.y);
      x = rect.left + rect.width * this._point.x;
      y = rect.top + rect.height * this._point.y;
    }

    return new Gaze(this._index++, Date.now(), 0, x, y);
  };

  /**
   * Update the canvas to current state
   */
  render = () => {
    let timestamp =
      this._lastRenderTimestamp + this._framerateManager.getFrameDuration();

    if (this._canvas) {
      let canvas = this._canvas;
      let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

      // for retina devices must consider pixelRatio
      if (this._div) {
        let div = this._div;
        this._canvas_x = div.clientLeft;
        this._canvas_y = div.clientTop;
        this._canvas_width = div.clientWidth;
        this._canvas_height = div.clientHeight;

        if (this.moving()) {
          if (this.pulsationCycleComplete(timestamp)) {
            this._movePoint(timestamp);

            this._nextPulsation = timestamp;
          } else {
            this._lastPulsation = timestamp;
          }
        } else {
          this._lastPulsation = timestamp;

          this._updatePulsationCycle(timestamp);
        }

        this._pulsatePoint(timestamp);

        this.drawPoint(ctx, this._point);
      }
    }

    this._lastRenderTimestamp = timestamp;

    this._framerateManager.recordFrame();

    let animationType = this.config.animation;

    if (this._running && animationType === "REQUEST_ANIMATION_FRAME") {
      requestAnimationFrame(this.render);
    }
  };

  /**
   * @param {Number} timestamp milliseconds
   * @return {Boolean} is the current pulsation cycle complete
   */
  pulsationCycleComplete(timestamp: number) {
    return timestamp > this._nextPulsation;
  }

  /**
   * @param {Number} timestamp milliseconds
   */
  _movePoint(timestamp: number) {
    let start = this._start;
    let dest = this._dest;
    let dt = timestamp - this._lastPulsation;
    let duration = this.config.moveProperties.duration;
    dt = Math.min(dt, duration);

    // s ranges between 0 and 1 and is defined by the equation of motion
    // starts moving slowly, speeds up
    if (dt < duration) {
      let s = 1.0 - Math.cos((0.5 * Math.PI * dt) / duration);
      let vector = new Point((dest.x - start.x) * s, (dest.y - start.y) * s);
      this._point.x = start.x + vector.x;
      this._point.y = start.y + vector.y;
    } else {
      this._point.x = this._dest.x;
      this._point.y = this._dest.y;
    }
  }

  /**
   * @param {Number} timestamp milliseconds
   */
  _updatePulsationCycle = (timestamp: number) => {
    let pulsationPeriod = this.config.pulsateProperties.duration;

    while (timestamp >= this._nextPulsation) {
      this._nextPulsation += pulsationPeriod;
    }
  };

  /**
   * @param {Number} timestamp milliseconds
   */
  _pulsatePoint = (timestamp: number) => {
    let dt = Math.max(this._nextPulsation - timestamp, 0.0);
    let frequency = (2 * Math.PI) / this.config.pulsateProperties.duration;
    let radius = this.config.pointProperties.radius;
    let innerRadius = this.config.pointProperties.innerFraction * radius;
    let maxOuterRadius = this.config.pointProperties.maxOuterFraction * radius;
    let minOuterRadius = this.config.pointProperties.minOuterFraction * radius;
    let dr = maxOuterRadius - minOuterRadius;
    let rm = 0.5 * (maxOuterRadius + minOuterRadius);
    let r = innerRadius + rm + 0.5 * dr * Math.cos(dt * frequency);
    this._pointRadius = r;
  };

  /**
   * @param {CanvasRenderingContext2D} ctx
   */
  drawPoint(ctx: CanvasRenderingContext2D, point: Point) {
    const pixelRatio = window.devicePixelRatio || 1;
    let canvas = ctx.canvas;
    let scale = Math.min(this._canvas_width, this._canvas_height) * pixelRatio;
    let radius = this.config.pointProperties.radius;
    let innerRadius = this.config.pointProperties.innerFraction * radius;
    let x = point.x * this._canvas_width; // point.x * ctx.canvas.width;

    let y = point.y * this._canvas_height; // point.y * ctx.canvas.height;

    x = Math.round(x);
    y = Math.round(y);
    let canvasDimension = Math.round(radius * 4 * scale);

    if (
      Math.abs(canvas.width - canvasDimension) > 1 ||
      Math.abs(canvas.height - canvasDimension) > 1
    ) {
      canvas.width = Math.round(canvasDimension * pixelRatio);
      canvas.height = Math.round(canvasDimension * pixelRatio);
      canvas.style.height = Math.round(canvasDimension) + "px";
      canvas.style.width = Math.round(canvasDimension) + "px";
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    /**
     * begin janky transform stuff
     */
    let dx = x;
    let dy = y;
    dx = Math.max(dx, 0.5 * canvasDimension);
    dx = Math.min(dx, this._canvas_width - 0.5 * canvasDimension);
    dy = Math.max(dy, 0.5 * canvasDimension);
    dy = Math.min(dy, this._canvas_height - 0.5 * canvasDimension);
    dx = Math.round(dx);
    dy = Math.round(dy);
    canvas.style.left = dx - Math.round(0.5 * canvasDimension) + "px";
    canvas.style.top = dy - Math.round(0.5 * canvasDimension) + "px";
    x = (x - dx + 0.5 * canvasDimension) * pixelRatio;
    y = (y - dy + 0.5 * canvasDimension) * pixelRatio;

    /**
     * end janky transform stuff.
     */
    // Outer circle
    ctx.beginPath();
    ctx.arc(x, y, this._pointRadius * scale, 0, 2 * Math.PI);
    ctx.fillStyle = this.config.colour;
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = this.config.colour;
    ctx.stroke();
    // inner circle
    ctx.beginPath();
    ctx.arc(x, y, innerRadius * scale, 0, 2 * Math.PI);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.stroke();
  }

  /**
   * Draws a number of points
   * @param {CanvasRenderingContext2D} ctx
   * @param {Array<Point>} points
   */
  drawPoints(ctx: CanvasRenderingContext2D, points: Array<Point>) {
    let i = 0;
    points.forEach((p) => {
      let x = p.x * ctx.canvas.width;
      let y = p.y * ctx.canvas.height;
      ctx.beginPath();
      ctx.arc(x, y, 50, 0, 2 * Math.PI);
      ctx.fillText("" + i, x, y);
      ctx.stroke();
      i++;
    });
  }

  getDefaultPoints(numOfPoints: number = 9) {
    return this._getBoxInBoxPoints(numOfPoints);
  }

  _getBoxInBoxPoints(numOfPoints: number = 13) {
    let numOfRings = Math.floor(numOfPoints / 4);
    // create rings of 4 dots at corners, then add margin based on the ring number from outer ring to inner ring
    let p = new Point(0.0, 0.0);
    let points: Array<Point> = [];

    for (let i = 0; i < numOfRings; i++) {
      let ps = [
        new Point(0, 0),
        new Point(1, 0),
        new Point(1, 1),
        new Point(0, 1),
      ];
      ps.map((x) => {
        return ClicklessDotView._addMarginToPoint(x, {
          x: i / (2 * numOfRings) + this.config.margin.x,
          y: i / (2 * numOfRings) + this.config.margin.y,
        });
      });
      points = points.concat(ps);
    }

    // in the end add the middle point
    points.push(new Point(0.5, 0.5));
    return points;
  }

  static _addMarginToPoint(
    point: Point,
    margin: {
      x: number;
      y: number;
    }
  ) {
    point.x = point.x * (1 - 2 * margin.x) + margin.x;
    point.y = point.y * (1 - 2 * margin.y) + margin.y;
    return point;
  }
}
