import GazeCalibrator from "Calibration/GazeCalibrator";
import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";

export default class CalibratorList extends GazeCalibrator {
  /**
   * Extensible base class for GazeCalibrator
   * Future implementations may include one point calibration etc
   */
  _calibrators: GazeCalibrator[];
  _index: number;
  _div: HTMLDivElement | null | undefined;
  _started: boolean;

  /**
   * @param {GazeCalibratorConfig} config
   */
  constructor(calibrators: GazeCalibrator[], eventManager: EventManager) {
    super(eventManager);
    this._calibrators = calibrators;
    this._index = 0;
    this._started = false;
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} div
   */
  setDivElement = (div: HTMLDivElement) => {
    this._calibrators[this._index].setDivElement(div);

    this._div = div;
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this._calibrators[this._index].releaseDivElement();

    this._div = null;
  };

  /**
   * Start calibration
   */
  onStart = () => {
    if (this._calibrators[this._calibrators.length - 1].finished()) {
      this.reset();
    }

    if (this._calibrators[this._index].finished()) {
      this._transition();
    }

    this._calibrators[this._index].onStart();

    this._started = true;
  };

  /**
   * Stop calibration
   */
  onStop = () => {
    this._calibrators[this._index].onStop();

    this._started = false;
  };

  /**
   * your finished!
   * @return {Boolean}
   */
  finished = (): boolean => {
    return this._calibrators[this._calibrators.length - 1].finished();
  };

  /**
   * Reset the calibrator
   */
  reset = () => {
    this._calibrators[this._index].releaseDivElement();

    for (let calibrator of this._calibrators) {
      calibrator.reset();
    }

    if (this._div) {
      this._calibrators[this._index].setDivElement(this._div);
    }
  };

  /**
   * This fires an onCalibrationGaze event
   * nextGaze will also now return an updated value
   * @param {*} sample
   */
  calibrate = (sample: any) => {
    if (this.finished()) {
      return this.nextGaze();
    }
    if (
      this._calibrators[this._index].finished() &&
      this._index < this._calibrators.length
    ) {
      this._transition();

      this.calibrate(sample);
    }
    this._calibrators[this._index].calibrate(sample);
  };

  /**
   * @return {boolean}
   */
  hasNextGaze = (): boolean => {
    return this._calibrators[this._index].hasNextGaze();
  };

  /**
   * consume and return the next gaze point
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._calibrators[this._index].nextGaze();
  };
  _transition = () => {
    let started = this._started;

    if (started) {
      this._calibrators[this._index].onStop();
    }

    this._calibrators[this._index].releaseDivElement();

    this._index++;
    this._index %= this._calibrators.length;

    if (this._div) {
      this._calibrators[this._index].setDivElement(this._div);
    }

    if (started) {
      this._calibrators[this._index].onStart();
    }
  };
}
