import React, { useRef, useState, useEffect } from "react";
import { submitWildValidationRequest } from "../../../api";
import { ValidationResult } from "../../../types/rni";
import { SessionState, SessionValidationProps } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";

export const SessionValidation = ({
  broker,
  validationSessionDetails,
  validationRef,
  trackerRef,
  setSessionState,
  errorHandle,
}: SessionValidationProps) => {
  const validate = async () => {
    // TODO: SCORE VALIDATION
    try {
      let div = validationRef.current;
      let trackerDiv = trackerRef.current;
      if (!div || !trackerDiv)
        throw ErrorFormatter.getError(ErrorClass.VALIDATION_DIV);
      broker.trackersController._videoView.releaseDivElement();
      let validation: ValidationResult = await broker.validate(div, 50000);
      await submitWildValidationRequest(
        validation,
        validationSessionDetails.panelist,
        validationSessionDetails.panelistId,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.calibrationTime
      );
      broker.trackersController.setVideoDiv(trackerDiv);
      broker.trackersController._videoView.render();
      cont();
    } catch (e) {
      let div = validationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      errorHandle(ErrorFormatter.formatError(e));
    }
  };
  const cont = () => {
    setSessionState(SessionState.EXTERNAL);
  };
  return (
    <>
      <p className="font-semibold mb-10">Set Up Eye Tracking - Validation</p>
      <p className="mb-10">The next step in calibration is validation.</p>
      <p className="mb-10">To validate we ask you to look at another dot.</p>
      <p className="mb-10">
        On the next page you will see a green validation dot. Please follow it
        with your eyes as it moves across the screen.
      </p>
      <p className="mb-10">Try to keep your head still.</p>
      <button
        onClick={validate}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Continue
      </button>
    </>
  );
};
