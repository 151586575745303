import { FramerateConfig } from 'Managers/ConfigManager';

/**
 * Records a time series of frame times and calculates the frame rate.
 */
export default class FramerateManager {
  config: FramerateConfig;
  _timeSeries: Array<number>;

  /**
   * @param {FramerateConfig} config
   */
  constructor(config: FramerateConfig) {
    this.config = config;
    this._timeSeries = [];
    this.reset();
  }

  /**
   * Resets the frame time series to its initial state.
   */
  reset = () => {
    this._timeSeries = [];
    this.recordFrame();
  };

  /**
   * Records a frame at the current time.
   */
  recordFrame = () => {
    this._timeSeries.push(Date.now());

    if (this._timeSeries.length > this.config.maxRecords) {
      this._timeSeries.shift();
    }
  };

  /**
   * @returns {number} frames per second
   */
  getFramerate = (): number => 1000.0 / this.getFrameDuration();

  /**
   * @returns {number} milliseconds
   */
  getFrameDuration = (): number => {
    let n = this._timeSeries.length;

    if (
      n > 1 &&
      n > this.config.maxRecords / 2 &&
      this.config.realtimeAnimation
    ) {
      return (this._timeSeries[n - 1] - this._timeSeries[0]) / (n - 1);
    } else {
      return 1000.0 / this.config.targetFps;
    }
  };

  /**
   * @param {CanvasRenderingContext2D} ctx
   */
  render = (ctx: CanvasRenderingContext2D) => {
    ctx.font = this.config.font; // "italic 20px Georgia";

    ctx.textAlign = this.config.textAlign; // "left";

    ctx.fillText(this.getFramerate().toFixed(1), this.config.x, this.config.y);
  };
}
