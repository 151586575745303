export default class Resolution {
  _x: number;
  _y: number;
  _width: number;
  _height: number;

  /**
   * @param {Number} x
   * @param {Number} y
   * @param {Number} width
   * @param {Number} height
   */
  constructor(x: number, y: number, width: number, height: number) {
    this._x = Math.round(x);
    this._y = Math.round(y);
    this._height = Math.round(height);
    this._width = Math.round(width);
  }

  /**
   * Serialize resolution by removing functions
   * This is useful when sending frames to web workers
   * @return {*}
   */
  serialize = (): any => {
    return {
      x: this._x,
      y: this._y,
      width: this._width,
      height: this._height,
    };
  };

  /**
   * @param {*} resolution
   * @return {Resolution}
   */
  static deserialize = (resolution: any): Resolution =>
    new Resolution(
      resolution.x,
      resolution.y,
      resolution.width,
      resolution.height
    );

  /**
   * Scales resolution by factor of scale
   * @param {Number} scale
   * @return {Resolution}
   */
  scale = (scale: number): Resolution => {
    return new Resolution(
      this._x * scale,
      this._y * scale,
      this._width * scale,
      this._height * scale
    );
  };

  /**
   * calculate dimensions to use when drawing frame to canvas in scaled mode
   * @param {Resolution} container
   * @param {boolean} crop
   * @return {Resolution}
   */
  calculateCanvasResolution = (
    container: Resolution,
    crop: boolean = true
  ): Resolution => {
    const xScale = container.width() / this._width;

    const yScale = container.height() / this._height;

    let dx = 0;
    let dy = 0;
    //Choose the largest scale. This could be smaller too?
    let scale = crop ? Math.max(xScale, yScale) : Math.min(xScale, yScale);

    if (scale > xScale) {
      dx = this._width * 0.5 * (xScale - scale);
    }

    if (scale > yScale) {
      dy = this._height * 0.5 * (yScale - scale);
    }

    return new Resolution(dx, dy, this._width * scale, this._height * scale);
  };

  /**
   * calculate dimensions to use when cropping frame in non-scaled mode
   * @param {Resolution} container
   * @param {boolean} crop
   * @return {Resolution}
   */
  calculatePutResolution = (
    container: Resolution,
    crop: boolean = true
  ): Resolution => {
    let scaledResolution = this.calculateCanvasResolution(container, crop);
    let scale = 1.0;

    if (scaledResolution.x() > -0.1) {
      scale = this.width() / scaledResolution.width();
    } else if (scaledResolution.y() > -0.1) {
      scale = this.height() / scaledResolution.height();
    }

    // un-scale the scaled resolution
    return scaledResolution.scale(scale);
  };

  /**
   * @return {Number}
   */
  x = (): number => this._x;

  /**
   * @return {Number}
   */
  y = (): number => this._y;

  /**
   * @return {Number}
   */
  width = (): number => this._width;

  /**
   * @return {Number}
   */
  height = (): number => this._height;

  /**
   * @param {Resolution} resolution
   * @return {Boolean}
   */
  equals = (resolution: Resolution): boolean => {
    return (
      resolution &&
      this._x === resolution._x &&
      this._y === resolution._y &&
      this._width === resolution._width &&
      this._height === resolution._height
    );
  };

  static Zero = () => new Resolution(0, 0, 0, 0);
}
