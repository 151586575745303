import React, { useRef, useState, useEffect } from "react";
import { SessionSectionProps, SessionState } from "../session";

interface SessionPositionProps extends SessionSectionProps {
  setPositionStatus: (score: number) => void;
}
export const SessionPosition = ({
  broker,
  setPositionStatus,
  setSessionState,
}: SessionPositionProps) => {
  const [score, setScore] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cont = () => {
    broker.trackersController._videoView.config.colour = "rgba(0,0,0,0)";
    broker.stopEyePos();
    setSessionState(SessionState.CALIBRATION);
  };
  let throttle = Date.now();
  const goalCoordinates = {
    w: broker.trackersController.config.frameReader.width,
    h: broker.trackersController.config.frameReader.height,
    goalX: Math.round(broker.trackersController.config.frameReader.width / 2),
    goalY: Math.round(broker.trackersController.config.frameReader.height / 2),
  };
  useEffect(() => {
    broker.getEyePos((res) => {
      if (Date.now() <= throttle + 500) return;
      let xScore: number;
      let yScore: number;
      let xCenter = (res._right.out.x + res._left.out.x) / 2;
      let yCenter = (res._right.out.y + res._left.out.y) / 2;

      if (xCenter > goalCoordinates.goalX) {
        xScore = 100 - (xCenter / goalCoordinates.goalX - 1) * 100;
      } else {
        xScore = (xCenter / goalCoordinates.goalX) * 100;
      }

      if (yCenter > goalCoordinates.goalY) {
        yScore = 100 - (yCenter / goalCoordinates.goalY - 1) * 100;
      } else {
        yScore = (yCenter / goalCoordinates.goalY) * 100;
      }

      const lowestScore = () => {
        if (xScore > yScore) {
          return Math.round(yScore);
        } else {
          return Math.round(xScore);
        }
      };

      const final = lowestScore();

      setPositionStatus(final);
      setScore(final);
      throttle = Date.now();
    });
  }, []);

  return (
    <>
      <p className="font-semibold mb-10">Set Up Eye Tracking - Head Position</p>
      <p className="mb-10">
        In order for the software to predict where you are looking we need to be
        able to see your eyes.
      </p>
      <p className="mb-10">
        This means throughout the study your head is in view of the camera.
      </p>
      <button
        onClick={cont}
        disabled={score >= 80 ? false : true}
        ref={buttonRef}
        className={`${
          score >= 80
            ? "bg-secondary hover:bg-secondary-darken"
            : "bg-slate-500"
        } text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
      >
        Continue
      </button>
    </>
  );
};
