import GazeCalibrator from "Calibration/GazeCalibrator";
import {
  GazeCalibratorConfig,
  PoseSegmentedDotViewConfig,
} from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";
import Landmarks468 from "Models/Landmarks468";
import { Point } from "Views/ClicklessDotView";
import PoseSegmentedDotView from "Views/PoseSegmentedDotView";

export default class PoseSegmentedGazeCalibrator extends GazeCalibrator {
  /**
   * Extensible base class for GazeCalibrator
   * Future implementations may include one point calibration etc
   */
  config: GazeCalibratorConfig;
  _view: PoseSegmentedDotView;
  _gaze: Gaze | null | undefined; // most recent gaze calibration

  _points: Array<Point>; // Points to sample from

  _pointIndex: number; // current index of calibration

  _samples: Array<number>; // number of samples for each segment of current point

  _lastSampleTime: number; // timestamp (ms) last pose was processed

  _unusedPoseStreak: number; // number of sequential unused poses

  _lastMovingTime: number;
  _lastHintTime: number; // timestamp (ms) last hint was displayed

  _lastHintIndex: number;

  /**
   * @param {GazeCalibratorConfig} config
   */
  constructor(config: GazeCalibratorConfig, eventManager: EventManager) {
    super(eventManager);
    this.config = config;
    this._view = new PoseSegmentedDotView(
      config.view as PoseSegmentedDotViewConfig
    );

    let points = this._view.getDefaultPoints(config.numberOfPoints);

    this._unusedPoseStreak = 0;
    this._points = points;
    this._pointIndex = 0;
    let time = Date.now();
    this._lastSampleTime = time;
    this._lastHintTime = time;
    this._lastMovingTime = time;
    this._lastHintIndex = -1;
    this._samples = [];

    this.reset();
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} div
   */
  setDivElement = (div: HTMLDivElement) => {
    this._view.setDivElement(div);
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this._view.releaseDivElement();
  };

  /**
   * @param {Array<Point>} points
   */
  setPoints = (points: Array<Point>) => {
    this._points = points.map((p) => new Point(p.x, p.y));
  };

  /**
   * Start calibration
   */
  onStart = () => {
    if (this.finished()) {
      this.reset();
    }

    this._unusedPoseStreak = 0;
    let time = Date.now();
    this._lastSampleTime = time;
    this._lastHintTime = time;
    this._lastMovingTime = time;
    this._lastHintIndex = -1;
    let point = this._points[this._pointIndex];

    this._view.moveTo(point);

    this._view.startAnimation();
  };

  /**
   * Stop calibration
   */
  onStop = () => {
    this._view.stopAnimation();
  };

  /**
   * your finished!
   * @return {Boolean}
   */
  finished = (): boolean => {
    return this._pointIndex === this._points.length;
  };

  /**
   * Reset the calibrator
   */
  reset = () => {
    this._pointIndex = 0;
    let offset = this.config.centralSegment ? 1 : 0;
    this._samples = new Array(this.config.numberOfSegments + offset).fill(0);
    this._gaze = null;
  };

  /**
   * Sample the calibrator.
   * This fires an onCalibrationGaze event
   * nextGaze will also now return an updated value
   * @param {*} sample
   * @return {Gaze}
   */
  calibrate = (sample: { landmarks: Landmarks468 }): Gaze => {
    let time = Date.now();

    if (!this._view.moving()) {
      let gaze = this._view.sample();

      this._gaze = gaze;

      if (this._pointIndex < this._points.length) {
        let totalRequiredSamples =
          this.config.numberOfSamples * this.config.poseMultiplier;
        let segmentRequiredSamples = Math.floor(
          totalRequiredSamples /
            (this.config.numberOfSegments * this._samples.length)
        );
        let requiredSamples = segmentRequiredSamples * this._samples.length;

        let segment = this._calculateSegment(sample.landmarks);

        if (segment !== -1) {
          let totalSamples = 0;

          for (let n of this._samples) {
            totalSamples += n;
          }

          if (totalSamples === requiredSamples) {
            for (let i = 0; i < this._samples.length; i++) {
              this._view.setSegment(i, 0);
            }

            let offset = this.config.centralSegment ? 1 : 0;
            this._samples = new Array(
              this.config.numberOfSegments + offset
            ).fill(0);
            this._pointIndex += 1;
            this._gaze = null;

            if (this._pointIndex < this._points.length) {
              let point = this._points[this._pointIndex];

              this._view.moveTo(point);

              console.log("calibrate next point");
            } else {
              console.log("calibrated final point");
            }

            this._lastSampleTime = time;
          } else if (this._samples[segment] < segmentRequiredSamples) {
            if (
              time > this._lastMovingTime + this.config.preSampleDelay &&
              time <
                this._lastHintTime +
                  this.config.hintProperties.relaxationDuration
            ) {
              this._gaze = null;
            } else {
              this._samples[segment] += 1;
              this._unusedPoseStreak = 0;
            }

            this._view.setSegment(
              segment,
              (this._samples[segment] + 0.0) / segmentRequiredSamples
            );

            if (this._samples[segment] === segmentRequiredSamples) {
              this._view.setSegment(segment, 1);
            }

            this._lastSampleTime = Date.now();
          } else {
            this._view.setSegment(segment, 1);

            this._gaze = null;
            this._unusedPoseStreak += 1;
          }
        }
      }

      // calculate hint state
      let hintIndex = this._calculateHintIndex();

      let hintMessage = "";

      switch (hintIndex) {
        case 0:
          hintMessage = "DownRight"; // A

          break;

        case 1:
          hintMessage = "Down";
          break;

        case 2:
          hintMessage = "DownLeft";
          break;

        case 3:
          hintMessage = "UpLeft"; // D

          break;

        case 4:
          hintMessage = "Up";
          break;

        case 5:
          hintMessage = "UpRight";
          break;
      }

      if (hintIndex != -1) {
        this._lastHintTime = time;

        if (this._lastHintIndex === -1) {
          let pointIndex = this._pointIndex;
          this.eventManager().publish("onHintSet", {
            hint: {
              x: this._points[pointIndex].x,
              y: this._points[pointIndex].y,
              index: pointIndex,
              segment: hintIndex,
              message: hintMessage,
            },
          });
        }
      } else {
        if (this._lastHintIndex != -1) {
          this.eventManager().publish("onHintReleased", {});
        }
      }

      this._lastHintIndex = hintIndex;
    } else {
      this._gaze = null;
      this._lastMovingTime = time;
    }

    return this.nextGaze();
  };

  /**
   * @return {boolean}
   */
  hasNextGaze = (): boolean => this._gaze != null;

  /**
   * consume and return the next gaze point
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._gaze ? this._gaze : Gaze.Zero();
  };

  /**
   *
   */
  _calculateSegment = (landmarks: Landmarks468) => {
    // using 3d nose vector, calculate 2 angles
    // 344, 115: either side of nose
    // 4: tip of nose
    let vertices = landmarks.vertices();
    let x0 = 0.5 * (vertices[115 * 3 + 0] + vertices[344 * 3 + 0]);
    let y0 = 0.5 * (vertices[115 * 3 + 1] + vertices[344 * 3 + 1]);
    let z0 = 0.5 * (vertices[115 * 3 + 2] + vertices[344 * 3 + 2]);
    let x1 = vertices[4 * 3 + 0];
    let y1 = vertices[4 * 3 + 1];
    let z1 = vertices[4 * 3 + 2];
    let dx = x1 - x0;
    let dy = y1 - y0;
    let dz = z1 - z0;
    let norm = Math.sqrt(dx * dx + dy * dy + dz * dz);
    dx /= norm;
    dy /= norm;
    dz /= norm;
    dy += 0.25;
    norm = Math.sqrt(dx * dx + dy * dy + dz * dz);
    dx /= norm;
    dy /= norm;
    dz /= norm;
    let theta = Math.atan2(dy, -dx);
    let phi = Math.acos(dz * dz);
    let segment = Math.floor(
      this.config.numberOfSegments +
        (this.config.numberOfSegments * theta) / (2 * Math.PI)
    );
    segment %= this.config.numberOfSegments;
    let offset = this.config.centralSegment ? 1 : 0;
    segment += offset;

    if (this.config.centralSegment) {
      if (phi < 0.15) {
        segment = -1 + offset;
      }
    }

    return segment;
  };

  /**
   * Calculate hint that should be displayed
   */
  _calculateHintIndex = () => {
    let time = Date.now();

    if (
      this._unusedPoseStreak > 5 &&
      time > this._lastSampleTime + this.config.hintProperties.showAfter
    ) {
      let minSamples = 1e9; // arbitrary large number

      let minIndex = 0;

      for (let i = 0; i < this._samples.length; i++) {
        if (this._samples[i] < minSamples) {
          minSamples = this._samples[i];
          minIndex = i;
        }
      }

      return minIndex;
    } else {
      return -1;
    }
  };
}
