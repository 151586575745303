import initializeBRF from "Loaders/brf";
import brfv5Module from "Loaders/brfv5Module";

export default class WASMLoader {
  constructor() {
    this.brf = undefined;
  }

  async initialiseBRF4() {
    let trackerObj = this;
    return new Promise(function (resolve, reject) {
      let brfv4 = {
        locateFile: function (fileName) {
          return (
            "https://lr-webcam-tracker.viewpoints.fyi/3rdparty/brf-com/dist/WASM/" +
            "BRFv4_JS_LR310818_v4.1.1_commercial.wasm"
          );
        },
        postRun: function () {
          console.log("Loaded BRF 4 WASM Module OK");
          resolve(true);
        },
        onAbort: function (err) {
          reject(err);
        },
      };
      trackerObj.brf = brfv4;
      initializeBRF(brfv4);
    });
  }
}
