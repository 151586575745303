import GazeFilter from "GazeFilters/GazeFilter";
import ErrorCorrection from "Models/ErrorCorrection";
import Gaze from "Models/Gaze";

export default abstract class ErrorCorrectionFilter implements GazeFilter {
  _predictedGazes: Array<Gaze>;
  _sampledGazes: Array<Gaze>;

  constructor() {
    this._predictedGazes = [];
    this._sampledGazes = [];
  }

  /**
   * @return {Array<Gaze>}
   */
  predictedGazes = (): Array<Gaze> => this._predictedGazes;

  /**
   * @return {Array<Gaze>}
   */
  sampledGazes = (): Array<Gaze> => this._sampledGazes;

  /**
   * @return {ErrorCorrection}
   */
  errorCorrection = (): ErrorCorrection => {
    throw "ErrorCorrectionFilter: Not implemented!";
  };

  /**
   * @param {Gaze} gaze
   * @return {Gaze}
   */
  apply = (gaze: Gaze): Gaze => {
    throw "ErrorCorrectionFilter: Not implemented!";
  };

  /**
   * @param {Gaze} predictedGaze
   * @param {Gaze} sampledGaze
   */
  addSample = (predictedGaze: Gaze, sampledGaze: Gaze) => {
    this._predictedGazes.push(predictedGaze);

    this._sampledGazes.push(sampledGaze);
  };

  /**
   * reset internal lists of samples and predictions
   */
  resetSamples = () => {
    this._predictedGazes = [];
    this._sampledGazes = [];
  };

  /**
   * calculate and update error correction from samples
   * @return {ErrorCorrection}
   */
  calculateErrorCorrection = (): ErrorCorrection => {
    throw "ErrorCorrectionFilter: Not implemented!";
  };
}
