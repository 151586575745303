import Eyes from "Models/Eyes";

export default class Landmarks {
  _timestamp: number;
  _duration: number;
  _vertices: Float32Array;

  /**
   * Landmarks represents 68 point facial landmark detections
   * This class may also be extended to contain information about facial rotation
   * and additional data needed to describe facial landmark detections
   * @param {number} timestamp milliseconds
   * @param {number} timestamp milliseconds
   * @param {Float32Array} vertices
   */
  constructor(timestamp: number, duration: number, vertices: Float32Array) {
    this._timestamp = timestamp;
    this._duration = duration;
    this._vertices = vertices;
  }

  /**
   * Serialize Landmarks by removing all functions
   * This is useful when communicating with web workers
   * @return {*}
   */
  serialize = (): any => {
    throw "Landmarks: Not implemented!";
  };

  /**
   * @return {number} milliseconds
   */
  timestamp = (): number => {
    return this._timestamp;
  };

  /**
   * @return {number} milliseconds
   */
  duration = (): number => {
    return this._duration;
  };

  /**
   * @return {{x: number, y: number, z: number}[]}
   */
  landmarks = (): { x: number; y: number; z: number }[] => {
    throw "Landmarks: Not implemented!";
  };

  /**
   * @return {Float32Array}
   */
  vertices = (): Float32Array => this._vertices;

  /**
   * @return {Eyes}
   */
  eyes = (): Eyes => {
    console.log("fff");
    throw "Landmarks: Not implemented!";
  };

  smileFactor = (): number => {
    throw "Landmarks: Not implemented!";
  };
}
