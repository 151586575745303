import ErrorCorrectionFilter from "GazeFilters/ErrorCorrectionFilter";
import Gaze from "Models/Gaze";
import DomUtils from "Utils/DomUtils";

export default class ClampedErrorCorrectionFilter extends ErrorCorrectionFilter {
  _margin: number;

  /**
   * @param {number} margin clamp margin
   */
  constructor(margin: number = -50) {
    super();
    this._margin = margin;
  }

  /**
   * apply the filter
   * @param {Gaze} gaze
   * @return {Gaze}
   */
  apply = (gaze: Gaze): Gaze => {
    let xClamped = gaze.x();
    let yClamped = gaze.y();
    let resolution = DomUtils.screenResolution();
    xClamped = Math.min(
      xClamped,
      resolution.x() + resolution.width() - this._margin
    );
    xClamped = Math.max(xClamped, resolution.x() + this._margin);
    yClamped = Math.min(
      yClamped,
      resolution.y() + resolution.height() - this._margin
    );
    yClamped = Math.max(yClamped, resolution.y() + this._margin);
    return new Gaze(
      gaze.index(),
      gaze.timestamp(),
      gaze.duration(),
      xClamped,
      yClamped
    );
  };
}
