import LandmarkDetector from "Detection/LandmarkDetector";
import WASMLoader from "Loaders/BRFv5Loader";
import { LandmarkDetectorConfig } from "Managers/ConfigManager";
import Frame from "Models/Frame";
import Landmarks68 from "Models/Landmarks68";
import Resolution from "Models/Resolution";

export default class BRFv5LandmarkDetector extends LandmarkDetector {
  _brf: any;
  _manager: any;
  _faces: Array<any>;
  _timestamp = 0;
  config: LandmarkDetectorConfig;

  /**
   * @param {LandmarkDetectorConfig} config;
   */
  constructor(config: LandmarkDetectorConfig) {
    super();
    this.config = config;
    this._faces = [];
  }

  /**
   * @param {Number} width
   * @param {Number} height
   */
  init = async (width: number, height: number) => {
    let loader = new WASMLoader();
    let brfResolution = {
      x: 0,
      y: 0,
      width: width,
      height: height,
    }; // a BRF rectangle

    let brfConfig = this.config.brf;
    throw "BRF5 has not been finished yet!"; //TODO: actually send BRF config to BRFv5
    //await loader.initialiseBRF5();
    //this._brf = loader.brf;
    //this._manager = this._brf.BRFv5Manager;
  };

  /**
   * Update the landmark detector.
   * @param {Frame} frame
   */
  update = (frame: Frame) => {
    if (this._manager) {
      this._manager.update(frame.imageData());
      this._faces = this._manager.getFaces();
      this._timestamp = frame.timestamp();
    }
  };

  /**
   * Might be useful
   */
  reset = async () => {
    if (this._manager) {
      await this._manager.reset();
    }
  };

  /**
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    // this.init(resolution.width(), resolution.height());
  };

  /**
   * @override
   * @return {Boolean}
   */
  hasNextLandmarks = (): boolean => {
    return this._faces && this._faces.length > 0;
  };

  /**
   * @override
   * @return {Landmarks}
   */
  nextLandmarks = (): Landmarks68 => {
    if (this._manager && this._faces && this._faces.length > 0) {
      let face = this._faces[0];
      this._faces = []; // TODO: is it still face.points for brfv5? ---> TODO: fix brfv5
      //return new Landmarks(this._timestamp, duration, face.vertices);
    }
    return Landmarks68.Zeros();
  };
}
