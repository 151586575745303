import initializeBRF from "Loaders/brf";

export default class WASMLoader {
  constructor() {
    this.brf = null;
  }

  async initialiseBRF5(fullCapacity = true) {
    //TODO: fix if needs to be used
    let trackerObj = this;
    // let module = await import('Loaders/brfv5Module');
    // let brfv5Module = module.default;
    return new Promise((resolve, reject) => {
      try {
        let brfv5 = {
          appId: "lumen-research-xx",
          binaryLocation:
            "https://ctt-media.s3.eu-west-2.amazonaws.com/easylist/v5.1.5/brfv5_js_tk240320_v5.1.5_trial.brfv5",
          modelLocation:
            "https://ctt-media.s3.eu-west-2.amazonaws.com/easylist/v5.1.5/68l_c",
          binaryProgress: null,
          modelChunks: fullCapacity ? 8 : 4,
          binaryError: (e) => {
            reject(e);
          },
          onInit: (brfv5Manager, brfv5Config) => {
            this._brfv5Manager = brfv5Manager;
            this._brfv5Config = brfv5Config;
            resolve({
              brfv5Manager: this._brfv5Manager,
              brfv5Config: this._brfv5Config,
            });
          },
          postRun: function () {
            console.log("Loaded BRF 5 WASM Module OK");
          },
        };

        brfv5Module(brfv5);
      } catch (error) {
        console.log("error", error);
      }
    });
  }
}
