import ErrorCorrection from "Models/ErrorCorrection";
import Frame from "Models/Frame";
import Gaze from "Models/Gaze";
import Landmarks from "Models/Landmarks";
import Resolution from "Models/Resolution";
import Trained from "Models/Trained";
import ValidatedErrorCorrection from "Models/ValidatedErrorCorrection";

type TopicMap = Record<string, any>;
type Topic<T extends TopicMap> = string & keyof T;

export class EventEmitter<T extends TopicMap> {
  _emitting: boolean;
  _started: number;
  _topics: Record<string, Array<Function>>;
  _interval: any;

  constructor() {
    this._emitting = false;
    this._started = 0;
    this._topics = {};
  }

  /**
   * subscribe to an event publisher
   * @param {string} topic of event
   * @param {Function} listener is called when event of topic are published
   * @return {{remove: Function}} the subscription can be removed
   */
  subscribe = <K extends Topic<T>>(
    topic: K,
    listener: (arg: T[K], timestamp: number | undefined) => any
  ): { remove: Function } => {
    if (!this._topics[topic]) {
      this._topics[topic] = [];
    }

    // Add the listener to queue
    const index = this._topics[topic].push(listener) - 1;
    // Provide handle back for removal of topic
    return {
      remove: () => {
        delete this._topics[topic][index];
      },
    };
  };

  /**
   * publish an event to subscribers
   * @param {Topic} topic of event
   * @param {Message} info that is published
   */
  publish = <K extends Topic<T>>(topic: K, info: T[K]) => {
    // If the topic doesn't exist, or there's no listeners in queue, just leave
    if (!this._topics[topic]) return;

    let timestamp = Date.now();

    // Cycle through topics queue, fire!
    this._topics[topic].forEach((item) => {
      try {
        item(info != undefined ? info : {}, timestamp);
      } catch (err: any) {
        // Don't get stuck in an infinite error loop
        if (topic != "onError") this.publish("onError", err);
      }
    });
  };

  /**
   * Forward event from this event manager to another event manager.
   * @param {Topic} topic of event to forward
   * @param {EventManager} eventManager to forward event to
   * @param {string} alias optional topic to publish relayed event under
   */
  relay = (
    topic: Topic<T>,
    eventManager: EventEmitter<T>,
    alias: Topic<T> | undefined | null = null
  ) => {
    if (alias) {
      return this.subscribe(topic, (info) => eventManager.publish(alias, info));
    } else {
      return this.subscribe(topic, (info) => eventManager.publish(topic, info));
    }
  };

  /**
   * is event emitter currently running?
   * @return {boolean}
   */
  emitting = (): boolean => this._emitting;

  /**
   * how long has event emitter been running?
   * @returns ms
   */
  duration = () => (this.emitting() ? Date.now() - this._started : 0);

  /**
   * start emitting events
   * @param {Function} emitter callback
   * @param {number} dt milliseconds
   */
  startEmitter = (emitter: Function, dt: number) => {
    if (!this.emitting()) {
      this._interval = setInterval((): void => {
        try {
          emitter();
        } catch (err: any) {
          this.publish("onError", err);
        }
      }, dt);
      this._emitting = true;
      this._started = Date.now();
    }
  };

  /**
   * stop emitting events
   */
  stopEmitter = () => {
    if (this.emitting()) {
      clearInterval(this._interval);
      this._emitting = false;
    }
  };
}

type FullTopicMap = {
  onError: {
    timestamp: string;
    message: string;
    name: string;
    cause: string;
    stack: string;
  };

  onSourceResolutionChanged: { resolution: Resolution };
  onNextFrame: { frame: Frame };
  onVideoStart: any;
  onEmitterStart: any;
  onFrameReaderStart: any;
  onWebcamStreamCreate: any;

  onWasmLoaded: any;
  onLandmarkDetectorInitialized: any;
  onLandmarkDetectorFailed: any;
  onNextLandmarks: { frame: Frame; landmarks: Landmarks };
  onVoidLandmarks: { frame: Frame };

  onGazeDetectorInitialized: any;
  onNextRawGaze: { rawGaze: Gaze; averagedGaze: Gaze };
  onNextModifiedGaze: { rawGaze: Gaze; averagedGaze: Gaze; modifiedGaze: Gaze };
  onModelTrained: { model: Trained };

  onEyesOnScreen: any;
  onEyesOffScreen: any;

  onPresenceAcquired: { duration: number };
  onPresenceReleased: { duration: number };

  onNextSample:
    | { frame: Frame; landmarks: Landmarks }
    | { rawGaze: Gaze; averagedGaze: Gaze };
  onNextCalibratedSample: {
    sample:
      | { frame: Frame; landmarks: Landmarks }
      | { rawGaze: Gaze; averagedGaze: Gaze };
    measuredGaze: Gaze;
  };

  onNextCalibrationPoint: {
    point: { x: number; y: number };
  };
  onCalibrationStarted: any;
  onCalibrationComplete: any;
  onCalibrationFailed: any;
  onHintSet: {
    hint: {
      x: number;
      y: number;
      index: number;
      segment: number;
      message: string;
    };
  };
  onHintReleased: any;

  onValidationStarted: any;
  onValidationComplete: {
    validatedErrorCorrection: ValidatedErrorCorrection;
    errorCorrection: ErrorCorrection;
  };
  onValidationFailed: any;

  onHeadPositioningVideoStart: any;
};

export default class EventManager extends EventEmitter<FullTopicMap> {}
