import { FrameReaderConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Resolution from "Models/Resolution";

/**
 * Abstract base class
 */
export default class FrameReader {
  config: FrameReaderConfig;
  _lastFrameHash: number;
  _lastUniqueFrame: number;
  _duplicateFrameCount: number;
  _duplicateFrameDuration: number;

  /**
   * Abstract base class for frame reader
   * @param {FrameReaderConfig} config
   */
  constructor(config: FrameReaderConfig) {
    this.config = config;
    this._lastFrameHash = -1;
    this._lastUniqueFrame = 0;
    this._duplicateFrameCount = 0;
    this._duplicateFrameDuration = 0;
  }

  /**
   * start the underlying media source
   * @return {Promise<boolean>} success
   */
  start = (): Promise<boolean> => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * stop the underlying media source
   * @return {boolean} success
   */
  stop = (): boolean => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * is the FrameReader currently running?
   * @return {boolean}
   */
  running = (): boolean => {
    return this.eventManager().emitting();
  };

  /**
   * returns whether or not a new frame is available from the underlying media source
   * @return {boolean}
   */
  hasNextFrame = (): boolean => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * get the next available frame from media source
   * rawImageData or jpgImageData may be disabled by setting properties values to false
   * removing computations may improve performance
   * @return {Frame}
   */
  nextFrame = (): Frame => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * return the expected frame resolution
   * @return {Resolution}
   */
  frameResolution = (): Resolution => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * returns the maximum allowed frame resolution
   * @return {Resolution}
   */
  maxFrameResolution = (): Resolution => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * calculate the maximum possible frame resolution without altering aspect ratio
   * this should be used to update frame resolution if the source resolution changes
   * @return {Resolution}
   */
  calculateFrameResolution = (): Resolution => {
    let sourceResolution = this.sourceResolution();
    let maxResolution = this.maxFrameResolution();
    let xScale = maxResolution.width() / sourceResolution.width();
    let yScale = maxResolution.height() / sourceResolution.height();
    let scale = Math.min(xScale, yScale);
    return sourceResolution.scale(scale);
  };

  /**
   * @param {Resolution} resolution
   */
  updateFrameResolution = (resolution: Resolution): void => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * @return {Boolean}
   */
  sourceResolutionChanged = (): boolean => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * @return {Resolution}
   */
  sourceResolution = (): Resolution => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * @return {Number}
   */
  framerate = (): number => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * @return {EventManager}
   */
  eventManager = (): EventManager => {
    throw "FrameReader: Not implemented!";
  };

  /**
   * internal
   */
  _emit = () => {
    if (this.sourceResolutionChanged()) {
      let resolution = this.calculateFrameResolution();
      this.updateFrameResolution(resolution);
      this.eventManager().publish("onSourceResolutionChanged", {
        resolution: resolution,
      });
    }

    if (this.hasNextFrame()) {
      let frame = this.nextFrame();
      let hash = frame.calculateReducedHash();
      let time = Date.now();

      if (this._lastFrameHash !== hash) {
        this._lastFrameHash = hash;
        this._lastUniqueFrame = time;
        this._duplicateFrameCount = 0;
        if (this._duplicateFrameDuration > 0) {
          frame = frame.extendDuration(
            this._duplicateFrameDuration,
            this.config.maxFrameDuration
          );
        }
        this._duplicateFrameDuration = 0;
        let res = {
          timestamp: Date.now(),
          frame: frame,
        };
        this.eventManager().publish("onNextFrame", res);
      } else {
        this._duplicateFrameDuration += frame._duration;
        if (time - this._lastUniqueFrame > this.config.frameTimeoutDuration) {
          if (this._duplicateFrameCount > this.config.frameTimeoutCount) {
            console.warn("FrameReader: No new frames. FrameReader stopped. ");
            this.stop();
            throw {
              name: "FrameTimeoutError",
              message:
                "FrameReader: No Frame detected for: " +
                (time - this._lastUniqueFrame) +
                "ms. ",
              duration: this.eventManager().duration(),
              stack: new Error().stack,
              toString: function () {
                return this.name + ": " + this.message;
              },
            };
          } else {
            this._duplicateFrameCount += 1;
          }
        }
      }
    }
  };
}
