import { VideoDotViewConfig } from 'Managers/ConfigManager';
import DomUtils from 'Utils/DomUtils';
import ClicklessDotView, { Point } from 'Views/ClicklessDotView';

export default class VideoDotView extends ClicklessDotView {
  config: VideoDotViewConfig;
  _segments: Array<number>; // 0: started, 1: mid, 2: finished, -1: ignore
  _lastSegment: number; // most recently modified segment
  _stage: number;
  _calibrationVideos: Array<HTMLVideoElement>;

  /**
   * @param {ClicklessDotViewConfig} config
   */
  constructor(config: VideoDotViewConfig) {
    super(config);
    this.config = config;
    this._segments = new Array(config.progressAngles.length).fill(0);
    this._lastSegment = -1;
    this._stage = -1;
    this._calibrationVideos = [];
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} parent
   */
  setDivElement = (div: HTMLDivElement) => {
    if (this._div) {
      this.releaseDivElement();
    }

    let canvas = document.createElement("canvas");
    div.appendChild(canvas);
    canvas.style.width = "inherit";
    canvas.style.height = "inherit";
    canvas.id = "lr_calibrator";
    this._div = div;
    this._canvas = canvas;
    this._calibrationVideos = [];

    for (let i = 0; i < this.config.videos.length; i++) {
      let videoConfig = this.config.videos[i];
      let videoElement = DomUtils.createVideoElement(videoConfig.id);
      videoElement.loop = videoConfig.loop;
      videoElement.src = videoConfig.uri;
      videoElement.pause();
      div.appendChild(videoElement);

      this._calibrationVideos.push(videoElement);
    }
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    if (this._calibrationVideos && this._div) {
      let div = this._div;

      for (let i = 0; i < this.config.videos.length; i++) {
        let videoElement = this._calibrationVideos[i];
        videoElement.pause();
        div.removeChild(videoElement);
      }

      this._calibrationVideos = [];
    }

    this.stopAnimation();

    if (this._div && this._canvas) {
      let div = this._div;
      div.removeChild(this._canvas);
    }

    this._div = null;
    this._canvas = null;
  };

  /**
   * Update the canvas to current state
   */
  render = () => {
    let timestamp =
      this._lastRenderTimestamp + this._framerateManager.getFrameDuration();

    let config = this.config;

    if (this._canvas) {
      let canvas = this._canvas;
      let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      // for retina devices must consider pixelRatio
      const pixelRatio = window.devicePixelRatio || 1;
      const canvasWidth = canvas.clientWidth * pixelRatio;
      const canvasHeight = canvas.clientHeight * pixelRatio;

      if (canvas.width !== canvasWidth || canvas.height !== canvasHeight) {
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
      } else {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }

      if (this.moving()) {
        if (this.pulsationCycleComplete(timestamp)) {
          this._movePoint(timestamp);

          this._nextPulsation = timestamp;
        } else {
          this._lastPulsation = timestamp;
        }

        this._pulsatePoint(timestamp);

        if (config.transition.showVideo) {
          this.drawVideo(ctx, this._point, config.transition.videoIndex);
        }

        if (config.transition.showDot) {
          this.drawPoint(ctx, this._point);
        }

        this.resetVideoTime(this._stage);
      } else {
        this._lastPulsation = timestamp;

        this._updatePulsationCycle(timestamp);

        this._pulsatePoint(timestamp);

        if (config.schedule[this._stage].showDot) {
          this.drawPoint(ctx, this._point);
        }

        if (config.schedule[this._stage].showVideo) {
          let videoIndex = config.schedule[this._stage].videoIndex;
          this.drawVideo(ctx, this._point, videoIndex);
        }

        if (config.schedule[this._stage].showProgress) {
          let progressIds = config.schedule[this._stage].progressIds;

          for (let i of progressIds) {
            let startColour = this.config.segmentProperties.startColour;
            let midColour = this.config.segmentProperties.midColour;
            let finishColour = this.config.segmentProperties.finishColour;

            if (this._segments[i] < 0.0001) {
              this.drawSegment(ctx, this._point, i, 0.0, startColour);
            } else if (this._segments[i] < 1.0) {
              this.drawSegment(
                ctx,
                this._point,
                i,
                this._segments[i],
                midColour
              );
            } else {
              this.drawSegment(ctx, this._point, i, 1.0, finishColour);
            }
          }
        } // draw based on schedule
      }
    }

    this._lastRenderTimestamp = timestamp;

    this._framerateManager.recordFrame();

    let animationType = this.config.animation;

    if (this._running && animationType === "REQUEST_ANIMATION_FRAME") {
      requestAnimationFrame(this.render);
    }
  };

  /**
   * @param {CanvasRenderingContext2D} ctx
   */
  drawVideo(ctx: CanvasRenderingContext2D, point: Point, videoIndex: number) {
    let x = point.x * ctx.canvas.width;
    let y = point.y * ctx.canvas.height;
    let videoConfig = this.config.videos[videoIndex];
    let scale = Math.min(ctx.canvas.width, ctx.canvas.height);
    let scaledSize = videoConfig.size * scale;

    if (videoIndex in this._calibrationVideos) {
      let videoElement = this._calibrationVideos[videoIndex];

      if (videoElement.currentTime * 1000.0 > videoConfig.end) {
        if (videoConfig.loop) {
          videoElement.currentTime = videoConfig.start / 1000.0;
        } else {
          videoElement.pause();
        }
      } else if (videoElement.currentTime * 1000.0 < videoConfig.start) {
        videoElement.currentTime = videoConfig.start / 1000.0;
      }

      ctx.drawImage(
        videoElement,
        x - 0.5 * scaledSize,
        y - 0.5 * scaledSize,
        scaledSize,
        scaledSize
      );
    }
  }

  setSegment = (index: number, value: number) => {
    this._segments[index] = value;
    this._lastSegment = index;
  };
  resetVideoTime = (index: number) => {
    if (index in this._calibrationVideos) {
      let videoConfig = this.config.videos[index];
      this._calibrationVideos[index].currentTime = videoConfig.start / 1000.0;
    }
  };
  setStage = (index: number) => {
    let stage = this._stage;

    if (stage != index) {
      if (stage in this._calibrationVideos) {
        this._calibrationVideos[stage].pause();
      }

      if (index in this._calibrationVideos) {
        let videoElement = this._calibrationVideos[index];
        videoElement.play();
        this.resetVideoTime(index);
      }

      this._stage = index;
    }
  };

  /**
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {Point2D} point
   * @param {number} index
   * @param {number} fraction of completion
   * @param {string} colour
   * @returns
   */
  drawSegment = (
    ctx: CanvasRenderingContext2D,
    point: Point,
    index: number,
    fraction: number,
    colour: string
  ) => {
    let x = point.x * ctx.canvas.width;
    let y = point.y * ctx.canvas.height;
    let scale = Math.min(ctx.canvas.width, ctx.canvas.height);
    let radius = this.config.pointProperties.radius;
    let innerRimRadius =
      this.config.segmentProperties.innerRimFraction * radius;
    let innerRadius = this.config.segmentProperties.innerFraction * radius;
    let outerRadius = this.config.segmentProperties.outerFraction * radius;
    let n = this.config.segmentProperties.numberOfSegments;

    if (index < 0 || index > n) {
      return;
    }

    ctx.beginPath();
    ctx.fillStyle = colour;

    if (this._lastSegment === index) {
      ctx.lineWidth = 8;
    } else {
      ctx.lineWidth = 1;
    }

    ctx.strokeStyle = colour;
    let offset = this.config.segmentProperties.centralSegment ? 1 : 0;

    if (index === 0 && offset === 1) {
      ctx.arc(x, y, innerRadius * scale, 0, 2 * Math.PI);
      ctx.arc(x, y, innerRimRadius * scale, 2 * Math.PI, 0, true);
    } else {
      let h = 0.5 * (outerRadius + innerRadius);
      let dh = 0.5 * (outerRadius - innerRadius);
      let theta = (Math.PI * this.config.progressAngles[index][0]) / 180;
      let dTheta = (Math.PI * this.config.progressAngles[index][1]) / 180;
      let minRadius = this.config.segmentProperties.minCircleFraction;
      let maxRadius = h * Math.sin(dTheta);
      let radius = minRadius + fraction * (maxRadius - minRadius);
      let pTheta = (0.8 * dTheta * (radius - dh)) / (maxRadius - dh); // 0.8 so it jumps last 10% at completion

      radius = Math.min(radius, dh);
      let outRS = outerRadius * scale;
      let inRS = innerRadius * scale;
      let hS = h * scale;
      let rS = radius * scale; // scale and clamp

      if (fraction >= 1.0) {
        // full arc
        ctx.arc(x, y, outRS, theta - dTheta, theta + dTheta);
        ctx.arc(x, y, inRS, theta + dTheta, theta - dTheta, true);
      } else if (pTheta > 0.0) {
        let lTheta = theta - pTheta;
        let rTheta = theta + pTheta;
        ctx.arc(x, y, outRS, lTheta, rTheta);
        ctx.arc(
          x + hS * Math.cos(rTheta),
          y + hS * Math.sin(rTheta),
          rS,
          rTheta,
          rTheta + Math.PI
        );
        ctx.arc(x, y, inRS, rTheta, lTheta, true);
        ctx.arc(
          x + hS * Math.cos(lTheta),
          y + hS * Math.sin(lTheta),
          rS,
          lTheta - Math.PI,
          lTheta
        );
      } else {
        // circle
        ctx.arc(
          x + hS * Math.cos(theta),
          y + hS * Math.sin(theta),
          rS,
          0.0,
          Math.PI * 2
        );
      }
    }

    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  };
}
