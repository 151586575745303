import GazeDetector from "Detection/GazeDetector";
import EventManager from "Managers/EventManager";
import SimonGazeDetector from "Detection/SimonGazeDetector";
import GeorgeGazeDetector from "Detection/GeorgeGazeDetector";
import RemoteFeatureGazeDetector from "Detection/RemoteFeatureGazeDetector";
import JsonRpcGazeDetector from "Detection/JsonRpcGazeDetector";
import { GazeDetectorConfig } from "Managers/ConfigManager";
export default class GazeDetectorFactory {
  static build = async (config: GazeDetectorConfig, eventManager: EventManager) => {
    if (config.detector === "SIMON") {
      return new SimonGazeDetector(config, eventManager); // return await import(
      //   /* webpackChunkName: "SimonGazeDetector" */ "./SimonGazeDetector"
      // ).then((SimonGazeDetector) => {
      //   return new SimonGazeDetector.default(config, eventManager);
      // });
    } else if (config.detector === "GEORGE") {
      return new GeorgeGazeDetector(config, eventManager); // return await import(
      //   /* webpackChunkName: "GeorgeGazeDetector" */ "./GeorgeGazeDetector"
      // ).then((GeorgeGazeDetector) => {
      //   return new GeorgeGazeDetector.default(config, eventManager);
      // });
    } else if (config.detector === "REMOTE_FEATURE") {
      return new RemoteFeatureGazeDetector(config, eventManager); // return await import(
      //   /* webpackChunkName: "GeorgeGazeDetector" */ "./GeorgeGazeDetector"
      // ).then((GeorgeGazeDetector) => {
      //   return new GeorgeGazeDetector.default(config, eventManager);
      // });
    } else if (config.detector === "JSONRPC") {
      return new JsonRpcGazeDetector(config, eventManager);
    } else {
      throw "GazeDetectorFactory: Unknown gaze detector type: " + config.detector;
    }
  };
}