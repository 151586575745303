import jpeg from 'jpeg-js';
import { FrameReaderConfig } from 'Managers/ConfigManager';
import EventManager from 'Managers/EventManager';
import Frame from 'Models/Frame';
import Resolution from 'Models/Resolution';
import FrameReader from 'Readers/FrameReader';
import DomUtils, { Orientation } from 'Utils/DomUtils';

export default class Base64FrameReader extends FrameReader {
  _videoCanvas: HTMLCanvasElement; // Media is copied and scaled from source to here (we control this)

  _eventManager: EventManager; // This is an event emitter

  _imageData: ImageData | undefined;
  _imageOrientation: number;
  _imageUpdatedTime: number; // when was image last updated?

  _imageReadTime: number; // when was image last read

  _lastSourceResolution: Resolution; // last resolution of media source. This can change on mobile.

  _lastFrameTime: number;

  /**
   * image should be set by external application
   * @param {FrameReaderConfig} config
   * @param {HTMLCanvasElement} videoCanvas
   */
  constructor(config: FrameReaderConfig, videoCanvas: HTMLCanvasElement) {
    super(config);
    this._videoCanvas = videoCanvas;
    this._eventManager = new EventManager();
    this._lastSourceResolution = new Resolution(0, 0, 1, 1);
    this._imageUpdatedTime = 0.0;
    this._imageReadTime = 0.0;
    this._lastFrameTime = 0.0;
    this._imageOrientation = 0;
  }

  /**
   * Static factory method to build new Base64FrameReader
   * In the event that the DOM is not available (such as in a web worker),
   * the regular constructor may be called
   * @param {FrameReaderConfig} config
   * @return {Base64FrameReader}
   */
  static build = (config: FrameReaderConfig): Base64FrameReader => {
    let videoCanvas = DomUtils.createOffscreenCanvas();
    return new Base64FrameReader(config, videoCanvas);
  };

  /**
   * This emits events which can be relayed to another event manager
   * @return {EventManager}
   */
  eventManager = (): EventManager => this._eventManager;

  /**
   * Start the webcam and begin emitting events
   * Turns on the webcam. The user must accept
   * @return {Promise<Boolean>} success
   */
  start = async (): Promise<boolean> => {
    if (!this.eventManager().emitting()) {
      try {
        let dt = 1000.0 / this.framerate();
        this._lastFrameTime = Date.now();
        this.eventManager().startEmitter(this._emit, dt);
        return true;
      } catch (err) {
        console.warn("WebcamFrameReader: Failed to start webcam!");
        throw err;
      }
    }

    return false;
  };

  /**
   * stop the underlying media source
   * @return {boolean} success
   */
  stop = (): boolean => {
    if (this.eventManager().emitting()) {
      this.eventManager().stopEmitter();
      return true;
    }

    return false;
  };

  /**
   * @param {string} base64
   * @param {number} width
   * @param {number} height
   * @param {string} orientation
   */
  update = (
    base64: string,
    width: number,
    height: number,
    orientation: number = Orientation.PORTRAIT
  ) => {
    let data = Buffer.from(base64, "base64");
    let jpegObj = jpeg.decode(data, {
      useTArray: true,
      formatAsRGBA: true,
    });
    let buffer = jpegObj.data;
    let array = new Uint8ClampedArray(buffer);
    let imageData = new ImageData(array, jpegObj.width, jpegObj.height);
    this._imageData = imageData;
    this._imageUpdatedTime = Date.now();
    this._imageOrientation = orientation
      ? orientation
      : DomUtils.screenOrientation();
  };

  /**
   * Is the next frame ready to be read?
   * @return {boolean}
   */
  hasNextFrame = (): boolean => {
    return (
      this._imageData != null && this._imageUpdatedTime > this._imageReadTime
    );
  };

  /**
   * Return the next available frame from the frame reader.
   * @return {Frame}
   */
  nextFrame = (): Frame => {
    // this code is a bit pasted ----> needs refactor!
    let ctx = this._videoCanvas.getContext("2d");

    let sourceResolution = this.sourceResolution();
    let frameResolution = this.frameResolution();
    let timestamp = Date.now();
    let duration = timestamp - this._lastFrameTime;
    let maxFrameDuration = this.config.maxFrameDuration;

    if (duration > maxFrameDuration) {
      this.stop();
      console.warn("Base64FrameReader: stream has been stopped. ");
      throw Error(
        "Base64FrameReader: Maximum delay between frames exceeded: " +
          duration +
          " > " +
          maxFrameDuration
      );
    }

    this._lastFrameTime = timestamp;
    this._imageReadTime = timestamp;
    let imageData = this._imageData as ImageData;
    let orientation = this._imageOrientation;
    let frame = new Frame(timestamp, duration, imageData, orientation);
    return frame;
  };

  /**
   * The expected resolution of frames to be provided by the frame reader
   * This may change from time to time
   * @return {Resolution}
   */
  frameResolution = (): Resolution => {
    let videoCanvas = this._videoCanvas;
    return new Resolution(0, 0, videoCanvas.width, videoCanvas.height);
  };

  /**
   * The maximum resolution which the reader supports
   * This can be configured
   * @override
   * @return {Resolution}
   */
  maxFrameResolution = (): Resolution => {
    let maxWidth = this.config.maxWidth;
    let maxHeight = this.config.maxHeight;
    return new Resolution(0, 0, maxWidth, maxHeight);
  };

  /**
   * Update the resolution of the frame reader
   * This is usually only called internally
   * @param {Resolution} resolution
   */
  updateFrameResolution = (resolution: Resolution) => {
    this._videoCanvas.width = resolution.width();
    this._videoCanvas.height = resolution.height();
    this._lastSourceResolution = this.sourceResolution();
  };

  /**
   * Has the media source resolution changed?
   * @return {boolean}
   */
  sourceResolutionChanged = (): boolean =>
    !this.sourceResolution().equals(this._lastSourceResolution);

  /**
   * Render video from offscreenCanvas to external canvas
   * @param {CanvasRenderingContext2D} ctx
   */
  renderVideo = (ctx: CanvasRenderingContext2D) => {
    let frameResolution = this.frameResolution();
    ctx.drawImage(
      this._videoCanvas,
      frameResolution.x(),
      frameResolution.y(),
      frameResolution.width(),
      frameResolution.height()
    );
  };

  /**
   * When mobile browser changes orientation this method is called
   * @return {Resolution}
   */
  sourceResolution = (): Resolution => {
    let imageData = this._imageData;
    let width = imageData ? (imageData.width as number) : 0;
    let height = imageData ? (imageData.height as number) : 0;
    return new Resolution(0, 0, width, height);
  };

  /**
   * The quoted framerate (frames per second) of the webcam device
   * This may not be reliable on some browsers
   * @return {number}
   */
  framerate = (): number => {
    return this.config.framerate.ideal;
  };
}
