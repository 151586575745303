import { ECPoint } from "Models/ErrorCorrection";
import StorageHandler from "Utils/StorageHandler";

export class VECPoint extends ECPoint {
  _precision: number;
  _xPrecision: number;
  _yPrecision: number;
  _accuracy: number;
  _xAccuracy: number;
  _yAccuracy: number;

  /**
  /**
   * represent the data needed to store gaze validation
   * @param {number} x
   * @param {number} y
   * @param {number} xCorrection
   * @param {number} yCorrection
   * @param {number} precision 
   * @param {number} xPrecision 
   * @param {number} yPrecision 
   * @param {number} accuracy 
   * @param {number} xAccuracy 
   * @param {number} yAccuracy 
   */
  constructor(
    x: number,
    y: number,
    xCorrection: number,
    yCorrection: number,
    precision: number,
    xPrecision: number,
    yPrecision: number,
    accuracy: number,
    xAccuracy: number,
    yAccuracy: number
  ) {
    super(x, y, xCorrection, yCorrection);
    this._precision = precision;
    this._xPrecision = xPrecision;
    this._yPrecision = yPrecision;
    this._accuracy = accuracy;
    this._xAccuracy = xAccuracy;
    this._yAccuracy = yAccuracy;
  }

  /**
   * serialize validated params by removing all functions
   * useful for persistence
   * @return {*}
   */
  serialize = (): any => {
    return {
      x: this._x,
      y: this._y,
      xCorrection: this._xCorrection,
      yCorrection: this._yCorrection,
      precision: this._precision,
      xPrecision: this._xPrecision,
      yPrecision: this._yPrecision,
      accuracy: this._accuracy,
      xAccuracy: this._xAccuracy,
      yAccuracy: this._yAccuracy,
    };
  };

  /**
   * reverse serialization
   * @param {*} point
   * @return {VECPoint}
   */
  static deserialize = (point: any): VECPoint =>
    new VECPoint(
      point.x,
      point.y,
      point.xCorrection,
      point.yCorrection,
      point.precision,
      point.xPrecision,
      point.yPrecision,
      point.accuracy,
      point.xAccuracy,
      point.yAccuracy
    );

  /**
   * @return {number}
   */
  precision = (): number => this._precision;

  /**
   * @return {number}
   */
  xPrecision = (): number => this._xPrecision;

  /**
   * @return {number}
   */
  yPrecision = (): number => this._yPrecision;

  /**
   * @return {number}
   */
  accuracy = (): number => this._accuracy;

  /**
   * @return {number}
   */
  xAccuracy = (): number => this._xAccuracy;

  /**
   * @return {number}
   */
  yAccuracy = (): number => this._yAccuracy;
}
export default class ValidatedErrorCorrection {
  _timestamp: number;
  _duration: number;
  _vecPoints: Array<VECPoint>;
  _sampleCount: number;
  _precision: number;
  _xPrecision: number;
  _yPrecision: number;
  _accuracy: number;
  _xAccuracy: number;
  _yAccuracy: number;
  _misc: any;

  /**
   * represent the data needed to store gaze validation
   * @param {number} timestamp
   * @param {number} duration
   * @param {Array<VECPoints>} vecPoints
   * @param {number} sampleCount
   * @param {number} precision
   * @param {number} xPrecision
   * @param {number} yPrecision
   * @param {number} accuracy
   * @param {number} xAccuracy
   * @param {number} yAccuracy
   * @param {*} misc
   */
  constructor(
    timestamp: number,
    duration: number,
    vecPoints: Array<VECPoint>,
    sampleCount: number,
    precision: number,
    xPrecision: number,
    yPrecision: number,
    accuracy: number,
    xAccuracy: number,
    yAccuracy: number,
    misc: any = {}
  ) {
    this._timestamp = timestamp;
    this._duration = duration;
    this._vecPoints = vecPoints;
    this._sampleCount = sampleCount;
    this._precision = precision;
    this._xPrecision = xPrecision;
    this._yPrecision = yPrecision;
    this._accuracy = accuracy;
    this._xAccuracy = xAccuracy;
    this._yAccuracy = yAccuracy;
    this._misc = misc;
  }

  /**
   * load saved validation params from local storage
   * @param {*} storageKey
   */
  static fromLocalStorage = (storageKey: string) => {
    let json = StorageHandler.getItem(storageKey);

    if (json) {
      let saveData = JSON.parse(json);
      let model = ValidatedErrorCorrection.deserialize(saveData);
      return model;
    }

    return null;
  };

  /**
   * save validation params to local storage
   * @param {string} storageKey
   */
  toLocalStorage = (storageKey: string) => {
    let json = JSON.stringify(this.serialize());
    StorageHandler.setItem(storageKey, json);
  };

  /**
   * serialize validated params by removing all functions
   * useful for persistence
   * @return {*}
   */
  serialize = (): any => {
    let vecPoints: Array<any> = this._vecPoints.map((p) => p.serialize());

    return {
      timestamp: this._timestamp,
      duration: this._duration,
      vecPoints: vecPoints,
      sampleCount: this._sampleCount,
      precision: this._precision,
      xPrecision: this._xPrecision,
      yPrecision: this._yPrecision,
      accuracy: this._accuracy,
      xAccuracy: this._xAccuracy,
      yAccuracy: this._yAccuracy,
      misc: this._misc,
    };
  };

  /**
   * reverse serialization
   * @param {*} validatedErrorCorrection
   * @return {ValidatedErrorCorrection}
   */
  static deserialize = (
    validatedErrorCorrection: any
  ): ValidatedErrorCorrection => {
    let vecPoints = validatedErrorCorrection.vecPoints.map((p: any) =>
      VECPoint.deserialize(p)
    );
    return new ValidatedErrorCorrection(
      validatedErrorCorrection.timestamp,
      validatedErrorCorrection.duration,
      vecPoints,
      validatedErrorCorrection.sampleCount,
      validatedErrorCorrection.precision,
      validatedErrorCorrection.xPrecision,
      validatedErrorCorrection.yPrecision,
      validatedErrorCorrection.accuracy,
      validatedErrorCorrection.xAccuracy,
      validatedErrorCorrection.yAccuracy,
      validatedErrorCorrection.misc
    );
  };

  /**
   * @return {number}
   */
  timestamp = (): number => this._timestamp;

  /**
   * @return {number}
   */
  duration = (): number => this._duration;

  /**
   * @return {Array<VECPoint>}
   */
  points = (): Array<VECPoint> => this._vecPoints;

  /**
   * @return {number}
   */
  sampleCount = (): number => this._sampleCount;

  /**
   * @return {number}
   */
  precision = (): number => this._precision;

  /**
   * @return {number}
   */
  xPrecision = (): number => this._xPrecision;

  /**
   * @return {number}
   */
  yPrecision = (): number => this._yPrecision;

  /**
   * @return {number}
   */
  accuracy = (): number => this._accuracy;

  /**
   * @return {number}
   */
  xAccuracy = (): number => this._xAccuracy;

  /**
   * @return {number}
   */
  yAccuracy = (): number => this._yAccuracy;

  /**
   * @return {*}
   */
  misc = (): any => this._misc;

  /**
   * @return ErrorCorrection
   */
  static Zero = (): ValidatedErrorCorrection => {
    return new ValidatedErrorCorrection(0, 0, [], 0, 0, 0, 0, 0, 0, 0);
  };
}
