declare const VERSION: string;

export default class ConfigManager {
  version = VERSION;
  frameReader: FrameReaderConfig = new FrameReaderConfig();
  landmarkDetector: LandmarkDetectorConfig = new LandmarkDetectorConfig();
  videoView: VideoViewConfig = new VideoViewConfig();
  presenceDetector: PresenceDetectorConfig = new PresenceDetectorConfig();
  gazeDetector: GazeDetectorConfig = new GazeDetectorConfig();
  gazeValidator: GazeValidatorConfig = new GazeValidatorConfig();
}
export type AnimationType = "REQUEST_ANIMATION_FRAME" | "SET_TIMEOUT";
export type FrameReaderType =
  | "WEBCAM"
  | "VIDEO_FILE"
  | "IMAGE_ELEMENT"
  | "BASE64";
export type GazeDetectorType =
  | "SIMON"
  | "GEORGE"
  | "JSONRPC"
  | "REMOTE_FEATURE";
export type GazeValidatorType = "ERROR_CORRECTION";
export type WasmModuleConfig = {
  version: string;
  name: string;
  modularized: boolean;
  wasmPath: string;
  wasmWasm: string;
  wasmJs: string;
  allocate: boolean;
  useSimd: boolean;
  useThreads: boolean;
  useWorkers: boolean;
  singleton: boolean;
  exportName: string;
};
export type GazeFilterConfig = {
  calibrator: string;
  margin: number;
  weights: Array<number>;
  nNearest: number;
  beta: number;
};
const wasmGeorge: WasmModuleConfig = {
  version: "2.0.3",
  name: "george",
  modularized: true,
  wasmPath:
    // "https://lumenresearch-rni-models-wasm-dev.s3.eu-west-2.amazonaws.com/george/0.1/platforms/wasm/dist/",
    "https://lumenresearch-rni-models-wasm-dev.s3.eu-west-2.amazonaws.com/george/2.0.3/platforms/wasm/dist/",
    // "https://lr-eye-tracker.lumen-research.com/george/2.0.3/platforms/wasm/dist/",
  wasmJs: "lumen_tracker.js",
  wasmWasm: "lumen_tracker.wasm",
  allocate: true,
  useSimd: true,
  singleton: true,
  useWorkers: false,
  useThreads: false,
  exportName: "Module",
};
const wasmSimon: WasmModuleConfig = {
  version: "1.0",
  name: "simon",
  modularized: false,
  wasmPath:
    "https://lr-webcam-tracker.lumen-research.com/release/models/simon/wasm/",
  // "https://lumenresearch-rni-models-wasm-dev.s3.eu-west-2.amazonaws.com/simon/platforms/wasm/dist/";
  // Note: needs to be deployed with dev!
  wasmJs: "_lumen_webcam_tracker.js",
  wasmWasm: "_lumen_webcam_tracker.wasm",
  allocate: false,
  useSimd: false,
  singleton: false,
  useWorkers: false,
  useThreads: false,
  exportName: "Module",
};
export class FrameReaderConfig {
  reader: FrameReaderType = "WEBCAM";
  width: number = 640;
  height: number = 480;
  mirror: boolean = false;
  maxWidth: number = 640;
  maxHeight: number = 640;
  maxFrameDuration: number = 1000; // clamp frames to have this duration (maximum)
  frameTimeoutDuration: number = 10000; // throw exception if frame takes longer than frameTimeoutDuration (milliseconds)
  frameTimeoutCount: number = 10;
  framerate = {
    ideal: 30,
    max: 60,
  };
  flightLimit: number = 2; // maximum number of frames in flight
  facingMode: string = "user";
  useWebGL: boolean = false; // webgl is faster on some platforms and supports frame allocator in web assembly which copies less data
  allocator: WasmModuleConfig = wasmSimon; // allocate frames from web assembly?
  oversample: number = 5; // Check for new frames at increased rate compared to maximum framerate
  videoFile: string = "";
}
export class WorkerConfig {
  useWebWorkers: boolean = true;
  maxQueuedFrames: number = 2;
}
export class LandmarkDetectorConfig {
  detector = "BRF_4";
  worker: WorkerConfig = new WorkerConfig();
  medianDurationCount = 9; // number of previous durations to keep track of

  maxLandmarkDuration = 2000; // throw exception if landmark takes longer than maxLandmarkDuration (milliseconds)
  numFaces = 1;

  brf: any = {
    imageConfig: {
      // This is for initialization only. Can be changed later. Potentially should be removed?
      inputWidth: 640,
      inputHeight: 480,
    },
    mode: "mode_face_tracking",
    numFaces: 1,
    license: "LR310818",
    // for BRFv4
    faceDetectionConfig: {
      regionOfInterest: {
        x: 0,
        y: 0,
        width: 640,
        height: 480,
      },
      // region of interest is not currently used
      minFaceSize: null,
      // calculated dynamically for BRFv4
      maxFaceSize: null,
      // calculated dynamically for BRFv4
      faceSizeIncrease: null,
      // not used in BRFv4
      stepSize: 12,
      minNumNeighbors: 8,
      rectMergeFactor: null,
      // Not used in BRFv4
      rectSkipFactor: null,
      // Not used in BRFv4
      filterNoise: true, // Not used in BRFv4
    },
  };
  george = wasmGeorge;
}
export class FramerateConfig {
  x = 0;
  y = 0;
  realtimeAnimation = true;
  targetFps = 60; // Only used for non-realtime animation

  maxRecords = 10; // how many timestamps to track

  font = "italic 20px Georgia";
  textAlign: CanvasTextAlign = "left";
}
export class VideoViewConfig {
  animation: AnimationType = "REQUEST_ANIMATION_FRAME";
  framerate: FramerateConfig = new FramerateConfig();
  frameFallbackAfter = 1000; // fallback to reading frames after (milliseconds)

  mirror: boolean = true; // show mirror image of video
  silhouette = false;
  antiFlickerFilter = { fc: 0.0005, dfc: 0.02, beta: 0.04 };
  silhouetteScale = 0.85;
  silhouetteFilter = { fc: 0.0005, dfc: 0.02, beta: 0.0005 };

  colour: string = "#06B7D6";
}
export class PresenceDetectorConfig {
  presence = "SIMPLE_PRESENCE";
}
export class ClicklessDotViewConfig {
  animation: AnimationType = "REQUEST_ANIMATION_FRAME";
  framerate: FramerateConfig = new FramerateConfig();
  colour = "#AFAFAF";
  margin = {
    x: 0.05,
    y: 0.05,
  };
  pointProperties = {
    radius: 0.0375,
    // this should be higher on mobile
    innerFraction: 0.1667,
    // fraction of radius (e.g dot radius is: innerFraction * radius * min(width, height)
    maxOuterFraction: 1.0,
    // fraction of radius
    minOuterFraction: 0.6, // fraction of radius
  };
  moveProperties = {
    duration: 1000.0, // duration of movement (milliseconds)
  };
  pulsateProperties = {
    duration: 1000.0, // duration of pulsation (milliseconds)
  };
}
export class PoseSegmentedDotViewConfig extends ClicklessDotViewConfig {
  colour = "#06B7D6";
  segmentProperties = {
    numberOfSegments: 6,
    centralSegment: false,
    startColour: "#ff9400",
    midColour: "#ffe900",
    finishColour: "#bfff00",
    innerRimFraction: 1.2,
    innerFraction: 1.3,
    outerFraction: 1.55,
    minCircleFraction: 0.01,
  };
}
export class VideoDotViewConfig extends PoseSegmentedDotViewConfig {
  videos = [
    {
      size: 0.0375 * 2,
      start: 0,
      end: 5000 - 1500,
      loop: false,
      id: "lr_calibration_shake",
      uri: "https://lr-eye-tracker.lumen-research.com/resources/shake.mp4",
    },
    {
      size: 0.0375 * 2,
      start: 5000,
      end: 9000 - 2000,
      loop: false,
      id: "lr_calibration_shake",
      uri: "https://lr-eye-tracker.lumen-research.com/resources/shake.mp4",
    },
    {
      size: 0.0375 * 2,
      start: 500,
      end: 5000 - 2300,
      loop: false,
      id: "lr_calibration_nod",
      uri: "https://lr-eye-tracker.lumen-research.com/resources/nod.mp4",
    },
    {
      size: 0.0375 * 2,
      start: 500,
      end: 5000 - 1000,
      loop: false,
      id: "lr_calibration_down",
      uri: "https://lr-eye-tracker.lumen-research.com/resources/down.mp4",
    },
  ];
  transition = {
    showVideo: false,
    showDot: true,
    showProgress: false,
    videoIndex: 0,
  };
  progressAngles = [
    [0, 0],
    [0, 30],
    [90, 30],
    [180, 30],
    [270, 30],
  ];
  schedule = [
    {
      showVideo: true,
      videoIndex: 0,
      showDot: true,
      showProgress: true,
      progressIds: [3],
    },
    {
      showVideo: true,
      videoIndex: 1,
      showDot: true,
      showProgress: true,
      progressIds: [1],
    },
    {
      showVideo: true,
      videoIndex: 2,
      showDot: true,
      showProgress: true,
      progressIds: [4],
    },
    {
      showVideo: true,
      videoIndex: 3,
      showDot: true,
      showProgress: true,
      progressIds: [2],
    },
    {
      showVideo: false,
      videoIndex: 3,
      showDot: true,
      showProgress: false,
      progressIds: [0],
    },
  ];
}

export class GazeCalibratorConfig {
  calibrator = "CLICKLESS_DOT";
  view = new ClicklessDotViewConfig();
  numberOfPoints = 5; // number of calibration points

  numberOfSamples = 50; // number of total samples

  poseMultiplier = 2;
  numberOfSegments = 6;
  centralSegment = false;
  preSampleDelay = 300; // delay before sampling (ms)

  postSampleDelay = 0; // delay after sampling (ms)
  sequential = false;
  poseCorrection = true;
  maxPoseBuffer = 300;

  points: { x: number; y: number; timeout: number; minCompletion: number }[] =
    [];
  hintProperties = {
    relaxationDuration: 800,
    // ms (no samples for ms after hint)
    showAfter: 5000, // ms (show hint after ms of inactivity)
  };
}
export class VideoGazeCalibratorConfig extends GazeCalibratorConfig {
  points = [
    {
      x: 0.5,
      y: 0.5,
      timeout: 15000,
      minCompletion: 0.39,
    },
    {
      x: 0.2,
      y: 0.1,
      timeout: 15000,
      minCompletion: 0.39,
    },
    {
      x: 0.8,
      y: 0.1,
      timeout: 15000,
      minCompletion: 0.39,
    },
    {
      x: 0.8,
      y: 0.9,
      timeout: 15000,
      minCompletion: 0.39,
    },
    {
      x: 0.2,
      y: 0.9,
      timeout: 15000,
      minCompletion: 0.39,
    },
  ];
  bounds = [
    [0, 360, 0, 14], // natural
    [0, 60, 40, 34], // right
    [90, 80, 30, 27], // down
    [180, 60, 40, 34], // left
    [270, 60, 30, 24], // up
  ];
  schedule = [
    {
      targets: [3],
      timeout: 5000,
    },
    {
      targets: [1],
      timeout: 5000,
    },
    {
      targets: [4],
      timeout: 5000,
    },
    {
      targets: [2],
      timeout: 5000,
    },
    {
      targets: [0],
      timeout: 500,
    },
  ];
  calibrator = "POSE_SEGMENTED_VIDEO";
  sequential = false;
  // should we do multiple calibrations in one calibrate call
  poseCorrection = true;
  // should we attempt to correct pose by keeping track of a moving average
  maxPoseBuffer = 300;
  // how many poses to keep track of for calculating normal pose

  hintProperties = {
    relaxationDuration: 800,
    // ms (no samples for ms after hint)
    showAfter: 5000, // ms (show hint after ms of inactivity)
  };
  view = new VideoDotViewConfig();
}
const videoGazeCalibrator = new VideoGazeCalibratorConfig();

export class GazeDetectorConfig {
  detector: GazeDetectorType = "SIMON";
  calibrator: GazeCalibratorConfig | GazeCalibratorConfig[];
  movingAverageWeights: Array<number> = [1, 2, 3]; // set this to [1] to disable time averaging
  eyeIlluminationThreshold: number = 0.0;
  headPositionThreshold: number = 0.0;
  validationAccuracyThreshold: number = 300.0;

  constructor() {
    this.calibrator = new GazeCalibratorConfig();
    this.calibrator.view.colour = "#06B7D6";
  }

  handshakeUrl =
    "https://licence.lumen-research.com/v1.0/js_software_startup_handshake";
  submitMachineId = false;
  license = "dba856d0-7c59-4db6-85b6-d528eb8b7397";
  simon = wasmSimon;
  george = wasmGeorge;
  fov = 52.5;
  port = "";
  ip = "";
  url = ""; // "https://89vh9epdmj.execute-api.eu-west-2.amazonaws.com/trial/sample"; // url to upload to
  tag = ""; // "tag123"; // tag to upload
}

export class GazeValidatorConfig {
  validator: GazeValidatorType = "ERROR_CORRECTION";
  calibrator: GazeCalibratorConfig | GazeCalibratorConfig[];
  filter = ["SIGMOID", "CLAMP"];
  filterProperties = {
    nNearest: 2,
    beta: 7,
    clamp: -500,
  };
  resetCorrections = false;

  constructor() {
    this.calibrator = new GazeCalibratorConfig();
    this.calibrator.view.colour = "#228B22";
  }
}

export function MobileConfigV1() {
  let config = new ConfigManager();
  (<GazeCalibratorConfig>(
    config.gazeDetector.calibrator
  )).view.pointProperties.radius = 0.0375 * 2;
  (<GazeCalibratorConfig>(
    config.gazeValidator.calibrator
  )).view.pointProperties.radius = 0.0375 * 2;
  config.gazeDetector.movingAverageWeights = [1, 2, 3];
  return config;
}

export function DesktopConfigV1() {
  let config = new ConfigManager();
  (<GazeCalibratorConfig>(
    config.gazeDetector.calibrator
  )).view.pointProperties.radius = 0.0375;
  (<GazeCalibratorConfig>(
    config.gazeValidator.calibrator
  )).view.pointProperties.radius = 0.0375;
  config.gazeDetector.movingAverageWeights = [1, 2, 3, 4, 5];
  return config;
}

export function MobileConfigV2() {
  let config = new ConfigManager();
  config.frameReader.maxWidth = 480;
  config.frameReader.maxHeight = 640;
  config.frameReader.useWebGL = false;
  config.landmarkDetector.detector = "GEORGE";
  config.landmarkDetector.worker.useWebWorkers = false;
  config.gazeDetector.detector = "GEORGE";
  let detCal = config.gazeDetector.calibrator as GazeCalibratorConfig;
  detCal.poseMultiplier = 2.0; // collect numberOfSamples * postMultiplier total points

  detCal.numberOfSamples = 120;
  detCal.numberOfPoints = 5;
  detCal.view.pointProperties.radius = 0.0375 * 2;
  (<GazeCalibratorConfig>(
    config.gazeValidator.calibrator
  )).view.pointProperties.radius = 0.0375 * 2;
  config.gazeDetector.movingAverageWeights = [1, 2, 3];
  config.gazeDetector.eyeIlluminationThreshold = 0.05;
  config.gazeDetector.headPositionThreshold = 0.8;
  return config;
}

export function DesktopConfigV2() {
  let config = new ConfigManager();
  // config.frameReader.maxWidth = 1280;
  // config.frameReader.maxHeight = 720;
  config.frameReader.maxWidth = 640;
  config.frameReader.maxHeight = 480;
  config.frameReader.useWebGL = false;
  config.landmarkDetector.detector = "GEORGE";
  config.landmarkDetector.worker.useWebWorkers = false;
  config.gazeDetector.detector = "GEORGE";
  let detCal = config.gazeDetector.calibrator as GazeCalibratorConfig;
  detCal.numberOfSamples = 120;
  detCal.poseMultiplier = 2.0; // collect numberOfSamples * postMultiplier total points
  detCal.numberOfPoints = 5;
  (<GazeCalibratorConfig>(
    config.gazeDetector.calibrator
  )).view.pointProperties.radius = 0.0375;
  (<GazeCalibratorConfig>(
    config.gazeValidator.calibrator
  )).view.pointProperties.radius = 0.0375;
  // config.gazeDetector.calibrator = [
  //  Object.assign({}, detCal),
  //  Object.assign({}, detCal),
  // ];
  // config.gazeDetector.calibrator[0].calibrator = "CLICKLESS_DOT";
  // config.gazeDetector.calibrator[1].calibrator = "POSE_SEGMENTED_DOT";
  // (<GazeCalibratorConfig>(
  //   config.gazeValidator.calibrator
  // )).view.pointProperties.radius = 0.0375;

  config.gazeDetector.movingAverageWeights = [1, 2, 3, 4, 5];
  config.gazeDetector.eyeIlluminationThreshold = 0.05;
  config.gazeDetector.headPositionThreshold = 0.8;
  return config;
}

export function JsonRpcConfig() {
  let config = new ConfigManager();
  config.frameReader.reader = "BASE64"; // only because it is passive

  config.landmarkDetector.detector = "GEORGE"; // init is not called so it won't cause overhead

  config.landmarkDetector.worker.useWebWorkers = false;
  config.gazeDetector.detector = "JSONRPC";
  config.gazeDetector.port = "58637";
  config.gazeDetector.ip = "localhost";
  let detCal = config.gazeDetector.calibrator as GazeCalibratorConfig;
  detCal.calibrator = "EXTERNAL";
  config.gazeDetector.movingAverageWeights = [1];
  let valCal = config.gazeValidator.calibrator as GazeCalibratorConfig;
  valCal.view.pointProperties.radius = 0.0375; // It runs on desktop

  return config;
}

export function RemoteFeatureConfig() {
  let config = new ConfigManager();
  config.frameReader.width = 640;
  config.frameReader.height = 480;
  config.frameReader.useWebGL = false;
  config.frameReader.flightLimit = 9;
  config.landmarkDetector.detector = "GEORGE";
  config.landmarkDetector.worker.useWebWorkers = false;
  config.gazeDetector.detector = "REMOTE_FEATURE";
  config.gazeDetector.george = config.landmarkDetector.george;

  return config;
}
