import Landmarks from "Models/Landmarks";
import Landmarks468 from "Models/Landmarks468";
import Landmarks68 from "Models/Landmarks68";

export default class LandmarksFactory {
  /**
   * reverse serialization
   * @param {*} landmarks
   * @return {Landmarks}
   */
  static deserialize = (landmarks: any): Landmarks => {
    switch (String(landmarks.class)) {
      case "Landmarks68":
        // Doesn't javascript have some kind of class signature?
        return Landmarks68.deserialize(landmarks);

      case "Landmarks468":
        return Landmarks468.deserialize(landmarks);

      default:
        throw (
          "Landmarks: Attempt to deserialize landmarks with unknown class: " +
          String(landmarks.class)
        );
    }
  };
}
