import LandmarkDetector from "Detection/LandmarkDetector";
import { LandmarkDetectorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Landmarks468 from "Models/Landmarks468";
import Resolution from "Models/Resolution";
import WasmModule from "Utils/WasmModule";

export default class GeorgeLandmarkDetector extends LandmarkDetector {
  config: LandmarkDetectorConfig;
  _landmarks: Landmarks468 | null | undefined;
  _wasm: WasmModule | undefined;

  /**
   * @param {LandmarkDetectorConfig} config;
   */
  constructor(config: LandmarkDetectorConfig) {
    super();
    this.config = config;
  }

  /**
   * @param {number} width
   * @param {number} height
   */
  init = async (width: number, height: number) => {
    try {
      let _this = this;

      let george = this.config.george;
      let wasmFuture = WasmModule.build(george, new EventManager()).then(
        async (wasm) => {
          await wasm
            .module()
            ._init_face_detector(
              this.config.numFaces ? this.config.numFaces : 1
            );
          _this._wasm = wasm; // await _this._setParams();
        }
      );
      await wasmFuture;
    } catch (err) {
      throw {
        name: "LRTrackerInitError",
        message: "GeorgeLandmarkDetector: " + err,
        stack: new Error().stack,
      };
    }
  };

  /**
   * Update the landmark detector with a new frame
   * @param {Frame} frame
   */
  update = (frame: Frame) => {
    if (this._wasm) {
      let module = this._wasm.module();

      let width = frame.imageData().width;
      let height = frame.imageData().height;
      let timestamp = frame.timestamp();
      let duration = frame.duration();
      let imageSize = width * height * 4;

      let imageOffset = module._get_image_offset(width, height);

      let heapBytes = module.HEAPU8.subarray(
        imageOffset,
        imageOffset + imageSize
      );
      let data = frame.imageData().data;

      if (
        heapBytes.buffer != data.buffer ||
        heapBytes.byteOffset != data.byteOffset ||
        heapBytes.byteLength != data.byteLength
      ) {
        heapBytes.set(frame.imageData().data);
      }

      let success = module._detect_landmarks(timestamp, duration);

      if (success) {
        let landmarksOffset = this._wasm
          .module()
          ._get_landmarks_offset(468 * 3);

        heapBytes = module.HEAPF32.subarray(
          landmarksOffset / 4,
          landmarksOffset / 4 + 468 * 3
        );
        let landmarksData = new Float32Array(heapBytes);
        this._landmarks = new Landmarks468(timestamp, duration, landmarksData);
      } else {
        this._landmarks = null;
      }
    }
  };

  /**
   * reset the landmark detector
   * call this when screen orientation changes maybe
   */
  reset = () => {};

  /**
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    // We don't need to change anything
  };

  /**
   * @override
   * @return {Boolean}
   */
  hasNextLandmarks = (): boolean => {
    return this._landmarks != null;
  };

  /**
   * @override
   * @return {Landmarks468}
   */
  nextLandmarks = (): Landmarks468 => {
    return this._landmarks ? this._landmarks : Landmarks468.Zeros();
  };
}
