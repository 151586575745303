export type Eye = {
  in: {
    x: number;
    y: number;
  };
  out: {
    x: number;
    y: number;
  };
};

export default class Eyes {
  _timestamp: number;
  _duration: number;
  _left: Eye;
  _right: Eye;

  constructor(timestamp: number, duration: number, left: Eye, right: Eye) {
    this._timestamp = timestamp;
    this._duration = duration;
    this._left = left;
    this._right = right;
  }

  /**
   * useful when communicating with web workers
   * @return {*}
   */
  serialize = () => {
    return {
      timestamp: this._timestamp,
      duration: this._duration,
      left: this._left,
      right: this._right,
    };
  };

  /**
   * reverse serialization
   * @param {*} eyes
   * @return {Eyes}
   */
  static deserialize = (eyes: any) =>
    new Eyes(eyes.timestamp, eyes.duration, eyes.left, eyes.right);

  /**
   * build eyes model from 68-point vertices
   * @return {Eyes}
   */
  static build = (
    timestamp: number,
    duration: number,
    vertices: Array<number>
  ) => {
    let left = {
      in: {
        x: vertices[78],
        y: vertices[79],
      },
      out: {
        x: vertices[72],
        y: vertices[73],
      },
    };
    let right = {
      in: {
        x: vertices[84],
        y: vertices[85],
      },
      out: {
        x: vertices[90],
        y: vertices[91],
      },
    };
    return new Eyes(timestamp, duration, left, right);
  };
  left = () => this._left;
  right = () => this._right;
}
