import FingerprintJS from "@fingerprintjs/fingerprintjs";
import GazeDetector from "Detection/GazeDetector";
import { GazeDetectorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Gaze from "Models/Gaze";
import Landmarks from "Models/Landmarks";
import Resolution from "Models/Resolution";
import Trained from "Models/Trained";
import DomUtils from "Utils/DomUtils";
import WasmModule from "Utils/WasmModule";

export default class SimonGazeDetector extends GazeDetector {
  config: GazeDetectorConfig;
  _wasm: WasmModule | undefined;
  _lastOrientation: number | null | undefined;
  _model: Trained | null | undefined; // friendly data

  _modelOffset: number | undefined; // offset of model data in wasm heap

  _gaze: Gaze | null | undefined;
  _index = 0;

  /**
   * Web Assembly Gaze Detector may be configured to use different models
   * providing that they adhere to
   * @param {GazeDetectorConfig} config
   * @param {EventManager} eventManager
   */
  constructor(config: GazeDetectorConfig, eventManager: EventManager) {
    super(config, eventManager);
    this.config = config;
  }

  /**
   * Initialize GazeDetector
   * Must be called prior to both calibration and detection
   */
  init = async () => {
    if (this._landmarksSubscription) {
      this._landmarksSubscription.remove();

      this._landmarksSubscription = null;
    }

    try {
      this._wasm = await WasmModule.build(
        this.config.simon,
        this._eventManager
      );
      await this._setParams();
    } catch (err) {
      throw {
        name: "LRTrackerInitError",
        message: "SimonGazeDetector: " + err,
        stack: new Error().stack,
      };
    }
  };

  /**
   * reset detection state
   */
  reset = () => {
    this._lastOrientation = null;
    this._model = null;
    if (this._wasm) {
      this._wasm.module()._reset_trainig();
    }

    this._index = 0;
  };

  /**
   * Update the gaze detector
   * @param {Frame} frame to detect gaze from
   * @param {Landmarks} landmarks to be used during gaze detection
   */
  update = (frame: Frame, landmarks: Landmarks) => {
    this._lastOrientation = frame.orientation();

    if (this._model && this._model.orientation() === this._lastOrientation) {
      let returnedValue;
      if (this._wasm) {
        let webcamImage = this._wasm.arrayToHeap(frame.imageData().data);

        let eyes = landmarks.eyes();
        returnedValue = this._wasm
          .module()
          ._get_gaze(
            frame.imageData().width,
            frame.imageData().height,
            webcamImage.byteOffset,
            eyes.left().out.x,
            eyes.left().out.y,
            eyes.left().in.x,
            eyes.left().in.y,
            eyes.right().in.x,
            eyes.right().in.y,
            eyes.right().out.x,
            eyes.right().out.y
          );
        let scale = DomUtils.devicePixelRatio();
        let dx = DomUtils.screenContentX();
        let dy = DomUtils.screenContentY();
        let x = this._wasm.module()._get_x() / scale - dx;
        let y = this._wasm.module()._get_y() / scale - dy;
        this._gaze = new Gaze(
          this._index++,
          frame.timestamp(),
          frame.duration(),
          x,
          y
        );

        this._wasm.freeArray(webcamImage);
      }
    } else {
      this._gaze = null;
    }

    return this.nextGaze();
  };

  /**
   * resize the gaze detector
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    throw "WasmGazeDetector: Not Implemented!";
  };

  /**
   * @return {Boolean}
   */
  hasNextGaze = (): boolean => this._gaze != null;

  /**
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._gaze ? this._gaze : Gaze.Zero();
  };

  /**
   *
   * @param {Frame} frame
   * @param {Landmarks} landmarks
   * @param {Gaze} gaze
   */
  addTrainingData = (frame: Frame, landmarks: Landmarks, gaze: Gaze) => {
    // resolution is currently fixed at 640 * 480
    let eyes = landmarks.eyes();
    if (this._wasm) {
      let webcamImage = this._wasm.arrayToHeap(frame.imageData().data);

      this._lastOrientation = frame.orientation();
      let scale = DomUtils.devicePixelRatio();
      let dx = DomUtils.screenContentX();
      let dy = DomUtils.screenContentY();
      let x = scale * (dx + gaze.x());
      let y = scale * (dy + gaze.y());

      let response = this._wasm
        .module()
        ._add_training_face(
          frame.imageData().width,
          frame.imageData().height,
          webcamImage.byteOffset,
          eyes.left().out.x,
          eyes.left().out.y,
          eyes.left().in.x,
          eyes.left().in.y,
          eyes.right().in.x,
          eyes.right().in.y,
          eyes.right().out.x,
          eyes.right().out.y,
          x,
          y
        );

      this._wasm.freeArray(webcamImage);
    }
  };

  /**
   * train gaze detector using supplied data
   */
  train = () => {
    console.log("training");
    if (this._wasm) {
      this._wasm.module()._train();

      let timestamp = Date.now();

      let buffer = this._wasm.allocateMemInHeap(1600, 8);

      this._wasm.module()._get_model(buffer.byteOffset); //copy model to buffer

      let xOffset = 0;
      let yOffset = 0;
      let orientation = this._lastOrientation ? this._lastOrientation : 0;
      let model = new Trained(
        timestamp,
        this.config.simon.name,
        this.config.simon.version,
        orientation,
        xOffset,
        yOffset,
        buffer.toString()
      );

      this._wasm.freeArray(buffer);

      this._model = model;
      console.log("training complete");

      // Navid spelled ing wrong again
      // aaaaaaaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA!!!!!!!!!!
      this._wasm.module()._reset_trainig();
      return model;
    }
    return Trained.Zero();
  };

  /**
   * @return {Trained}
   */
  model = (): Trained => {
    return this._model ? this._model : Trained.Zero();
  };

  /**
   * set the model which is used for gaze detection
   * @param {Trained} model
   */
  setModel = (model: Trained) => {
    if (this._wasm) {
      let buffer = this._wasm.arrayToHeap(model.data());

      this._wasm.module()._load_model(buffer.byteOffset);

      this._wasm.freeArray(buffer);

      this._model = model;
    }
  };

  /**
   * For security
   */
  _setParams = async () => {
    // encode the licence
    //////////////////////////// Text encoding polyfill for MS Edge////////////////////////
    if (!this._wasm) {
      return;
    }
    if (typeof TextEncoder === "undefined") {
      let TextEncoder = function TextEncoder() {};

      TextEncoder.prototype.encode = function encode(str: string) {
        "use strict";

        let resLen = str.length;
        let resPos = -1;
        // The Uint8Array's length must be at least 3x the length of the string because an invalid UTF-16
        // takes up the equivalent space of 3 UTF-8 characters to encode it properly. However, Array's
        // have an auto expanding length and 1.5x should be just the right balance for most uses.
        let resArr = new Uint8Array(resLen * 3);

        for (let point = 0, nextcode = 0, i = 0; i !== resLen; ) {
          (point = str.charCodeAt(i)), (i += 1);

          if (point >= 0xd800 && point <= 0xdbff) {
            if (i === resLen) {
              resArr[(resPos += 1)] = 0xef;
              /*0b11101111*/
              resArr[(resPos += 1)] = 0xbf;
              /*0b10111111*/
              resArr[(resPos += 1)] = 0xbd;
              /*0b10111101*/
              break;
            }

            // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
            nextcode = str.charCodeAt(i);

            if (nextcode >= 0xdc00 && nextcode <= 0xdfff) {
              point = (point - 0xd800) * 0x400 + nextcode - 0xdc00 + 0x10000;
              i += 1;

              if (point > 0xffff) {
                resArr[(resPos += 1)] =
                  (0x1e <<
                    /*0b11110*/
                    3) |
                  (point >>> 18);
                resArr[(resPos += 1)] =
                  (0x2 <<
                    /*0b10*/
                    6) |
                  ((point >>> 12) & 0x3f);
                /*0b00111111*/
                resArr[(resPos += 1)] =
                  (0x2 <<
                    /*0b10*/
                    6) |
                  ((point >>> 6) & 0x3f);
                /*0b00111111*/
                resArr[(resPos += 1)] =
                  (0x2 <<
                    /*0b10*/
                    6) |
                  (point & 0x3f);
                /*0b00111111*/
                continue;
              }
            } else {
              resArr[(resPos += 1)] = 0xef;
              /*0b11101111*/
              resArr[(resPos += 1)] = 0xbf;
              /*0b10111111*/
              resArr[(resPos += 1)] = 0xbd;
              /*0b10111101*/
              continue;
            }
          }

          if (point <= 0x007f) {
            resArr[(resPos += 1)] =
              (0x0 <<
                /*0b0*/
                7) |
              point;
          } else if (point <= 0x07ff) {
            resArr[(resPos += 1)] =
              (0x6 <<
                /*0b110*/
                5) |
              (point >>> 6);
            resArr[(resPos += 1)] =
              (0x2 <<
                /*0b10*/
                6) |
              (point & 0x3f);
            /*0b00111111*/
          } else {
            resArr[(resPos += 1)] =
              (0xe <<
                /*0b1110*/
                4) |
              (point >>> 12);
            resArr[(resPos += 1)] =
              (0x2 <<
                /*0b10*/
                6) |
              ((point >>> 6) & 0x3f);
            /*0b00111111*/
            resArr[(resPos += 1)] =
              (0x2 <<
                /*0b10*/
                6) |
              (point & 0x3f);
            /*0b00111111*/
          }
        }

        if (typeof Uint8Array !== "undefined")
          return resArr.subarray(0, resPos + 1);
        // else // IE 6-9
        // resArr.length = resPos + 1; // trim off extra weight <--- ???

        return resArr;
      };

      try {
        // Object.defineProperty only works on DOM prototypes in IE8
        Object.defineProperty(TextEncoder.prototype, "encoding", {
          get: function () {
            if (TextEncoder.prototype.isPrototypeOf(this)) return "utf-8";
            else throw TypeError("Illegal invocation");
          },
        });
      } catch (e) {
        /*IE6-8 fallback*/
        TextEncoder.prototype.encoding = "utf-8";
      }
    }

    ///////////////////////////! Text encoding polyfill for MS Edge ////////////////////////////
    let enc = new TextEncoder(); // always utf-8

    let fp = await FingerprintJS.load();
    let mid = this.config.submitMachineId ? (await fp.get()).visitorId : ""; // This is the visitor identifier:

    let encoded_mid = enc.encode(mid);
    // let lic = "dba856d0-7c59-4db6-85b6-d528eb8b7397"; <--- the old license key
    let lic = this.config.license;
    let encoded_lic = enc.encode(lic);
    let encoded_data = new Uint8Array(encoded_mid.length + encoded_lic.length);
    encoded_data.set(encoded_mid);
    encoded_data.set(encoded_lic, encoded_mid.length);
    
    let lic_data = this._wasm.arrayToHeap(encoded_data);

    // params data
    let params_data = this._wasm.arrayToHeap(new Uint8Array(1000));

    let data_size = this._wasm.arrayToHeap(new Uint8Array(4));

    this._wasm
      .module()
      ._get_params(
        params_data.byteOffset,
        data_size.byteOffset,
        lic_data.byteOffset,
        lic_data.length
      );

    let u8 = new Uint8Array(data_size); // original array

    let u32bytes = u8.buffer.slice(-4);
    let uint = new Uint32Array(u32bytes)[0];
    let data_from_cpp = params_data.subarray(0, uint);
    let request_json = {
      data: Array.prototype.slice.call(data_from_cpp),
      mid_size: encoded_mid.length,
      lic_size: encoded_lic.length,
    };
    let resp = await fetch(this.config.handshakeUrl, {
      method: "POST",
      body: JSON.stringify(request_json),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await resp.json();
    var cypher = new Uint8Array(data["params"]);

    var cypher_data = this._wasm.arrayToHeap(cypher);

    this._wasm.module()._set_params(cypher_data.byteOffset, cypher_data.length);
  };
}
