import NoiseCorrectionFilter from "GazeFilters/NoiseCorrectionFilter";
import {
  abs,
  add,
  dotDivide,
  dotMultiply,
  Matrix,
  multiply,
  subtract,
} from "mathjs";
import Gaze from "Models/Gaze";

export class OneEuroNoiseCorrectionFilter<T> {
  _fc: number;
  _dfc: number;
  _beta: number;

  _timestamp: number;
  _x_hat: Matrix;
  _dx_dt_hat: Matrix;

  constructor(fc: number, dfc: number, beta: number) {
    this._timestamp = 0.0;
    this._x_hat = new Matrix();
    this._dx_dt_hat = new Matrix();
    this._fc = 2 * Math.PI * fc;
    this._dfc = 2 * Math.PI * dfc;
    this._beta = beta;
  }

  reset = () => {
    this._timestamp = 0.0;
  };

  apply = (x: T, timestamp: number): T => {
    let dt = timestamp - this._timestamp;
    if (this._timestamp == 0.0 || dt < 0.0) {
      this._x_hat = multiply(x as Matrix, 0.0);
      this._dx_dt_hat = multiply(x as Matrix, 0.0);
    } else {
      dt = Math.max(dt, 0.0001);
      let dx_dt = multiply(subtract(x as Matrix, this._x_hat), 1.0 / dt);

      let r_d = this._dfc * dt;
      let alpha_d = r_d / (r_d + 1.0);
      this._dx_dt_hat = add(
        multiply(dx_dt, alpha_d),
        multiply(1.0 - alpha_d, this._dx_dt_hat)
      );

      let fc = add(abs(multiply(this._dx_dt_hat, this._beta)), this._fc);
      let r = multiply(fc, dt) as Matrix;

      let alpha = dotDivide(r, add(r, 1.0));
      this._x_hat = add(
        dotMultiply(alpha, x as Matrix),
        dotMultiply(subtract(1.0, alpha), this._x_hat)
      ) as Matrix;
    }
    this._timestamp = timestamp;
    return this._x_hat as T;
  };

  guess = (timestamp: number): T => {
    let dt = (timestamp - this._timestamp) * 0.001;
    return add(this._x_hat, multiply(this._dx_dt_hat, dt)) as T;
  };
}

export default class OneEuroNoiseCorrectionGazeFilter
  implements NoiseCorrectionFilter
{
  _euroFilter: OneEuroNoiseCorrectionFilter<Array<number>>;

  constructor(fc: number, dfc: number, beta: number) {
    this._euroFilter = new OneEuroNoiseCorrectionFilter(fc, dfc, beta);
  }

  apply = (gaze: Gaze) => {
    let x0 = [gaze.x(), gaze.y()];
    let x1 = this._euroFilter.apply(x0, gaze.timestamp());
    return new Gaze(
      gaze.index(),
      gaze.timestamp(),
      gaze.duration(),
      x1[0],
      x1[1]
    );
  };
}
