import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";

export default class GazeCalibrator {
  _eventManager: EventManager;
  _sampleSubscription: { remove: Function } | null | undefined;

  /**
   * @param {EventManager} eventManager
   */
  constructor(eventManager: EventManager) {
    this._eventManager = eventManager;
  }

  /**
   * @return {EventManager}
   */
  eventManager = (): EventManager => this._eventManager;

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} div
   */
  setDivElement = (div: HTMLDivElement): void => {
    throw "GazeCalibrator: Not implemented";
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = (): void => {
    throw "GazeCalibrator: Not implemented";
  };

  /**
   * Start calibration
   */
  start = () => {
    if (!this._sampleSubscription) {
      this._sampleSubscription = this._eventManager.subscribe(
        "onNextSample",
        (sample) => {
          this.calibrate(sample);
          if (this.hasNextGaze()) {
            let measuredGaze = this.nextGaze();
            let res = { sample: sample, measuredGaze: measuredGaze };
            this._eventManager.publish("onNextCalibratedSample", res);
          }

          if (this.finished()) {
            this.stop();
            this._eventManager.publish("onCalibrationComplete", {});
          }
        }
      );
      this.onStart();
    }

    this._eventManager.publish("onCalibrationStarted", {});
  };

  /**
   * Stop calibration
   */
  stop = () => {
    if (this._sampleSubscription) {
      this._sampleSubscription.remove();

      this._sampleSubscription = null;
      this.onStop();
    }
  };

  /**
   * called on start calibration
   */
  onStart = (): void => {
    throw "GazeCalibrator: Not implemented";
  };

  /**
   * called on stop calibration
   */
  onStop = (): void => {
    throw "GazeCalibrator: Not implemented";
  };

  /**
   * reset calibrator
   */
  reset = (): void => {
    throw "GazeCalibrator: Not implemented!";
  };

  /**
   * your finished!
   * @return {Boolean}
   */
  finished = (): boolean => {
    throw "GazeCalibrator: Not implemented!";
  };

  /**
   * Update the gaze calibrator
   * @param {*} sample data associated with calibration sample
   */
  calibrate = (sample: any): void => {
    throw "GazeCalibrator: Not implemented";
  };

  /**
   * @return {Boolean} is computed calibration gaze available?
   */
  hasNextGaze = (): boolean => {
    throw "GazeCalibrator: Not implemented!";
  };

  /**
   * @return {Gaze} calibration gaze
   */
  nextGaze = (): Gaze => {
    throw "GazeCalibrator: Not implemented!";
  };
}
