import GazeCalibrator from "Calibration/GazeCalibrator";
import { GazeCalibratorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";
import LoadingView from "Views/LoadingView";

export default class ExternalGazeCalibrator extends GazeCalibrator {
  config: GazeCalibratorConfig;
  _clicklessDotView: LoadingView;
  _complete: boolean;

  /**
   * @param {GazeCalibratorConfig} config
   */
  constructor(config: GazeCalibratorConfig, eventManager: EventManager) {
    super(eventManager);
    this.config = config;
    this._clicklessDotView = new LoadingView(config.view);
    this._complete = false;
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} div
   */
  setDivElement = (div: HTMLDivElement) => {
    this._clicklessDotView.setDivElement(div);
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this._clicklessDotView.releaseDivElement();
  };

  /**
   * Start calibration
   */
  onStart = () => {
    if (this.finished()) {
      this.reset();
    }

    this._clicklessDotView.startAnimation();
  };

  /**
   * Stop calibration
   */
  onStop = () => {
    this._clicklessDotView.stopAnimation();
  };

  /**
   * your finished!
   * @return {Boolean}
   */
  finished = (): boolean => {
    return this._complete;
  };

  /**
   * Reset the calibrator
   */
  reset = () => {
    this._complete = false;
  };

  /**
   * Sample the calibrator.
   * This fires an onCalibrationGaze event
   * nextGaze will also now return an updated value
   */
  calibrate = () => {
    this._complete = true; // complete instantly

    console.log("Calibrated!");
  };

  /**
   * @return {boolean}
   */
  hasNextGaze = (): boolean => false;

  /**
   * consume and return the next gaze point
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return new Gaze(0, 0, 0, 0, 0);
  };
}
