import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";
import Resolution from "Models/Resolution";

export default class SimpleEyesOnScreen {
  _eventManager: EventManager;
  _gazeSubscription: { remove: any } | null | undefined;
  _onScreen = false; // are eyes on screen?

  /**
   * @param {EventManager} eventManager
   * @param {boolean} start automatically
   * very simple eyes on screen detector
   * determines whether modified gaze coordinates are window dimensions
   */
  constructor(eventManager: EventManager, start: boolean = true) {
    this._eventManager = eventManager;

    if (start) {
      this.start();
    }
  }

  /**
   * determine whether eyes are on screen from the latest gaze
   * @param {Gaze} gaze to compare
   */
  update = (gaze: Gaze) => {
    let width = screen.width;
    let height = screen.height;
    let resolution = new Resolution(0.0, 0.0, width, height);
    let onScreen =
      gaze.x() > resolution.x() &&
      gaze.x() < resolution.width() &&
      gaze.y() > resolution.y() &&
      gaze.y() < resolution.height();

    if (onScreen != this._onScreen) {
      this._onScreen = onScreen;
      this._eventManager.publish(
        onScreen ? "onEyesOnScreen" : "onEyesOffScreen",
        {
          timestamp: Date.now(),
        }
      );
    }
  };

  /**
   * start detecting
   */
  start = () => {
    if (!this._gazeSubscription) {
      this._gazeSubscription = this._eventManager.subscribe(
        "onNextRawGaze",
        (res: { averagedGaze: Gaze }) => {
          this.update(res.averagedGaze);
        }
      );
    }
  };

  /**
   * stop detecting
   */
  stop = () => {
    if (this._gazeSubscription) {
      this._gazeSubscription.remove();

      this._gazeSubscription = null;
    }
  };

  /**
   * reset state
   */
  reset = () => {
    this._onScreen = false;
  };

  /**
   * @return {Boolean} are eyes on screen
   */
  eyesOnScreen = (): boolean => this._onScreen;
}
