import TrackerController from "Controllers/TrackerController";
import ConfigManager from "Managers/ConfigManager";
import WebcamFrameReader from "Readers/WebcamFrameReader";
import ChunkedVideoTelemetry from "Telemetry/ChunkedVideoTelemetry";

export default class GroundTruthTrackerController extends TrackerController {
  private _telemetry: ChunkedVideoTelemetry;

  constructor(config: ConfigManager) {
    super(config);
    this._telemetry = new ChunkedVideoTelemetry(
      this.eventManager(),
      this.detectorCalibrator().eventManager(),
      this.validatorCalibrator().eventManager()
    );
  }

  uid = () => this._telemetry.uid();

  pushMetadata = (tag: string, data: object, timestamp: number | undefined) =>
    this._telemetry.pushMetadata(tag, data, timestamp);

  /**
   * @return {Promise<boolean>} started
   */
  startFrameReader = async (): Promise<boolean> => {
    if (this._frameReader) {
      let ret = await this._frameReader.start();
      if (ret) {
        let frameReader = this.frameReader() as WebcamFrameReader;
        let videoStream = frameReader.videoStream();
        if (!!videoStream) {
          this._telemetry.start(videoStream);
        }
        return true;
      }
    }
    return false;
  };

  /**
   * @return {boolean} stopped
   */
  stopFrameReader = (): boolean => {
    let ret = false;
    this._telemetry.stop();
    if (this._frameReader) {
      ret = this._frameReader.stop();
    }
    return ret;
  };
}
