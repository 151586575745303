import NoiseCorrectionFilter from "GazeFilters/NoiseCorrectionFilter";
import Gaze from "Models/Gaze";

export default class MovingAverageGazeFilter implements NoiseCorrectionFilter {
  _weights: Array<number>;
  _gazeBuff: Array<Gaze>;

  constructor(weights: Array<number> = [1, 2, 3]) {
    let weightSum = 0.0;
    weights.map((weight) => (weightSum += weight));
    this._weights = weights.map((weight) => weight / weightSum);
    this._gazeBuff = [];
  }

  /**
   * moving average filter with Hann window for smoother time averaging
   * @param {number} n samples
   */
  static hann(n: number) {
    let N = n - 1;
    let weights = [];

    for (let i = 0; i <= N; i++) {
      weights[i] = Math.pow(Math.sin((Math.PI * i) / N), 2);
    }

    return new MovingAverageGazeFilter(weights);
  }

  /**
   * @param {Gaze} gaze
   * @return {Gaze}
   */
  apply = (gaze: Gaze): Gaze => {
    // pad moving average filter with initial value (e.g fixed boundary)
    while (this._gazeBuff.length <= this._weights.length) {
      this._gazeBuff.push(gaze);
    }

    if (this._gazeBuff.length > this._weights.length) {
      this._gazeBuff.shift();
    }

    let timestamp = 0.0;
    let duration = 0.0;
    let x = 0.0;
    let y = 0.0;

    for (let i = 0; i < this._weights.length; i++) {
      let gaze = this._gazeBuff[i];
      let weight = this._weights[i];
      timestamp += weight * gaze.timestamp();
      duration += weight * gaze.duration();
      x += weight * gaze.x();
      y += weight * gaze.y();
    }

    // initialize the arrays if it is the first entry
    return new Gaze(gaze.index(), timestamp, duration, x, y);
  };
}
