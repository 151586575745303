import GazeFilter from "GazeFilters/GazeFilter";
import MovingAverageGazeFilter from "GazeFilters/MovingAverageGazeFilter";
import { GazeDetectorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Gaze from "Models/Gaze";
import Landmarks from "Models/Landmarks";
import Resolution from "Models/Resolution";
import Trained from "Models/Trained";

export default class GazeDetector {
  _eventManager: EventManager;
  _gazeFilter: GazeFilter;
  _landmarksSubscription: { remove: any } | null | undefined;

  /**
   * usage:
   * gazeDetector.update(frame);
   * if (gazeDetector.hasNextGaze) {
   *    gaze = gazeDetector.nextGaze();
   * }
   */
  constructor(config: GazeDetectorConfig, eventManager: EventManager) {
    // gaze filter may be initialized with factory later
    this._gazeFilter = new MovingAverageGazeFilter(config.movingAverageWeights);
    this._eventManager = eventManager;
  }

  /**
   * start detecting
   */
  start = () => {
    if (!this._landmarksSubscription) {
      this._landmarksSubscription = this._eventManager.subscribe(
        "onNextLandmarks",
        (res: { frame: Frame; landmarks: Landmarks }) => {
          if (res.landmarks && res.frame) {
            this.update(res.frame, res.landmarks);

            if (this.hasNextGaze()) {
              let rawGaze = this.nextGaze();
              let averagedGaze = this._gazeFilter.apply(rawGaze);
              let res = {
                rawGaze: rawGaze,
                averagedGaze: averagedGaze,
              };

              this._eventManager.publish("onNextRawGaze", res);
            }
          }
        }
      );
    }

    this.onStart();
  };

  /**
   * Gaze detector may implement this
   */
  onStart = () => {};

  /**
   * stop detecting
   */
  stop = () => {
    if (this._landmarksSubscription) {
      this._landmarksSubscription.remove();

      this._landmarksSubscription = null;
    }

    this.onStop();
  };

  /**
   * Gaze detector may implement this
   */
  onStop = (): void => {};

  /**
   * reset detection state
   */
  reset = (): void => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * resize the gaze detector
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution): void => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * Update the gaze detector
   * @param {Frame} frame to detect gaze from
   * @param {Landmarks} landmarks to be used during gaze detection
   */
  update = (frame: Frame, landmarks: Landmarks): void => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * Add sample of training data
   * @param {Frame} frame to train on
   * @param {Landmarks} landmarks to train on
   * @param {Gaze} gaze known gaze point
   */
  addTrainingData = (frame: Frame, landmarks: Landmarks, gaze: Gaze): void => {
    throw "GazeDetector: Not implemented!";
  };

  train = (): Trained => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * @return {Boolean} is computed gaze available?
   */
  hasNextGaze = (): boolean => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * @return {Gaze} raw (unmodified) gaze
   */
  nextGaze = (): Gaze => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * @return {Trained}
   */
  model = (): Trained => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * @param {Trained} model
   */
  setModel = (model: Trained): void => {
    throw "GazeDetector: Not implemented!";
  };

  /**
   * @returns number between 0 and 1 representing head position quality
   */
  getHeadPositionQuality = (): number => {
    return 1.0;
  };

  /**
   * @returns number between 0 and 1 representing eye illumination quality
   */
  getEyeIlluminationQuality = (): number => {
    return 1.0;
  };
}
