import FingerprintJS from "@fingerprintjs/fingerprintjs";
import GazeDetector from "Detection/GazeDetector";
import { GazeDetectorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Frame from "Models/Frame";
import Gaze from "Models/Gaze";
import Landmarks468 from "Models/Landmarks";
import Resolution from "Models/Resolution";
import Trained from "Models/Trained";
import DomUtils from "Utils/DomUtils";
import WasmModule from "Utils/WasmModule";
import Throttle from "Workers/Throttle";

export default class GeorgeGazeDetector extends GazeDetector {
  config: GazeDetectorConfig;
  _wasm: WasmModule | undefined;
  _lastOrientation: number | null | undefined;
  _model: Trained | null | undefined; // friendly data

  _modelOffset: number | undefined; // offset of model data in wasm heap

  _gaze: Gaze | null | undefined;
  _index: number;
  _trainCallbackPtr: number | undefined;
  _updateCallbackPtr: number | undefined;

  /**
   * Web Assembly Gaze Detector may be configured to use different models
   * providing that they adhere to
   * @param {GazeDetectorConfig} config
   * @param {EventManager} eventManager
   */
  constructor(config: GazeDetectorConfig, eventManager: EventManager) {
    super(config, eventManager);
    this.config = config;
    this._index = 0;
  }

  /**
   * Initialize GazeDetector
   * Must be called prior to both calibration and detection
   */
  init = async () => {
    try {
      if (this._landmarksSubscription) {
        this._landmarksSubscription.remove();

        this._landmarksSubscription = null;
      }

      let _this = this;

      let george = this.config.george;
      let wasmFuture = WasmModule.build(george, this._eventManager).then(
        async (wasm) => {
          if (wasm.useWorkers()) {
            let buffer = new TextEncoder().encode(wasm.workerUrl() + "\0");
            let heapBuffer = wasm.arrayToHeap(buffer);
            await wasm
              .module()
              ._init_tracker(
                this.config.fov,
                150,
                0.0,
                0.0,
                heapBuffer.byteOffset
              );
            wasm.freeArray(heapBuffer);

            let trainCallbackFn = () => {
              Throttle.removeEvent("addTrainingGazeDetector");
            };

            let updateCallbackFn = () => {
              Throttle.removeEvent("updateGazeDetector");
              let scale = DomUtils.devicePixelRatio();
              let dx = DomUtils.screenContentX();
              let dy = DomUtils.screenContentY();
              if (this._wasm) {
                let timestamp = this._wasm.module()._get_timestamp();

                let duration = this._wasm.module()._get_duration();

                let x = this._wasm.module()._get_x() / scale - dx;
                let y = this._wasm.module()._get_y() / scale - dy;
                let rawGaze = new Gaze(
                  this._index++,
                  timestamp,
                  duration,
                  x,
                  y
                );

                let averagedGaze = this._gazeFilter.apply(rawGaze);

                let res = {
                  rawGaze: rawGaze,
                  averagedGaze: averagedGaze,
                };

                this._eventManager.publish("onNextRawGaze", res);
              }
            };

            this._trainCallbackPtr = wasm
              .module()
              .addFunction(trainCallbackFn, "v");
            this._updateCallbackPtr = wasm
              .module()
              .addFunction(updateCallbackFn, "v");
          } else {
            await wasm.module()._init_tracker(this.config.fov, 150, 0.0, 0.0); // dpi, x0 (inches), y0(inches)
          }

          _this._wasm = wasm;
          await _this._setParams();
        }
      );
      await wasmFuture;
    } catch (err) {
      throw {
        name: "LRTrackerInitError",
        message: "GeorgeGazeDetector: " + err,
        stack: new Error().stack,
      };
    }
  };

  /**
   * reset detection state
   */
  reset = () => {
    this._lastOrientation = null;
    this._model = null;
    if (this._wasm) {
      this._wasm.module()._reset_training();
    }

    this._index = 0;
  };

  /**
   * Update the gaze detector
   * @param {Frame} frame to detect gaze from
   * @param {Landmarks468} landmarks to be used during gaze detection
   */
  update = (frame: Frame, landmarks: Landmarks468) => {
    this._lastOrientation = frame.orientation();

    if (this._model && this._wasm) {
      // why is wasm a module and module a wasm...(refactor?)
      let wasm = this._wasm;
      let module = wasm.module();

      let imageWidth = frame.imageData().width;
      let imageHeight = frame.imageData().height;

      if (!wasm.singleton()) {
        let imageSize = imageWidth * imageHeight * 4;

        let imageOffset = module._get_image_offset(imageWidth, imageHeight);

        let imageHeapBytes = module.HEAPU8.subarray(
          imageOffset,
          imageOffset + imageSize
        );
        imageHeapBytes.set(frame.imageData().data);
        let landmarksSize = landmarks.vertices().length;

        let landmarksOffset = module._get_landmarks_offset(landmarksSize);

        let landmarksHeapBytes = module.HEAPU8.subarray(
          landmarksOffset / 4,
          landmarksOffset / 4 + landmarksSize
        );
        landmarksHeapBytes.set(landmarks.vertices().buffer);
      }

      let timestamp = landmarks.timestamp();
      let duration = landmarks.duration();
      let orientation = frame.orientation();
      let screenWidth = DomUtils.screenWidth();
      let screenHeight = DomUtils.screenHeight();
      let scale = DomUtils.devicePixelRatio();
      let dx = DomUtils.screenContentX();
      let dy = DomUtils.screenContentY();

      if (wasm.useWorkers()) {
        wasm
          .module()
          ._update(
            screenWidth * scale,
            screenHeight * scale,
            orientation,
            timestamp,
            duration,
            this._updateCallbackPtr
          );

        Throttle.addEvent("updateGazeDetector");
      } else {
        wasm
          .module()
          ._update(
            screenWidth * scale,
            screenHeight * scale,
            orientation,
            timestamp,
            duration
          );

        let x = wasm.module()._get_x() / scale - dx;
        let y = wasm.module()._get_y() / scale - dy;
        this._gaze = new Gaze(this._index++, timestamp, duration, x, y);
      }
    }
  };

  /**
   * resize the gaze detector
   * @param {Resolution} resolution
   */
  resize = (resolution: Resolution) => {
    throw "WasmGazeDetector: Not Implemented!";
  };

  /**
   * @return {Boolean}
   */
  hasNextGaze = (): boolean => this._gaze != null;

  /**
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._gaze ? this._gaze : Gaze.Zero();
  };

  /**
   *
   * @param {Frame} frame
   * @param {Landmarks} landmarks
   * @param {Gaze} gaze
   */
  addTrainingData = (frame: Frame, landmarks: Landmarks468, gaze: Gaze) => {
    this._lastOrientation = frame.orientation();

    if (this._wasm) {
      let wasm = this._wasm;

      let module = this._wasm.module();

      let imageWidth = frame.imageData().width;
      let imageHeight = frame.imageData().height;

      if (!wasm.singleton()) {
        let imageSize = imageWidth * imageHeight * 4;

        let imageOffset = module._get_image_offset(imageWidth, imageHeight);

        let heapBytes = module.HEAPU8.subarray(
          imageOffset,
          imageOffset + imageSize
        );

        let data = frame.imageData().data;

        if (
          heapBytes.buffer != data.buffer ||
          heapBytes.byteOffset != data.byteOffset ||
          heapBytes.byteLength != data.byteLength
        ) {
          heapBytes.set(frame.imageData().data);
        }

        let landmarksSize = landmarks.vertices().length;
        let landmarksOffset = module._get_landmarks_offset(landmarksSize);

        heapBytes = module.HEAPF32.subarray(
          landmarksOffset / 4,
          landmarksOffset / 4 + landmarksSize
        );

        heapBytes.set(landmarks.vertices());
      }

      let timestamp = landmarks.timestamp();
      let duration = landmarks.duration();
      let orientation = frame.orientation();
      let screenWidth = DomUtils.screenWidth();
      let screenHeight = DomUtils.screenHeight();
      let scale = DomUtils.devicePixelRatio();
      let dx = DomUtils.screenContentX();
      let dy = DomUtils.screenContentY();
      let x = scale * (dx + gaze.x());
      let y = scale * (dy + gaze.y());

      if (wasm.useWorkers()) {
        Throttle.addEvent("addTrainingGazeDetector");
        this._wasm
          .module()
          ._add_training(
            screenWidth * scale,
            screenHeight * scale,
            orientation,
            x,
            y,
            timestamp,
            duration,
            this._trainCallbackPtr
          );
      } else {
        this._wasm
          .module()
          ._add_training(
            screenWidth * scale,
            screenHeight * scale,
            orientation,
            x,
            y,
            timestamp,
            duration
          );
      }
    }
  };

  /**
   * train gaze detector using supplied data
   */
  train = () => {
    console.log("training");

    if (!this._wasm) {
      throw "GeorgeGazeDetector: Train filed: wasm not yet loaded!";
    }

    let orientation = DomUtils.screenOrientation();
    let screenWidth = DomUtils.screenWidth();
    let screenHeight = DomUtils.screenHeight();
    let scale = DomUtils.devicePixelRatio();

    let response = this._wasm
      .module()
      ._train(screenWidth * scale, screenHeight * scale, orientation);

    let timestamp = Date.now();

    let buffer = this._wasm.allocateMemInHeap(320000, 8); // 2560k

    let size = this._wasm.module()._get_model(buffer.byteOffset, buffer.length);

    let xOffset = 0;
    let yOffset = 0;
    orientation = 0;
    let model = new Trained(
      timestamp,
      this.config.george.name,
      this.config.george.version,
      orientation,
      xOffset,
      yOffset,
      buffer.slice(0, size).toString()
    );

    this._wasm.freeArray(buffer);

    this._model = model;
    console.log("training complete");
    return model;
  };

  /**
   * @return {Trained}
   */
  model = (): Trained => {
    return this._model ? this._model : Trained.Zero();
  };

  /**
   * set the model which is used for gaze detection
   * @param {Trained} model
   */
  setModel = (model: Trained) => {
    if (!this._wasm) {
      return;
    }
    let orientation = DomUtils.screenOrientation();
    let screenWidth = DomUtils.screenWidth();
    let screenHeight = DomUtils.screenHeight();
    let scale = DomUtils.devicePixelRatio();

    let buffer = this._wasm.arrayToHeap(model.data());

    this._wasm
      .module()
      ._set_model(
        buffer.byteOffset,
        model.data().length,
        screenWidth * scale,
        screenHeight * scale,
        orientation
      );

    this._wasm.freeArray(buffer);

    this._model = model;
  };

  /**
   * @returns number between 0 and 1 representing head position quality
   */
  getHeadPositionQuality = (): number => {
    if (this._wasm) {
      return this._wasm.module()._get_head_position_quality();
    } else {
      return 1.0;
    }
  };

  /**
   * @returns number between 0 and 1 representing eye illumination quality
   */
  getEyeIlluminationQuality = (): number => {
    if (this._wasm) {
      return this._wasm?.module()._get_eye_illumination_quality();
    } else {
      return 1.0;
    }
  };

  /**
   * For security
   */
  _setParams = async () => {
    if (!this._wasm) {
      return;
    }
    // encode the licence
    //////////////////////////// Text encoding polyfill for MS Edge////////////////////////
    if (typeof TextEncoder === "undefined") {
      let TextEncoder = function TextEncoder() {};

      TextEncoder.prototype.encode = function encode(str: string) {
        "use strict";

        let Len = str.length,
          resPos = -1;
        // The Uint8Array's length must be at least 3x the length of the string because an invalid UTF-16
        // takes up the equivalent space of 3 UTF-8 characters to encode it properly. However, Array's
        // have an auto expanding length and 1.5x should be just the right balance for most uses.
        let resArr = new Uint8Array(Len * 3);

        for (let point = 0, nextcode = 0, i = 0; i !== Len; ) {
          (point = str.charCodeAt(i)), (i += 1);

          if (point >= 0xd800 && point <= 0xdbff) {
            if (i === Len) {
              resArr[(resPos += 1)] = 0xef;
              /*0b11101111*/
              resArr[(resPos += 1)] = 0xbf;
              /*0b10111111*/
              resArr[(resPos += 1)] = 0xbd;
              /*0b10111101*/
              break;
            }

            // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
            nextcode = str.charCodeAt(i);

            if (nextcode >= 0xdc00 && nextcode <= 0xdfff) {
              point = (point - 0xd800) * 0x400 + nextcode - 0xdc00 + 0x10000;
              i += 1;

              if (point > 0xffff) {
                resArr[(resPos += 1)] =
                  (0x1e <<
                    /*0b11110*/
                    3) |
                  (point >>> 18);
                resArr[(resPos += 1)] =
                  (0x2 <<
                    /*0b10*/
                    6) |
                  ((point >>> 12) & 0x3f);
                /*0b00111111*/
                resArr[(resPos += 1)] =
                  (0x2 <<
                    /*0b10*/
                    6) |
                  ((point >>> 6) & 0x3f);
                /*0b00111111*/
                resArr[(resPos += 1)] =
                  (0x2 <<
                    /*0b10*/
                    6) |
                  (point & 0x3f);
                /*0b00111111*/
                continue;
              }
            } else {
              resArr[(resPos += 1)] = 0xef;
              /*0b11101111*/
              resArr[(resPos += 1)] = 0xbf;
              /*0b10111111*/
              resArr[(resPos += 1)] = 0xbd;
              /*0b10111101*/
              continue;
            }
          }

          if (point <= 0x007f) {
            resArr[(resPos += 1)] =
              (0x0 <<
                /*0b0*/
                7) |
              point;
          } else if (point <= 0x07ff) {
            resArr[(resPos += 1)] =
              (0x6 <<
                /*0b110*/
                5) |
              (point >>> 6);
            resArr[(resPos += 1)] =
              (0x2 <<
                /*0b10*/
                6) |
              (point & 0x3f);
            /*0b00111111*/
          } else {
            resArr[(resPos += 1)] =
              (0xe <<
                /*0b1110*/
                4) |
              (point >>> 12);
            resArr[(resPos += 1)] =
              (0x2 <<
                /*0b10*/
                6) |
              ((point >>> 6) & 0x3f);
            /*0b00111111*/
            resArr[(resPos += 1)] =
              (0x2 <<
                /*0b10*/
                6) |
              (point & 0x3f);
            /*0b00111111*/
          }
        }

        if (typeof Uint8Array !== "undefined")
          return resArr.subarray(0, resPos + 1);
        // else // IE 6-9
        // resArr.length = resPos + 1; // trim off extra weight <=== ???

        return resArr;
      };

      try {
        // Object.defineProperty only works on DOM prototypes in IE8
        Object.defineProperty(TextEncoder.prototype, "encoding", {
          get: function () {
            if (TextEncoder.prototype.isPrototypeOf(this)) return "utf-8";
            else throw TypeError("Illegal invocation");
          },
        });
      } catch (e) {
        /*IE6-8 fallback*/
        TextEncoder.prototype.encoding = "utf-8";
      }
    }

    ///////////////////////////! Text encoding polyfill for MS Edge ////////////////////////////
    let enc = new TextEncoder(); // always utf-8

    let fp = await FingerprintJS.load();
    let mid = this.config.submitMachineId ? (await fp.get()).visitorId : ""; // This is the visitor identifier:

    let encoded_mid = enc.encode(mid);
    // let lic = "dba856d0-7c59-4db6-85b6-d528eb8b7397"; <--- the old license key
    let lic = this.config.license;
    let encoded_lic = enc.encode(lic);
    let encoded_data = new Uint8Array(encoded_mid.length + encoded_lic.length);
    encoded_data.set(encoded_mid);
    encoded_data.set(encoded_lic, encoded_mid.length);

    let lic_data = this._wasm.arrayToHeap(encoded_data);

    // params data
    let params_data = this._wasm.arrayToHeap(new Uint8Array(1000));

    let data_size = this._wasm.arrayToHeap(new Uint8Array(4));

    this._wasm
      .module()
      ._get_params(
        params_data.byteOffset,
        data_size.byteOffset,
        lic_data.byteOffset,
        lic_data.length
      );

    let u8 = new Uint8Array(data_size); // original array

    let u32bytes = u8.buffer.slice(-4);
    let uint = new Uint32Array(u32bytes)[0];
    let data_from_cpp = params_data.subarray(0, uint);
    let request_json = {
      data: Array.prototype.slice.call(data_from_cpp),
      mid_size: encoded_mid.length,
      lic_size: encoded_lic.length,
    };
    let resp = await fetch(this.config.handshakeUrl, {
      method: "POST",
      body: JSON.stringify(request_json),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await resp.json();
    var cypher = new Uint8Array(data["params"]);

    var cypher_data = this._wasm.arrayToHeap(cypher);

    this._wasm.module()._set_params(cypher_data.byteOffset, cypher_data.length);
  };
}
