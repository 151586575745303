import { FrameReaderConfig } from "Managers/ConfigManager";
import Resolution from "Models/Resolution";
import platform from "platform";
import VideoFrameReader from "Readers/VideoFrameReader";
import DomUtils, { Orientation } from "Utils/DomUtils";

export default class WebcamFrameReader extends VideoFrameReader {
  _videoStream: MediaStream | undefined | null;

  /**
   * WebcamFrameReader records frames from a webcam device
   * This frame reader can be turned on and off using start and stop
   * The webcam light should also turn off, if this does not work it is a bug
   * @param {FrameReaderConfig} config
   * @param {HTMLVideoElement} videoElement
   * @param {HTMLCanvasElement} videoCanvas
   */
  constructor(
    config: FrameReaderConfig,
    videoElement: HTMLVideoElement,
    videoCanvas: HTMLCanvasElement
  ) {
    super(config, videoElement, videoCanvas);
  }

  /**
   * Static factory method to build new WebcamFrameReader
   * In the event that the DOM is not available (such as in a web worker),
   * the regular constructor may be called
   * @param {FrameReaderConfig} config
   * @return {WebcamFrameReader}
   */
  static build = (config: FrameReaderConfig): WebcamFrameReader => {
    let videoElement = DomUtils.createVideoElement() as HTMLVideoElement;
    let videoCanvas = DomUtils.createOffscreenCanvas() as HTMLCanvasElement;
    return new WebcamFrameReader(config, videoElement, videoCanvas);
  };

  /**
   * Start the webcam and begin emitting events
   * Turns on the webcam. The user must accept
   * @return {Promise<Boolean>} success
   */
  start = async (): Promise<boolean> => {
    if (!this._videoStream) {
      try {
        this.eventManager().publish("onFrameReaderStart", {});
        let videoStream = await DomUtils.createWebcamStream(this.config);
        this.eventManager().publish("onWebcamStreamCreate", {});
        this._videoStream = videoStream;
        this.videoElement().srcObject = videoStream;
        let dt = 1000.0 / (this.config.oversample * this.framerate());
        this._startedTime = Date.now();
        // this._lastFrameTime = Date.now();
        this._lastFrameTime = this.videoTime() * 1000 + this._startedTime;
        this._lastFrameHash = -1;
        this._lastUniqueFrame = Date.now();

        try {
          this.videoElement().muted = true;
          this.videoElement()
            .play()
            .catch((err) => {
              this.eventManager().publish("onError", err);
            });
          this.eventManager().publish("onVideoStart", {});
        } catch (err: any) {
          this.eventManager().publish("onError", err);
          ("");
        }

        this.eventManager().startEmitter(this._emit, dt);
        this.eventManager().publish("onEmitterStart", {});
        return true;
      } catch (err) {
        console.warn("WebcamFrameReader: Failed to start webcam!");
        throw err;
      }
    }

    return false;
  };

  /**
   * stop the underlying media source
   * @return {Boolean} success
   */
  stop = (): boolean => {
    if (this._videoStream) {
      this.eventManager().stopEmitter();
      this._videoElement.srcObject = null;

      this._videoStream.getTracks().forEach((track) => track.stop());

      this._videoStream = null;
      return true;
    }

    return false;
  };

  /**
   * @return {MediaStream}
   */
  videoStream = (): MediaStream | undefined | null => {
    return this._videoStream;
  };

  /**
   * When mobile browser changes orientation this method is called
   * @emit onSourceResolutionChanged
   * @override
   * @return {Resolution}
   */
  sourceResolution = (): Resolution => {
    let videoStream = this._videoStream as MediaStream;
    let track = videoStream.getVideoTracks()[0] as MediaStreamTrack;

    let width = track.getSettings().width as number;
    let height = track.getSettings().height as number;

    // begin: ios fix that should be removed later
    if (platform.os?.family == "iOS") {
      const orientation = DomUtils.screenOrientation();
      if (width > height) {
        if (
          orientation == Orientation.PORTRAIT &&
          DomUtils.screenHeight() > DomUtils.screenWidth()
        ) {
          return new Resolution(0, 0, height, width);
        }
      } else {
        if (
          (orientation == Orientation.LANDSCAPE_LEFT ||
            orientation == Orientation.LANDSCAPE_RIGHT) &&
          DomUtils.screenWidth() > DomUtils.screenHeight()
        ) {
          return new Resolution(0, 0, height, width);
        }
      }
    }
    // end: ios fix that should be removed later

    return new Resolution(0, 0, width, height);
  };

  /**
   * The quoted framerate (frames per second) of the webcam device
   * This may not be reliable on some browsers
   * @override
   * @return {Number}
   */
  framerate = (): number => {
    let videoStream = this._videoStream as MediaStream;
    let track = videoStream.getVideoTracks()[0] as MediaStreamTrack;

    return track.getSettings().frameRate as number;
  };
}
