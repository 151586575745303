import { WasmModuleConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import check from "wasm-check";
import {
  createModule,
  createSimdModule,
  createMainModule,
  createMainSimdModule,
} from "@lumen-developer/rni-webcam-wasm";

export default class WasmModule {
  /**
   * key: wasmPath + wasmJs
   * value: future describing when module has completed loading
   */
  static _singletonFutures: Record<string, any> = {};
  static _singletonResults: Record<string, any> = {};
  _module: any;
  _workerUrl: string | undefined;
  _singleton: boolean;
  _useWorkers: boolean;
  _useThreads: boolean;
  _useSimd: boolean;

  /**
   * @param {*} module is already loaded
   */
  constructor(module: any) {
    this._module = module;
    this._useSimd = true;
    this._useThreads = false;
    this._useWorkers = false;
    this._singleton = true;
  }

  static configureFileExtensions = (
    options: WasmModuleConfig,
    wasmModule: WasmModule | null | undefined = null
  ) => {
    // let hasSimd = check.feature.simd;  <== doesn't even work?!?
    let wasmJs = options.wasmJs;
    let wasmWasm = options.wasmWasm;
    let hasSimd = WebAssembly.validate(
      new Uint8Array([
        0, 97, 115, 109, 1, 0, 0, 0, 1, 4, 1, 96, 0, 0, 3, 2, 1, 0, 10, 9, 1, 7,
        0, 65, 0, 253, 15, 26, 11,
      ])
    );
    let useSimd = options.useSimd ? hasSimd : false;
    let useThreads = options.useThreads ? check.feature.threads : false;
    let useWorkers = options.useWorkers;
    let singleton = options.singleton ? true : false;

    if (wasmModule) {
      wasmModule._singleton = singleton;
      wasmModule._useSimd = useSimd;
      wasmModule._useSimd = useWorkers;
      wasmModule._useWorkers = useWorkers;
    }

    if (useWorkers) {
      wasmJs = wasmJs.replace(".js", "_main.js");
      wasmWasm = wasmWasm.replace(".wasm", "_main.wasm");
    }

    if (useSimd) {
      wasmJs = wasmJs.replace(".js", "-simd.js");
      wasmWasm = wasmWasm.replace(".wasm", "-simd.wasm");
    }

    if (useThreads) {
      wasmJs = wasmJs.replace(".js", "-threaded.js");
      wasmWasm = wasmWasm.replace(".wasm", "-threaded.wasm");
    }

    return [wasmJs, wasmWasm];
  };

  /**
   * Build Web Assembly module from a given directory path and javascript file postfix
   * @param {WasmModuleConfig} options
   * @param {EventManager} eventManager
   */
  static build = async (
    options: WasmModuleConfig,
    eventManager: EventManager
  ) => {
    let wasmPath = options.wasmPath;
    let wasmJs = options.wasmJs;
    let wasmWasm = options.wasmWasm;
    let wasmModule = new WasmModule(null);
    [wasmJs, wasmWasm] = WasmModule.configureFileExtensions(
      options,
      wasmModule
    );
    let moduleKey = wasmPath + wasmJs;
    let exportName = options.exportName ? options.exportName : "Module";

    if (
      !wasmModule.singleton() ||
      !(moduleKey in WasmModule._singletonFutures)
    ) {
      if (wasmModule.useWorkers()) {
        function getWorkerUrl(wasmPath: string, wasmJs: string) {
          const content =
            `var Module = typeof Module !== "undefined" ? Module : {};` +
            `Module["locateFile"] = function(wasmWasm) {` +
            `console.log("${wasmPath}"+wasmWasm);` +
            `return "${wasmPath}"+wasmWasm;` +
            `};` +
            `importScripts( "${wasmPath + wasmJs}" );`;
          return URL.createObjectURL(
            new Blob([content], {
              type: "text/javascript",
            })
          );
        }

        wasmModule._workerUrl = getWorkerUrl(
          wasmPath,
          wasmJs.replace("main", "worker")
        );
      }

      let wasmFuture = fetch(wasmPath + wasmWasm);

      WasmModule._singletonFutures[moduleKey] = new Promise(function (
        resolve,
        reject
      ) {
        // mainScriptUrlOrBlob: mainBlob, // for threads
        let module = {
          preInit: [],
          preRun: [],
          postRun: [
            function () {
              console.log("Loaded: " + wasmJs + " OK");

              if (eventManager) {
                eventManager.publish("onWasmLoaded", {
                  timestamp: Date.now(),
                });
              }

              wasmModule._module = module;
              WasmModule._singletonResults[moduleKey] = true;
              resolve(wasmModule);
            },
          ],
          scriptDirectory: wasmPath,
          locateFile: function (wasmWasm: string) {
            return wasmPath + wasmWasm;
          },
          instantiateWasm: function (
            info: WebAssembly.Imports | undefined,
            receiveInstance: (
              arg0: WebAssembly.Instance,
              arg1: WebAssembly.Module
            ) => any
          ) {
            if (WebAssembly.instantiateStreaming) {
              WebAssembly.instantiateStreaming(wasmFuture, info)
                .then((ret) => receiveInstance(ret.instance, ret.module))
                .catch((err) => {
                  wasmFuture
                    .then((r) => r.arrayBuffer())
                    .then((wasmArray) =>
                      WebAssembly.instantiate(wasmArray, info).then((ret) =>
                        receiveInstance(ret.instance, ret.module)
                      )
                    )
                    .catch((err) => {
                      console.log(err);
                      WasmModule._singletonFutures[moduleKey] = null;
                      reject("WasmModule: Failed instantiation!");
                    });
                });
            } else {
              wasmFuture
                .then((r) => r.arrayBuffer())
                .then((wasmArray) =>
                  WebAssembly.instantiate(wasmArray, info).then((ret) =>
                    receiveInstance(ret.instance, ret.module)
                  )
                )
                .catch((err) => {
                  console.log(err);
                  WasmModule._singletonFutures[moduleKey] = null;
                  reject("WasmModule: Failed instantiation!");
                });
            }
          },
          onAbort: function (err: any) {
            WasmModule._singletonFutures[moduleKey] = null;
            reject(err);
          },
        };
        if (options.modularized) {
          if (options.useWorkers) {
            if (options.useSimd) {
              createMainSimdModule(module);
            } else {
              createMainModule(module);
            }
          } else {
            if (options.useSimd) {
              createSimdModule(module);
            } else {
              createModule(module);
            }
          }
        } else {
          let mainTextFuture = fetch(moduleKey).then((r) => r.text());
          mainTextFuture.then((mainText) => {
            let moduleLoader = new Function(exportName, mainText);
            moduleLoader(module);
          });
        }
      });
    }

    return WasmModule._singletonFutures[moduleKey];
  };

  /**
   * Has an instance of this singleton module finished loading?
   * @param {WasmModuleConfig} options
   */
  static built = (options: WasmModuleConfig) => {
    let wasmPath = options.wasmPath;
    let wasmJs = options.wasmJs;
    let wasmWasm = options.wasmWasm;
    [wasmJs, wasmWasm] = WasmModule.configureFileExtensions(options);
    let moduleKey = wasmPath + wasmJs;
    return options.singleton && moduleKey in WasmModule._singletonResults;
  };

  /**
   * @return accessor to the encapsulated Web Assembly module
   */
  module = () => this._module;

  /**
   * @returns url to blob containing web worker (this is needed for CDN)
   */
  workerUrl = () => this._workerUrl;

  /**
   * @returns is this module a singleton (e.g landmarks + gaze detector share memory)
   */
  singleton = () => this._singleton;

  /**
   * @returns are we actually using simd (not did we ask to use simd)
   */
  useSimd = () => this._useSimd;

  /**
   * @returns are we actually using workers (not did we ask to use simd)
   */
  useWorkers = () => this._useWorkers;

  /**
   * @returns are we actually using threads (not did we ask to use simd)
   */
  useThreads = () => this._useThreads;

  /**
   * Allocates memory in Web Assembly heap and copy data
   * @param {*} typedArray
   * @return {Uint8Array}
   */
  arrayToHeap = (typedArray: any): Uint8Array => {
    let numBytes = typedArray.length * typedArray.BYTES_PER_ELEMENT;

    let ptr = this._module._malloc(numBytes);

    let heapBytes = this._module.HEAPU8.subarray(ptr, ptr + numBytes);

    heapBytes.set(new Uint8Array(typedArray.buffer));
    return heapBytes;
  };

  /**
   * Allocate memory in Web Assembly heap
   * @param {Number} length_of_heap_memory number of elements
   * @param {Number} number_of_bytes of each element
   * @return {Uint8Array} allocated
   */
  allocateMemInHeap = (
    length_of_heap_memory: number,
    number_of_bytes: number
  ): Uint8Array => {
    let numBytes = length_of_heap_memory * number_of_bytes;

    let ptr = this._module._malloc(numBytes);

    let heapBytes = this._module.HEAPU8.subarray(ptr, ptr + numBytes);

    return heapBytes;
  };

  /**
   * Deallocate bytes from Web Assembly module heap
   * @param {Uint8Array} heapBytes to deallocate
   */
  freeArray = (heapBytes: Uint8Array) => {
    return this._module._free(heapBytes.byteOffset);
  };
}
