import GazeCalibrator from "Calibration/GazeCalibrator";
import { GazeCalibratorConfig } from "Managers/ConfigManager";
import EventManager from "Managers/EventManager";
import Gaze from "Models/Gaze";
import ClicklessDotView, { Point } from "Views/ClicklessDotView";

export default class ClicklessDotGazeCalibrator extends GazeCalibrator {
  /**
   * Extensible base class for GazeCalibrator
   * Future implementations may include one point calibration etc
   */
  config: GazeCalibratorConfig;
  _view: ClicklessDotView;
  _gaze: Gaze | null | undefined; // most recent gaze calibration

  _points: Array<Point>; // Points to sample from

  _pointIndex: number; // current index of calibration

  _samples: number; // number of samples taken from current point

  _lastMoving: number; // timestamp dot was last moving

  /**
   * @param {GazeCalibratorConfig} config
   */
  constructor(config: GazeCalibratorConfig, eventManager: EventManager) {
    super(eventManager);
    this.config = config;
    this._view = new ClicklessDotView(config.view);

    let points = this._view.getDefaultPoints(config.numberOfPoints);

    this._points = points;
    this._pointIndex = 0;
    this._samples = 0;
    this._lastMoving = 0;
  }

  /**
   * Set div element and attach children
   * @param {HTMLDivElement} div
   */
  setDivElement = (div: HTMLDivElement) => {
    this._view.setDivElement(div);
  };

  /**
   * Release document elements attached to div
   */
  releaseDivElement = () => {
    this._view.releaseDivElement();
  };

  /**
   * @param {Array<Point>} points
   */
  setPoints = (points: Array<Point>) => {
    this._points = points.map((p) => new Point(p.x, p.y));
  };

  /**
   * Start calibration
   */
  onStart = () => {
    if (this.finished()) {
      this.reset();
    }

    let time = Date.now();
    this._lastMoving = time;
    let point = this._points[this._pointIndex];

    this._view.moveTo(point);

    this._view.startAnimation();
  };

  /**
   * Stop calibration
   */
  onStop = () => {
    this._view.stopAnimation();
  };

  /**
   * your finished!
   * @return {Boolean}
   */
  finished = (): boolean => {
    return this._pointIndex === this._points.length;
  };

  /**
   * Reset the calibrator
   */
  reset = () => {
    this._pointIndex = 0;
    this._samples = 0;
    this._gaze = null;
  };

  /**
   * This fires an onCalibrationGaze event
   * nextGaze will also now return an updated value
   */
  calibrate = () => {
    let time = Date.now();

    if (!this._view.moving()) {
      let gaze = this._view.sample();

      this._gaze = gaze;

      if (this._pointIndex < this._points.length) {
        let requiredSamples = Math.floor(
          this.config.numberOfSamples / this.config.numberOfPoints
        );

        if (time > this._lastMoving + this.config.preSampleDelay) {
          if (this._samples < requiredSamples) {
            this._samples += 1;
          }
        } else {
          this._gaze = null;
        }

        if (this._samples === requiredSamples) {
          this._samples = 0;
          this._pointIndex += 1;

          if (this._pointIndex < this._points.length) {
            let point = this._points[this._pointIndex];

            this._view.moveTo(point);

            this._eventManager.publish("onNextCalibrationPoint", {
              point: { x: point.x, y: point.y },
            });

            console.log("calibrate next point");
          } else {
            console.log("calibrated final point");
          }
        }
      }
    } else {
      this._gaze = null;
      this._lastMoving = time;
    }
  };

  /**
   * @return {boolean}
   */
  hasNextGaze = (): boolean => this._gaze != null;

  /**
   * consume and return the next gaze point
   * @return {Gaze}
   */
  nextGaze = (): Gaze => {
    return this._gaze ? this._gaze : Gaze.Zero();
  };
}
